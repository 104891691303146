.create-content-group, .create-chat-group, .create-live-event, .edit-live-event
  padding: 5rem

  .hidden-buttons-block
    text-align: center
    .edit-cg, .create-group-btn
      background-color: #ff5100 !important
      color: #ffffff !important
      width: 162px
      border: 0
      &:disabled
        opacity: 0.45

  .global-create-tags-block
    position: relative
    margin-top: 3rem
    .search-term-wrapper
      height: auto
      border: 1px solid #e3e3e3
      padding: 0.5rem 1rem 1.5rem
      margin: 0 0 1rem
      cursor: text
      max-width: 500px
      flex-flow: wrap
    .global-tags-input
      width: auto
      font-size: 1.6rem
      border: none !important
      margin-top: 1rem

  .add-amb-title
    margin-top: 2rem !important
  .add-amb-block
    padding: 8px 19px
    background-color: #f3f3f3

  .error
    position: relative
    left: auto
    top: 24%
    font-size: 1.2rem
    color: red
    font-family: $InterFace

    &.file
      margin-left: -10rem
      width: 8rem

    &.text
      left: 0.3rem

  .ui-kit-input
    padding: 0
    width: 100%
    height: 3.4rem
    border-radius: 5px
    border: solid 0.1rem #cec8c8
    background-color: #ffffff
    font-size: 1.6rem
    text-indent: 0.8rem
    font-family: $InterFace
    font-weight: 300
    font-style: normal
    font-stretch: normal
    line-height: 1.19
    letter-spacing: normal
    &::placeholder
      color: #222222
      opacity: 0.5

  &.mod-users
    width: 100%

  .ui-kit-textarea
    padding: 0.5rem 1rem 1.5rem
    width: 100%
    background-color: #ffffff
    font-size: 1.6rem
    font-family: $InterFace
    font-weight: 300
    font-style: normal
    font-stretch: normal
    letter-spacing: normal
    min-height: 10rem
    border-radius: 5px
    border: solid 0.1rem #cec8c8
    &::placeholder
      color: #222222
      opacity: 0.5
  .ui-kit-date-picker
    width: 50%
    background-color: #ffffff
    font-size: 1.6rem
    font-family: $InterFace
    font-weight: 300
    font-style: normal
    font-stretch: normal
    letter-spacing: normal
    border-radius: 5px
    border: solid 0.1rem #cec8c8

  .modal-title
    text-align: center
    font-size: 2.4rem
    color: #222222
    margin-bottom: 3rem

  .content-group-name
    font-size: 1.6rem
    font-weight: 600
    font-family: $InterFace
    margin-top: 0
    margin-bottom: 1rem
    color: $dark
    letter-spacing: normal
    line-height: 1.17
    &.optional-icon
      margin: 0.5rem 0 -3rem 0 !important
      opacity: 0.8
      font-size: 1.6rem

  .select-all
    font-size: 1.6rem
    color: #ff5100
    padding-top: 1rem
    letter-spacing: -0.2px
    width: 8rem
    margin-bottom: 0.9rem

    &:hover
      cursor: pointer
      text-decoration: none

.create-faq
  padding: 4rem
  min-width: 50rem
  width: 600px

  .global-create-tags-block
    position: relative
    .search-term-wrapper
      height: auto
      border: 1px solid #e3e3e3
      padding: 0.5rem 1rem 1.5rem
      margin: 0 0 1rem
      cursor: text
      max-width: 500px
      flex-flow: wrap
    .global-tags-input
      width: auto
      font-size: 1.6rem
      border: none !important
      margin-top: 1rem

  .add-amb-title
    margin-top: 2rem !important
  .add-amb-block
    padding: 8px 19px
    background-color: #f3f3f3

  .error
    position: absolute
    right: 0
    top: -2.5rem
    font-size: 1rem
    color: red
    font-family: $InterFace

    &.file
      margin-left: -10rem
      width: 8rem

    &.text
      margin-left: 0
      top: 20%
      width: 100%
      left: 31rem
      right: 0
      text-align: left

  .ui-kit-input
    width: 100%
    height: 3.4rem
    border-radius: 5px
    border: solid 0.1rem #cec8c8
    background-color: #ffffff
    font-size: 1.6rem
    text-indent: 0.8rem
    font-family: $InterFace
    font-weight: 300
    font-style: normal
    font-stretch: normal
    line-height: 1.19
    letter-spacing: normal
    &::placeholder
      color: #222222
      opacity: 0.5

    &.mod-users
      width: 100%

  .ui-kit-textarea
    padding: 0
    max-width: 100%
    width: calc(100% - 1.6rem)
    height: 2rem
    border-radius: 1px
    border: solid 0.1rem #e3e3e3
    background-color: #fafafa
    font-size: 1.6rem
    letter-spacing: -0.2px
    padding: 0.8rem
    margin-bottom: 1.8rem

    &--question
      height: 75px

    &--answer
      height: auto
      &::placeholder
        color: $taporange

  .modal-title
    font-size: 2.3rem
    margin-bottom: 3rem

  .content-group-name
    font-size: 1.8rem
    font-family: $InterFace
    margin-top: 0
    margin-bottom: 0.6rem
    color: $dark
    letter-spacing: normal
    line-height: 1.17
    opacity: 0.8

  .select-all
    font-size: 1.2rem
    color: #b3b3b3
    padding-top: 1rem
    letter-spacing: -0.2px
    width: 8rem
    margin-bottom: 0.9rem

    &:hover
      cursor: pointer
      text-decoration: none

.content-group-users
  border-radius: 0.3rem
  max-height: 23rem
  overflow-y: scroll
  margin-bottom: 1rem
  width: 100%
  min-height: 23rem

  .content-group-user
    width: 100%
    box-sizing: border-box
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    position: relative
    padding-left: 1.6rem
    cursor: pointer
    margin-bottom: 0.9rem

    .user-name
      font-size: 1.6rem
      color: $dark
      letter-spacing: -0.2px
      font-family: $InterFace
      font-weight: 500
      display: block
      margin-right: 1rem

.create-group-row
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  margin-bottom: 1.7rem

  .content-group-prview-block
    margin-left: 0 !important

.create-group-icon-label
  position: relative
  display: flex
  justify-content: center
  padding-top: 10px

.content-group-icon-input
  position: absolute
  left: 0
  top: 0
  opacity: 0
  width: 100%
  height: 100%
  cursor: pointer

.content-group-prview-text
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  text-align: center
  color: #b3b3b3

.content-group-prview-block
  width: 5.7rem
  height: 5.7rem
  border: solid 0.1rem #e3e3e3
  background-color: #fafafa
  border-radius: 50%
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  box-sizing: border-box
  margin-left: 1.5rem
  position: relative
  overflow: hidden

.content-group-prview
  position: absolute
  opacity: 0
  max-width: 150%
  max-height: 150%
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

  &.visible
    opacity: 1

.content-group-check-icon
  width: 2rem
  height: 2rem
  border: 1px solid $taporange
  border-radius: 3px
  display: block
  position: relative
  margin-right: 2.6rem

.content-group-check
  position: absolute
  left: 0
  top: 0
  opacity: 0

  &:checked
    & + .content-group-check-icon
      border: solid 1px rgba(34, 34, 34, 0.7)
      &:before
        content: ""
        position: absolute
        width: 100%
        height: 100%
        background: url(../../../svg/check.svg) center center no-repeat
        background-size: 100%

.content-group-check-img-block
  width: 4.5rem
  height: 4.5rem
  border-radius: 50%
  position: relative
  overflow: hidden
  margin-right: 1.6rem
  min-width: 4.5rem

.content-group-check-img
  object-fit: cover
  height: 100%
  width: 100%

.content-group-check-img-name
  display: block
  width: 100%
  height: 100%
  background: grey
  line-height: 4.5rem
  text-align: center
  color: #fff
  text-transform: uppercase

.user-study-time
  font-family: $InterFace
  font-size: 1.4rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-right: 1.6rem
  border: 0.5px solid $tapgrey
  border-radius: 4px
  padding: 4px

.user-hobbies-block
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  flex-wrap: wrap

.user-hobbie
  border-radius: 0.2rem
  background-color: #e3e3e3
  padding: 0.5rem 1rem
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  text-align: center
  color: $dark
  margin-right: 0.4rem
  margin-bottom: 0.4rem

.el-btn
  &.mod-close-group
    background-color: transparent
    border: 0
    color: $dark

  &.mod-archive-group
    background-color: transparent
    border: 0
    color: $taporange
    padding: 0
    margin-left: 2rem

.content-gallery-buttons
  display: flex
  .el-btn.back-btn
    margin-right: 1rem
    border-color: #262e45
    background-color: transparent
    color: #262e45
    text-decoration: none
    transition: all .2s
    &:hover
      background-color: #262e45
      color: #fff
      border: 1px #262e45 solid
  .create-content-btn
    float: right
    min-width: 140px
    margin-left: 5px
    padding: 1rem
    text-align: center
  .upload-content-btn
    padding: 1rem
    text-align: center

.posts-page
  .content-wrap
    grid-template-columns: repeat(3, 1fr) !important
  .global-tags-block
    margin: 10px 0 35px 15px

.posts-global-tags
  .tags-title
    margin-bottom: 2rem
    font-family: $InterFace
    font-size: 16px
    font-weight: bold
    font-style: normal
    font-stretch: normal
    letter-spacing: normal
    color: #262e45
  .popular-tag-wrapper
    display: flex
    flex-wrap: wrap
    flex-direction: row
    width: auto
    .search-term
      margin-bottom: 5px
      width: fit-content

.content-group-selected-count
  font-family: $InterFace
  font-size: 1.6rem
  padding-left: 25px
  color: $dark

  &--active
    color: $taporange

.content-group-selected-names
  font-family: $InterFace
  font-size: 1.2rem
  color: $tapgrey
  margin-bottom: 1rem

.deleted-post
  width: 37rem
  height: 37rem
  background-color: $light-grey
  text-align: center
  font-family: $InterFace

  p
    color: white
    padding-top: 15rem

.select-post-share
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  padding-left: 17rem

  input
    margin-left: 2rem

  p
    margin-left: 0.5rem

.select-post-share-title
  padding-left: 19rem
  padding-bottom: 0.5rem
  padding-top: 2rem

.load-prospect
  display: block
  margin: 5rem auto 0
  max-width: 100%
  max-height: 100%

.warning
  color: #ff9368

.el-btn[disabled]
  background: #dedede !important
  border-color: #dedede !important
  color: rgba(0, 0, 0, 0.5) !important

.chat-footer-closed
  width: 100%
  height: 6.5rem
  background-color: #f9f9f9
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 1.5rem 2.8rem 1.5rem 3.2rem
  box-sizing: border-box
  position: absolute
  bottom: 0
  left: 0
  text-align: center
  &.reported
    background: white

.reopen-btn
  font-size: 1.5rem
  letter-spacing: -0.05rem
  text-align: center
  color: #4a4a4a
  width: 7.6rem
  height: 2.6rem
  border-radius: 0.3rem
  border: solid 0.1rem #4a4a4a
  margin-left: 10px

.uploaded-line
  display: block
  width: 200px
  position: relative
  height: 10px
  overflow: hidden
  border: 1px solid rgba(0,0,0,.2)
  border-radius: 3px
  margin-top: 10px

.uploaded-progress
  height: 10px
  display: block
  background: rgba(255,84,16,1)
  background: -moz-linear-gradient(45deg, rgba(255,84,16,1) 0%, rgba(255,84,16,1) 42%, rgba(255,190,184,1) 66%, rgba(255,190,184,1) 100%)
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,84,16,1)), color-stop(42%, rgba(255,84,16,1)), color-stop(66%, rgba(255,190,184,1)), color-stop(100%, rgba(255,190,184,1)))
  background: -webkit-linear-gradient(45deg, rgba(255,84,16,1) 0%, rgba(255,84,16,1) 42%, rgba(255,190,184,1) 66%, rgba(255,190,184,1) 100%)
  background: -o-linear-gradient(45deg, rgba(255,84,16,1) 0%, rgba(255,84,16,1) 42%, rgba(255,190,184,1) 66%, rgba(255,190,184,1) 100%)
  background: -ms-linear-gradient(45deg, rgba(255,84,16,1) 0%, rgba(255,84,16,1) 42%, rgba(255,190,184,1) 66%, rgba(255,190,184,1) 100%)
  background: linear-gradient(45deg, rgba(255,84,16,1) 0%, rgba(255,84,16,1) 42%, rgba(255,190,184,1) 66%, rgba(255,190,184,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5410', endColorstr='#ffbeb8', GradientType=1 )
  transition: all 0.2s linear

.msg-text.msg-test-uploading
  font-size: 1.2rem

.msg-text.msg-text-uploading-filed
  font-size: 1.2rem
  color: #ff1b37

.msg-img-content
  cursor: pointer

.text-popup
  padding: 15px
  height: auto

.loading-video-block
  display: flex
  align-items: center
  height: 100%
  width: 100%
  position: relative

.video-upload-error-block
  padding: 7rem 7rem 9rem
  font-family: $InterFace
  font-size: 1.6rem
  color: $tapgrey

.content-schedule-title
  font-size: 2.1rem
  font-weight: 900
  letter-spacing: -0.2px
  color: #222
  margin-bottom: 2rem

.content-schedule-intro
  margin-bottom: 2rem

.calendar-event-cell
  height: 112px
  border-top: solid 1px #e7e7e7
  border-left: solid 1px #e7e7e7

.calendar-event-cell-today
  border: solid 1px #ff5100

.calendar-event-cell-selected
  background-color: rgba(255, 81, 0, 0.6)

.calendar-event-empty-cell
  background-color: #ffffff

.calendar-event-archived-cell
  background-color: rgba(0,0,0,0.2)

.calendar-event-full-cell
  background-color: #fbfcfe

.calendar-event-disabled-cell
  background-color: #f8f8fa

.calendar-event-cell-day-row
  display: flex
  justify-content: space-between

.calendar-day
  padding-top: 4px
  padding-left: 12px
  font-size: 20px
  color: rgba(38, 46, 69, 0.56)

.calendar-event-cell-selected .calendar-day
  color: #ffffff

.calendar-day-checkbox
  padding-top: 8px
  padding-right: 12px

.calendar-day-checked .ant-checkbox-inner
  border: solid 1px #ff9666
  background-color: #ffffff
  color: #ff9666

.calendar-day-checked .ant-checkbox-inner::after
  border-color: rgba(255, 81, 0, 0.7)

.calendar-day-unchecked .ant-checkbox-inner
  border: solid 1px #cbcaca

.calendar-day-text
  text-align: left
  padding-top: 16px
  padding-left: 8px
  padding-right: 8px
  max-height: 60px
  font-size: 12px
  color: rgba(38, 46, 69, 0.8)
  line-height: 1.33
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3

.calendar-event-cell-selected .calendar-day-text
  color: #ffffff

.calendar-header-column
  display: flex
  flex-direction: column
  align-items: center

.calendar-header-date
  width: 160px

.calendar-header-date input
  text-align: center

.calendar-header-checkbox
  padding-bottom: 8px
  width: 100%
  display: flex
  justify-content: flex-end
  align-items: center

.calendar-header-checked .ant-checkbox-inner
  border: solid 1px #ff9666
  background-color: #ffffff
  color: #ff9666

.calendar-header-checked .ant-checkbox-inner::after
  border-color: rgba(255, 81, 0, 0.7)

.calendar-header-indeterminate .ant-checkbox-inner
  border: solid 1px #ff9666

.calendar-header-indeterminate .ant-checkbox-inner::after
  background-color: rgba(255, 81, 0, 0.7)

.calendar-header-unchecked .ant-checkbox-inner
  border: solid 1px #ff9666

.calendar-header-checkbox-text
  padding-right: 4px
  font-size: 16px
  color: #ff5100
  opacity: 0.7

.schedule-divider
  margin-left: 20px
  height: 775px
  width: 1px
  background-color: rgba(0, 0, 0, 0.15)

.panel-date-header
  padding-top: 38px
  font-size: 18px
  color: #262e45
  opacity: 0.7

.panel-none-description
  padding-top: 26px
  padding-bottom: 26px
  font-size: 16px
  line-height: 1.5
  color: rgba(38, 46, 69, 0.6)

.panel-create-button
  padding: 0px
  font-size: 16px
  color: rgba(255, 81, 0, 0.7)

.panel-create-button:hover,
.panel-create-button:focus
  color: rgba(255, 81, 0, 0.7)
  background-color: #ffffff
  border-color: #ffffff
  opacity: 0.8

.panel-title-description
  padding-top: 26px
  padding-bottom: 26px
  font-size: 16px
  line-height: 1.5
  color: #ff5100
  opacity: 0.8

.panel-title-description-container
  position: relative
  margin-bottom: 12px

.panel-title-description-label
  font-size: 16px
  font-family: $InterFaceBold

.panel-text
  display: flex
  flex-direction: column
  align-items: stretch

.panel-text-line
  margin-right: 13px
  width: 2px
  min-width: 2px
  background-color: #ff5100
  opacity: 0.8

.panel-text-title
  font-size: 16px
  line-height: 1.5
  font-family: $InterFaceBold

.panel-text-description
  font-size: 16px
  line-height: 1.5
  color: rgba(38, 46, 69, 0.6)

.panel-action-button
  padding: 10px 0px 0px 20px
  font-size: 16px
  color: #262e45
  opacity: 0.7

.panel-action-button:hover,
.panel-action-button:focus
  color: #262e45
  background-color: #ffffff
  border-color: #ffffff

.panel-action-buttons-row
  display: flex
  justify-content: flex-end

.panel-action-delete-popup .ant-popover-inner-content
  padding: 12px 16px

.panel-text-counter
  position: absolute
  bottom: 2px
  right: 7px

.panel-text-counter-valid
  color: #ff844c

.panel-text-counter-invalid
  color: #fe3057
  font-weight: 900

.calendar-popover-content
  display: flex
  align-items: center

.calendar-popover-date
  height: 134px
  width: 132px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: #ffffff
  background-color: rgba(255, 81, 0, 0.7)
  border-radius: 4px 0 0 4px
  &.archived
    background-color: rgba(0,0,0,0.2)

.calendar-popover-date-main
  font-size: 32px

.calendar-popover-date-additional
  font-size: 20px
  opacity: 0.87

.calendar-popover-event
  padding-top: 20px
  padding-left: 20px
  height: 134px
  width: 328px
  display: flex
  flex-direction: column

.calendar-popover-event-title
  padding-bottom: 13px
  padding-right: 1rem
  font-size: 16px
  font-weight: 600
  color: rgba(38, 46, 69, 0.64)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.calendar-popover-event-text
  font-size: 14px
  line-height: 1.3
  text-align: left
  color: rgba(34, 34, 34, 0.6)
  text-overflow: ellipsis
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  max-height: 4rem
  padding-right: 1rem

.content-group-date-picker-container
  width: 100%
  margin-top: 1.7rem
