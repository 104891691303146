// footer
//-----------------------------------------------------------------------------------

.footer
    position: fixed
    left: 19.6rem
    bottom: 0
    width: calc(100vw - 19.6rem)
    @media (max-width: 767px)
        width: 100%
.footer-content
    display: none !important
    opacity: 0
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    padding: 2rem
    @media (max-width: 991px)
        padding: 0 2rem
.select
    &.language
        width: 8rem
        .ui.selection.dropdown
            width: 8rem
            min-width: 6rem
            min-height: 3rem
            padding: 0 2rem
            background: transparent
            border: 0
            box-sizing: border-box
            border-radius: 0
            box-shadow: none
            width: auto
            @media (max-width: 767px)
                padding-left: 0
        .ui.dropdown>.text
            font-family: $InterFace
            font-size: 1.6rem
            font-style: normal
            font-stretch: normal
            line-height: normal

            color: #ffffff
            line-height: 3rem
        .ui.selection.dropdown>.dropdown.icon
            padding: 0
            color: #fff
            height: 3rem
            line-height: 3rem
            font-size: 1.6rem
            transition: 0.3s
        .ui.selection.dropdown
            color: #fff
        .ui.selection.visible.dropdown>.text:not(.default)
            color: #fff
        .ui.upward.selection.dropdown .menu
            border-color: #5b6075
            box-sizing: border-box
            width: auto
            @media (max-width: 767px)
                margin-left: -2rem
        .ui.selection.dropdown .menu>.item
            font-family: $InterFace
            font-size: 1.6rem
            font-style: normal
            font-stretch: normal
            line-height: normal

            padding-left: 2rem !important
            color: $tapgrey
        .ui.active.selection.dropdown>.dropdown.icon, .ui.visible.selection.dropdown>.dropdown.icon
            transform: rotate(180deg)
.footer-menu
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
.footer-menu-link
    font-family: $InterFace
    font-size: 1.4rem
    color: $tapgrey
    &:hover
        text-decoration: none
.footer-menu-item
    padding: 0 1rem
    position: relative
    @media (max-width: 767px)
        padding: 0 0.3rem
    &:first-of-type
        padding-left: 0
        &:before
            display: none
    &:last-of-type
        padding-right: 0
