@import './transition.min.css';
@import './dropdown.min.css';
@import './balloon.css';
@import './slick.css';
@import './slick-theme.css';
@import './remodal.css';
@import './remodal-default-theme.css';
@import "./antd.css";

.highcharts-credits {
    font-size: 0px !important;
}