.table
  .shared-content-preview-img, .shared-content-preview-video
    top: 0
    left: 0
    object-fit: cover
    height: 7rem !important
    width: 7rem !important
    flex-grow: 0
    border-radius: 0
    transition: all .2s
    &:hover
      transform: scale(1.06)
  &:last-child
    margin-bottom: 0
  &.posts-table
    margin-top: 1rem
    height: 100%
    tbody tr
      height: 10rem
  tbody td, th
    line-height: 2rem
    border: none
    padding-bottom: 1rem

  &.posts-table thead td
    &:first-child
      padding-left: 0
    &:nth-child(2)
      width: 10%
    &:nth-child(3)
      width: 10%
    &:nth-child(5)
      width: 10%
    &:nth-child(6)
      width: 10%
    &:nth-child(7)
      width: 10%
  &.posts-table tbody td
    padding-top: 2.5rem
    box-sizing: border-box
    width: 15%
    &:first-child
      padding-top: 0rem
      width: 10%
      padding-left: 0rem
      img, video
        &:hover
          cursor: pointer
    &:nth-child(2)
      width: 10%
    &:nth-child(3)
      width: 10%
    &:nth-child(4)
      width: 10%
    &:nth-child(5)
      width: 10%
    &:nth-child(6)
      width: 10%
    &:nth-child(7)
      padding-left: 0rem
      width: 10%
    &:nth-child(8)
      width: 10%

  thead th
    color: #555555
    border-bottom-color: #eff1f5
    text-align: left

  .badge
    color: #ffffff
    padding: 6px 20px
    border-radius: 15px
    font-size: 12px
    font-weight: 400

    &.badge-success
      background-color: #4ce1b6

    &.badge-warning
      background-color: #f6da6e

    &.badge-primary
      background-color: #70bbfd

    &.badge-danger
      background-color: #ff4861

    &.badge-disabled
      background-color: #dddddd

.table-top
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

.table .badge.badge-success
  padding: 6px 24px

.non-cluster
  display: block
  position: absolute
  top: 45%
  left: 50%
  transform: translate(-50%, -50%)
  font-size: 2.2rem
  text-align: center
  line-height: 1.4
  svg
    display: block
    margin: 0 auto 1rem
  a,
  button
    color: $taporange
    font-size: 2.2rem
    display: inline-block
    vertical-align: middle
    line-height: 1
    transform: translateY(-3px)
    &:hover
      cursor: pointer
  button
    transform: translateY(-1px)
