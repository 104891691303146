.idp-activation-container {
    @apply h-screen flex flex-col py-22 px-80 bg-light-gray overflow-y-auto box-border text-black;
    .ant-steps {
        @apply px-23  mb-16;
        .step-counter {
            @apply text-white bg-secondary px-3 py-1 rounded text-xl font-interface-bold;
        }
        .ant-steps-item-process, .ant-steps-item-finish {
            .step-counter {
                @apply bg-black;
            }
        }
        .ant-steps-item-title {
            &::after {
                height: 0.2rem;
                background-color: #C0BABA !important
            }
        }
        .ant-steps-item-finish {
            .ant-steps-item-title {
                &::after {
                    background-color: black !important
                }
            }
        }
        &.completed {
            .step-counter {
                @apply bg-[#A1E391];
            }
            .ant-steps-item-title {
                &::after {
                    background-color: #A1E391 !important
                }
            }
        }
    }
    .faq-link {
        @apply text-brand-orange underline;
        &:hover {
            @apply text-black;
        }
    }
    .ant-card-body {
        @apply p-0;
    }
    .card {
        @apply px-6 py-4 bg-white max-w-[46rem];
        box-shadow: 0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05);
        border-radius: 0.8rem;
        border: 1px solid transparent;
        &.selected {
            border: 1px solid #000;
        }
        .card-container {
            @apply grid grid-cols-[auto_1fr_auto];
        }
        .card-title {
            @apply text-2xl pr-4 uppercase;
        }
        .card-label {
            @apply my-2.5 py-0.5 px-4 text-xs bg-[#FFC671] rounded-[3rem] max-w-fit;
            &.active {
                @apply bg-[#CCF2D7];
            }
        }
        .card-description {
            @apply col-start-2 text-lg text-[#666666];
        }
        .card-checkbox {
            @apply self-baseline ml-6;
        }
    }
    .ant-checkbox { 
        &:not(.ant-checkbox-disabled) {
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: black !important;
                    border-color: black !important;
                }
            }
            .ant-checkbox-inner {
                border-color: black !important;
            }
        }
    }
    .process-btn {
        @apply font-poppins font-semibold w-[48rem] h-[5rem] rounded;
        &:disabled {
            @apply text-white bg-[#FFBFA1] border-none
        }
    }
}