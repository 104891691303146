@import 'antd-tap-theme.less';

.tap-tag-select .ant-select-item-option-selected {
    // display: none;
}

.tap-upload-field .ant-upload.ant-upload-select-picture-card {
    margin: 0;
}
// ant-typography
.tap-notification-modal,
.tap-event-modal {

    .ant-modal-close {
        width: 3rem;
        height: 3rem;
        top: -4.4rem;
        right: 0;

        &-x {
            width: 100%;
            height: 100%;
            line-height: 1;
        }

        svg {
            height: 3rem;
            width: 3rem;
            fill: #ffffff;
        }
    }
}

.tap-notification-modal {

    h3.ant-typography {
        text-align: center;
    }
}

.tap-event-modal {

    .ant-modal-header {
        padding: 30px 50px 16px 50px;
        border: none;
    }

    .ant-modal-body {
        padding-top: 0;
        padding-left: 50px;
        padding-right: 50px;
    }

    .ant-modal-title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;

        .modal-subtitle {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .ant-modal-footer {
        border: 0;
        text-align: center;
        padding-bottom: 50px;
    }
}

.tap-color-primary {
    color: @primary-color;
}

.tap-event-nav {
    .ant-tabs {
        height: 100%;
    }
    .ant-tabs-content {
        max-height: 100%;
        height: 100%;

        .ant-tabs-tabpane {
            max-height: 100%;
            height: 100%;
        }
    }
}

.tap-avatar-online-badge {

    .ant-badge-dot {
        top: auto;
        left: auto;
        bottom: -5px;
        right: 5px;
        width: 10px;
        height: 10px;
    }
}

.tap-livestream {
    background: #000;
    position: relative;
    color: #FFFFFF;
    height: 100%;

    &-video-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

        &.video-preview {
            height: 284px;
        }
    }

    &-join-layer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-controls {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 8px;
        z-index: 2;
        background-color: rgba(34, 34, 34, 0.7);

        .control-wrapper {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.speakers-placeholder-field {
    text-align: center;

    .ant-form-item-control-input {
        display: none;
    }
}

.tap-event-stream {
    position: relative;
    width: 100%;
    height: 100%;
    background: #222;
    color: #FFFFFF;

    &-join-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &-participants-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 5px;
        padding-bottom: 54px;
        overflow: hidden;

        &.video-preview {
            height: 375px;
        }

        &.screen-share {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .tap-event-stream-screen-view {
                position: relative;
                width: 80%;
                // padding-right: 4px;

                .fm-video {
                    width: 100%;
                    height: 100%;
                }
            }

            .tap-event-stream-participants {
                width: 20%;
                padding-left: 4px;

                .participant-card {

                    .participant-card-content {
                        height: auto;
                        padding-top: 73%;
                    }
                }
            }
        }
    }

    &-participants {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

        .fm-video {
            width: 100%;
            height: 100%;
        }
    }

    &-controls {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        height: 46px;
        padding: 8px;
        z-index: 2;

        .ant-btn {
            color: #fff
        }
    }

    .control-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            display: block;
            height: 24px;
        }
    }

    .participant-card {
        position: absolute;
        width: 100%;
        height: 100%;
        // padding: 5px;
        padding: 0px;
        transition: border 0.1s ease;
        border: 5px solid rgba(255, 0, 0, 0);

        .participant-card-content {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .participant-video {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: 0;
        }

        .participant-loading {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .participant-info {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 3;
            background-color: rgba(33, 33, 33, 0.5);
            padding: 4px 14px;

            .control-wrapper {
                cursor: default;
            }
        }

        .participant-avatar {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #383838;
            top: 0;
        }

        .participant-avatar-img {
            display: block;
            width: 100px;
            max-width: 40%;
            border-radius: 50%;
            overflow: hidden;

            &-content {
                width: 100%;
                padding-top: 100%;
                height: 0;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
            }

            &-name {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: #ccc;
            }
        }
    }
}

.tap-live-event-block-wrapper {
    &.is-fullscreen {
        padding-top: 0!important;

        .event-head {
            display: none!important;
        }
    }
}

.live-event {
    .msg-text {
        word-break: break-word;
    }
}

.stream-publish-control {
    display: inline-grid;
    grid-template-columns: 1fr 6rem;
    align-items: baseline;
    margin-bottom: 4rem;
    .description {
        grid-column: span 2;
    }
}

.re-upload-modal-body {
    display: inline-grid;
    grid-gap: 2rem;
    .file-name {
        font-size: 2.2rem;
        text-align: center;
    }
    .hint {
        font-size: 1.8rem;
        text-align: center;
        font-weight: 600;
        padding-bottom: 2rem;
    }
}

.ant-list-items {
    a:hover {
        opacity: 1;
    }
}
