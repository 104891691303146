.tap-license-modal.tap-event-modal {
    .ant-modal-body {
        padding: 0 !important;
    }
    .ant-modal-content {
        padding: 0;
        border-radius: .5rem;
    }
    .ant-modal-confirm-body>.ant-modal-confirm-content {
        max-width: unset;
    }
    .ant-modal-confirm-paragraph {
        max-width: unset;
    }
}