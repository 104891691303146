.home-start-guide, .live-events-start-guides
  font-size: 1.8rem
  line-height: 1.6
  & p
    font-size: 1.8rem
    margin-bottom: 1rem
    opacity: .87
  & h3
    font-size: 2.4rem
    line-height: 2.4rem
    color: $tapgrey
    font-family: $InterFaceBold
    letter-spacing: -.5px
    margin-bottom: 2.0rem
  & a
    color: #ff5100
    opacity: 1
    text-decoration: none
  & a.edit-url-link
    color: #222222
    opacity: 1
    text-decoration: underline
    &:hover
      opacity: 0.5
  & a.start-button
    color: #ff5100
    text-decoration: none
    display: block
    &::after
      content: ''
      transform: rotate(-45deg)
      -webkit-transform: rotate(-45deg)
      border: solid #ff5100
      border-width: 0 2px 2px 0
      display: inline-block
      padding: 3px
      margin-bottom: 1px
      margin-left: 3px
    &.start-button-inline
      display: inline-block
      &:last-of-type
        margin-left: 4rem

h1.settings-title
  font-size: 4.6rem
  font-family: $InterFaceBold
  letter-spacing: -.5px
  line-height: 1.2
  margin-top: 0

.start-guide-intro
  margin-bottom: 6rem

.start-block
  display: flex
  flex-direction: row
  margin-bottom: 3rem

.point
  font-family: $InterFaceBold
  font-size: 2.4rem
  line-height: 2.4rem
  font-weight: 600
  color: #ff5100
  flex: 0 0 3rem

.block-body
  display: inline-block
  & p
    margin-bottom: 1rem !important

.callout
  background: #efefef
  border-left: 5px solid #dbdbdb
  padding: 1.5rem 2rem
  margin-bottom: 5rem

.video-title
  font-size: 1.8rem
  margin-bottom: .3rem
  font-family: $InterFaceBold

.video-wrapper
  width: 100%
  height: 100%
  position: relative
  padding-bottom: 56.25%
  padding-top: 25px
  margin-bottom: 3rem
  height: 0
  & iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.start-guide-grid
  border-top: 1px solid #dbdbdb
  padding-top: 3rem
  display: flex
  margin: 3rem 0
  &.tap-page-widget, &.start-guides
    border-top: none
  &.no-submenu
      margin-left: 6.5rem
      width: calc(100vw - 6.6rem)
  &.start-guides
    margin: 0
    padding-top: 0
  &>.column
    flex: 1
    &>p
      width: 100%
      max-width: 100%
  & .column
    margin-right: 5rem
  & .column.resources
    max-width: calc(100% / 2)
    & .resources-box
      background-color: #efefef
      padding: 3rem
      &>h3
        font-size: 1.8rem
        margin-bottom: 2rem
        font-family: $InterFaceBold
        opacity: 1
      &>ul
        opacity: .87
        &>li
          list-style-type: none
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii01IC03IDI0IDI0IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIiBjbGFzcz0iamFtIGphbS1jaGVjayI+PHBhdGggZD0nTTUuNDg2IDkuNzNhLjk5Ny45OTcgMCAwIDEtLjcwNy0uMjkyTC41MzcgNS4xOTVBMSAxIDAgMSAxIDEuOTUgMy43OGwzLjUzNSAzLjUzNUwxMS44NS45NTJhMSAxIDAgMCAxIDEuNDE1IDEuNDE0TDYuMTkzIDkuNDM4YS45OTcuOTk3IDAgMCAxLS43MDcuMjkyeicgLz48L3N2Zz4=) no-repeat left top
          background-position-x: left
          background-position-y: top
          padding: 0px 0 3px 24px
          background-position: left 3px
          margin-bottom: .9rem
