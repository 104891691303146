.content-layout {
    display: grid;

    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;

    grid-template-areas:
        'search'
        'bulk-action'
        'list';

    .search {
        grid-area: search;
    }

    .list {
        grid-area: list;

        & [data-item-index='0'] .posts-group-title {
            padding-top: 0;
        }
    }

    .bulk-action {
        grid-area: bulk-action;
    }
}

.preview-post-gallery {
    min-width: 600px;
    .ant-modal-content {
        padding: 1.6rem !important;
    }

    .ant-modal-title {
        margin-right: 3rem;
    }
}

.content-group-form {
    .ant-form-item-label > label {
        font-size: 1.6rem;
        font-weight: 600;
    }
}

.gallery-post {
    .ant-checkbox-inner {
        @apply size-6 rounded-full;

        &::after {
            width: 0.9rem;
            height: 1.2rem;
        }
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
    .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        border-color: #000000;
    }
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
        .ant-checkbox-checked:not(.ant-checkbox-disabled)
        .ant-checkbox-inner {
        background-color: #000000;
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #000000;
            border-color: rgb(var(--grey-100));
        }
    }
}
