.sso-settings-page {
    hr.settings-hr {
        max-width: 105%;
    }
}

.sso-provider-body-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
        margin-bottom: 0 !important;
    }
}

.sso-drop-block {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .sso-dropdown {
        font-size: 1.6rem;
        margin-right: 3rem;
        display: flex;
        align-items: center;
        width: 200px;
    }
}

.sso-deactivate-button-block {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: -2rem;

    .sso-deactivate-button {
        width: 50px;
    }

    .sso-deactivate-text {
        margin-left: 10px;
    }
}

.sso-field-row {
    display: flex;
    align-items: center;
    max-width: 900px;
}

.sso-custom-field-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
}

.sso-field-item {
    input {
        display: block;
        min-width: 100%;
        width: 100%;
    }
}
