.common-tooltip {
    .ant-tooltip-inner {
        padding: theme('spacing.3') theme('spacing.4');
        border-radius: 1rem;
        // background-color: #fff !important;
        color: theme('colors.disabled-light-gray');
        box-shadow: 0px 0px 1px 0px rgba(23, 15, 73, 0.03), 0px 1px 1px 0px rgba(23, 15, 73, 0.04),
            0px 5px 14px 0px rgba(8, 15, 52, 0.04);
        position: relative;
    }
    .ant-tooltip-arrow {
        &::before {
            height: 10px;
            background-color: transparent;
        }
        &::after {
            border-bottom-right-radius: 6px;
            background: var(--antd-arrow-background-color);
            transform: translateY(50%) rotate(-121deg) skew(28deg);
            height: 13px;
            width: 14px;
        }
    }
}
