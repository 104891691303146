// plugin
//-----------------------------------------------------------------------------------

.remodal-overlay
    background-color: rgba(0, 0, 0, 0.9)
.post-galery
    width: 85vw
    height: calc(100vh - 12rem)
    max-width: 100%
    padding: 1px
    box-sizing: border-box
    background: transparent
    margin: 0 auto
    @media (max-width: 767px)
        width: 100%
.post-galery-item
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: center
  align-items: center
  align-content: stretch
  height: calc(100vh - 20rem)
  & img
    overflow: hidden
    height: 100%

.post-image
  overflow: hidden
  height: 100%
  min-width: 50vw
  & img, video
   object-fit: contain
   object-position: 100% 50%
   right: 0
   height: 100%
   width: 100%
  & video
    background: #000
    object-position: center

.post-info
  background-color: #ffffff !important
  width: 36rem
  min-width: 20vw
  height: 100%

.post-no-caption
  font-style: italic
  color:#262e45

.post-info-wrapper
  padding: 3rem
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  height: 100%
  box-sizing: border-box


.post-galery-group
  & span
    font-family: $InterFaceBold

.slick-initialized
    .post-galery-item
        display: flex !important
.icon-slider-arrow
    width: 2.6rem
    height: 2.6rem
    fill: #ffffff
    transition: all .2s
    &:hover
      transform: scale(1.5)
.slick-prev
    left: -30px
    .icon-slider-arrow:hover
        transform: rotate(180deg) scale(1.5) !important
.slick-arrow
    color: #fff
    width: 2.6rem
    height: 2.6rem
    @media (max-width: 767px)
        display: none !important
    &:before
        display: none
    &.slick-disabled
        opacity: 0.45
    &:hover
        color: #fff
    &:focus
        color: #fff
.slick-prev
    .icon-slider-arrow
        transform: rotate(180deg)
.slick-next, .slick-prev
    &:before
        display: none

.post-info-top
    display: flex
    align-items: center
    justify-content: space-between

.post-galery-name
    font-family: $InterFaceBold
    font-size: 2rem
    font-style: normal
    font-stretch: normal
    line-height: normal

.post-galery-time
    font-size: 12px
    opacity: 0.4

.post-galery-caption
    margin-top: 25px

    &--editable
      margin-top: 0

.post-galery-tag
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal

    color: #ffffff
    border-radius: 0.5rem
    margin-right: 1rem
    margin-bottom: 1rem
    &:hover
        text-decoration: none
    &:last-of-type
        margin-right: 0
.post-galery-tags-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    max-width: 600px
.post-galery-contols-row
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    flex-wrap: wrap
.button-row
    display: flex
    justify-content: space-between
    width: 100%
    margin-top: 1.5rem

.post-galery-btn
    display: block
    width: 45%
    font-family: $InterFace
    font-size: 1.8rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    text-align: center
    color: $tapgrey
    border: 1px solid $tapgrey
    border-radius: 3px
    background-color: transparent
    flex-grow: 1
    cursor: pointer

    &:hover
        color: #ffffff
        background-color: $tapgrey
        opacity: 1
        text-decoration: none
    &:first-child
        margin-left: 0
        margin-right: 1rem

.post-galery-delete
    position: fixed
    right: -2.4rem
    bottom: 7.6rem


//modal verification
.verification, .general-popup
    max-width: 50rem
    text-align: center
    .el-btn
        margin-top: 30px
        @media (max-width: 767px)
            margin-top: 20px
    form
        text-align: center

    .wrap-input-number
        margin-top: 35px
        margin-bottom: 35px
        @media (max-width: 767px)
            margin-top: 25px
            margin-bottom: 25px
        input
            border-radius: 3px
            border: solid 1px #d6d6d6
            background-color: #ffffff
            height: 68px
            width: 240px
            font-family: $InterFace
            font-size: 4rem
            font-weight: 600
            text-align: center
            color: #30b1fc
            letter-spacing: 2px
            padding: 0 20px
            display: block
            margin: 0 auto
            @media (max-width: 767px)
                width: 190px

    .subtitle
        font-family: $InterFace
        font-size: 1.6rem
        text-align: center
        color: $tapgrey

    .modal-title
        font-family: $InterFaceBold
        font-size: 2.1rem
        font-weight: 600
        text-align: center
        color: $tapgrey
        margin-bottom: 3rem
        @media (max-width: 767px)
            margin-bottom: 25px
            font-size: 20px

//success
.success
    border-radius: 3px
    background-color: #33b4ff
    max-width: 850px
    text-align: center
    padding: 50px
    @media (max-width: 767px)
        padding: 30px
    .icon-success
        font-size: 140px
        text-align: center
        color: #ffffff
    .wite-title-success
        font-family: $InterFaceBold
        font-size: 40px
        font-weight: 600
        text-align: center
        color: #ffffff
        margin-bottom: 60px
        @media (max-width: 767px)
            font-size: 20px
            margin-bottom: 30px
//content-request

.content-request
    border-radius: 3px
    background-color: #fafafa !important
    max-width: 850px !important
    text-align: left
    padding: 50px
    @media (max-width: 767px)
        padding: 30px
    .btn-wrap
        margin-top: 35px
    .list-number-people
        width: 100%
        font-family: $InterFace
        font-size: 11px
        text-align: right
        color: $light-grey
        margin-bottom: 10px
        padding-right: 2px
    .modal-title
        font-family: $InterFaceBold
        font-size: 2.1rem
        font-weight: 600
        text-align: left
        color: $tapgrey
        margin-bottom: 3rem
        @media (max-width: 767px)
            font-size: 20px
    .subtitle
        font-family: $InterFace
        font-size: 1.6rem
        text-align: left
        color: $tapgrey
        margin-bottom: 10px
    .wrap-checked-everyone
        @extend %clear-fix
        margin-bottom: 20px
    .wrap-checked-all
        @extend %clear-fix
        &.mod-disabled
            pointer-events: none
            .el-checkbox
                pointer-events: none
                .checkbox-text
                    border: 1px solid #c1c1c1
                    background: transparent
                    color: #c1c1c1

    .el-checkbox
        float: left
        margin-bottom: 10px
        margin-right: 10px
    .content-request-text
        position: relative
        width: 100%
        @extend %clear-fix
        margin-bottom: 30px
        textarea
            box-sizing: border-box
            resize: none
            border-radius: 3px 0 0 3px
            border: solid 1px #d6d6d6
            background-color: #ffffff
            height: 50px
            width: calc(100% - 50px)
            font-family: $InterFaceBold
            font-size: 1.6rem
            font-weight: bold
            color: $dark
            line-height: 46px
            overflow: hidden
            padding: 0 20px
        .count-letear
            border-radius: 0 3px 3px 0
            background-color: #d6d6d6
            font-family: $InterFaceBold
            font-size: 1.6rem
            font-weight: bold
            color: $dark
            line-height: 50px
            text-align: center
            width: 50px
            height: 50px
            float: right

.close-modal
    color: #fff
    font-size: 3rem
    position: fixed
    top: 4rem
    right: 4rem
    z-index: 2
    &.closer-button
        position: absolute
        top: -2rem
        right: -5.5rem
    @media (max-width: 767px)
        right: 0
        font-size: 2rem
.react-post-gallery
    width: auto !important
    height: auto
    padding-bottom: 0
    top: 50px
    .ant-modal-content
        box-shadow: none
        background-color: transparent
    & > div
        & > div
            width: auto !important
            margin: 0 auto !important
    div
        background: transparent
        max-width: none !important
    div:nth-child(1) > div:nth-child(1):not(.white-bg)
        background: transparent !important

    .content-request
        background-color: #fafafa !important
        margin: 0 auto
    .share-post
        background-color: #fafafa !important
        border: 1px solid #dbdbdb
        margin: 0 auto
        max-width: 100% !important
    .shared-post
        background-color: #fafafa !important
        margin: 0 auto
        max-width: 100% !important
    .white-bg
        background-color: #fafafa !important
    .load
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 0

.react-create-content-group, .react-create-chat-group, .react-edit-chat-group, .react-create-event
    & > div
        width: 600px !important
        margin: 0 auto !important
        max-width: 100% !important
    div
        max-width: none !important

    .ant-modal-body
        padding: 0

.general-popup
    padding: 5rem
    text-align: center
    background: #ececec
    border: 1px solid #dbdbdb
    .el-btn:hover
       background: transparent
    .general-action-button
        margin: 0

.verification, .general-popup
    box-sizing: border-box
    padding: 4rem 2rem
    text-align: center
    .modal-title
        font-family: $InterFaceBold
        font-size: 2.1rem
        font-style: normal
        font-stretch: normal
        line-height: normal
        color: $dark
        margin-bottom: 3rem
    .subtitle
        font-family: $InterFace
        font-size: 1.6rem
        font-style: normal
        font-stretch: normal
        line-height: normal

        color: $dark
        a
            color: #30b1fc

.action-popup
    width: 40rem !important
    .general-popup
        padding: 4rem 2rem 3rem
        .modal-title
            margin-bottom: 0

.share-post
    border-radius: 3px
    background-color: #fafafa !important
    max-width: 100% !important
    text-align: left
    padding: 50px
    @media (max-width: 767px)
        padding: 30px
    .btn-wrap
        margin-top: 35px
    .modal-title
        font-family: $InterFaceBold
        font-size: 30px
        font-weight: 600
        text-align: left
        color: $tapgrey
        margin-bottom: 15px
        @media (max-width: 767px)
            font-size: 20px
    .share-post-text
        position: relative
        @extend %clear-fix
        textarea
            box-sizing: border-box
            resize: none
            border-radius: 0.3rem 0 0 0.3rem
            border: solid 0.1rem #d6d6d6
            background-color: #ffffff
            height: 5rem
            width: calc(100% - 50px)
            font-family: $InterFace
            font-size: 1.6rem
            color: $dark
            line-height: 2.1rem
            overflow-y: auto
            padding: .5rem 1rem
        .count-letear
            border-radius: 0 3px 3px 0
            background-color: #d6d6d6
            font-family: $InterFaceBold
            font-size: 1.6rem
            font-weight: bold
            color: $dark
            line-height: 50px
            text-align: center
            width: 50px
            height: 50px
            float: right
.share-post-wrap
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    @media (max-width: 550px)
        flex-direction: column
    .content-column
        flex-basis: auto
        min-width: auto
        height: 16rem
        width: 19rem
        padding-bottom: 0
        @media (max-width: 550px)
            height: 19rem
            padding-bottom: 3rem
.share-img
    position: absolute
    top: 0
    left: 0
    object-fit: cover
    height: 100%
    width: 100%
    flex-grow: 0
    border-radius: 0
.share-video
    &:before
        content: ''
        position: absolute
        width: 0
        height: 0
        border-top: 1.3rem solid transparent
        border-left: 2.6rem solid #fff
        border-bottom: 1.3rem solid transparent
        bottom: 1rem
        right: 1rem
        z-index: 2
        @media (max-width: 767px)
            border-top: 1.3rem solid transparent
            border-left: 2.6rem solid #fff
            border-bottom: 1.3rem solid transparent
.share-post-caption
    font-family: $InterFaceBold
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal

    color: $tapgrey
    margin-bottom: 1rem
.share-post
    max-width: 100%
    box-sizing: border-box
    .share-post-text
        .share-textarea
            width: 30rem
            height: 13rem
            resize: none
            max-width: 100%

.shared-post
    width: 75rem
    height: 40rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-height: 100%
    .modal-title
        font-family: $InterFaceBold
        font-size: 2.1rem
        color: $tapgrey

.slide-request-text
    width: 100%
    color: #fff
    font-family: $InterFace
    font-size: 1.6rem
.slide-request-name
    width: 100%
    color: #fff
    font-family: $InterFace
    font-size: 1.6rem
    margin-bottom: 0.5rem
.post-shared-at
    width: 20rem
    color: #222222
    font-family: $InterFace
    font-size: 1.6rem
    margin-bottom: 1.5rem

.el-btn
    &.confirm-delete-button, &.cancel-delete-button
        margin-left: 3rem
        margin-right: 2rem
    &.cancel-delete-button
        background-color: #ffffff
        border-color: #000000
        color: #000000
    &.block-prospect-button
        width: 60%

.slideOne
  display: inline-block
  width: 7rem
  height: 3rem
  margin: 5px 0
  position: relative
  border-radius: 14px
  transition: all 0.4s ease
  &:hover
    cursor: pointer
  &::after
    position: absolute
    top: 0
    left: 0
    width: 7rem
    height: 3rem
    border-radius: 2.5rem
    content: ''
    overflow: hidden
    transition: all 0.4s ease
    background-color: rgba($dark, 0.03)
    border: 1px solid #dbdbdb
    z-index: 1
  &::before
    position: absolute
    font-family: $InterFaceBold
    font-size: 1.4rem
    left: -4rem
    top: 3rem
    content: ''
    cursor: auto
    border: .1rem solid #e7e7e7
    background-color: #fff
    border-radius: 3px
    padding: .6rem .9rem
    opacity: 0
    visibility: hidden
    transition: all .45s
    box-shadow: 0 3px 12px rgba(0,0,0,.12)
    white-space: nowrap
    z-index: 2

  .slideOne-icon
    display: block
    width: 2.5rem
    height: 2.5rem
    position: absolute
    top: .33rem
    left: .25rem
    cursor: pointer
    background: #f90052
    border-radius: 50px
    transition: all 0.4s ease
    &:before
      content: 'Off'
      font-size: 1.2rem
      font-family: $InterFaceBold
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      color: #fff

  input[type="checkbox"]
    visibility: hidden

    &:checked + .slideOne-icon
      left: 4.25rem
      background: #29d266
      z-index: 1
      &:after
        left: -4.5rem
        background-color: rgba(#d9d9d9, 0.03)
      &:before
        content: 'On'

.user-status-row
    padding-left: 10px

.post-shared-at
    width: 20rem
    color: #222
    font-family: $InterFace
    font-size: 1.6rem
    margin-bottom: 1.5rem

.confirm-delete-button
    margin-left: 2rem


.start-page
    font-family: $InterFace
    color: #000
    line-height: 3rem
    ol
        padding-left: 3rem
    .modal-title
        font-size: 2.1rem
        font-weight: 500
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: -0.2px
        color: $dark
        margin-bottom: 3rem
    .bold-list
        font-family: $InterFace
    ul
        list-style: circle
        padding-left: 6rem
    .link
        color: $tapgrey
        font-family: $InterFace
.react-create-new-question
    &>div
        width: auto !important
        max-width: none !important
        &>div
            width: auto !important
            margin: 0 auto
.ui-kit-label
    position: relative
    display: block
.profile-info-popup
    max-width: 100%
    box-sizing: border-box
.profile-info-tags-name
    font-family: $InterFaceBold
    font-size: 1.4rem
    color: $dark
    margin-bottom: 1.5rem
    text-align: left
    span
        display: block
        font-size: 1.6rem
        color: #ff5100

.profile-info-tags-row
    text-align: left
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    .user-info-tag
        display: block
        margin-right: 0.5rem
        margin-bottom: 0.5rem
        padding: 0.3em 0.8rem
        background-color: #fff
        border: 1px solid #d9d9d9
        border-radius: 0.3rem
        font-size: 1.4rem
        font-family: $InterFace
        color: $dark
    .sa-info-tag
        font-size: 1.4rem
        font-family: $InterFace
        color: $dark

.profile-info-tags-row > .sa-info-tag:not(:last-child):after
    content: "\2022"
    margin: 0 1rem
    font-size: 1.1rem

input, button, select, optgroup, textarea
    font-size: inherit
.ant-table-thead > tr > th.ant-table-cell
    color: #595959 !important
    font-weight: 500 !important
.ant-tooltip-arrow:not(.common-tooltip *)::after
    background: rgba(0, 0, 0, 0.75) !important
.ant-tooltip:not(.common-tooltip)
    --antd-arrow-background-color: transparent !important
.ant-tooltip-inner:not(.common-tooltip *)
    background-color: rgba(0, 0, 0, 0.75) !important
.ant-table-tbody > tr.ant-table-row-selected > td
    background: #fafafa !important
.ant-table-tbody > tr.ant-table-row-selected:hover > td
    background: #f5f5f5 !important
.ant-btn-link
    color: $taporange
    &:hover
        color: $taporange !important
        opacity: 0.5
.ant-btn-dangerous.ant-btn-primary
    border-color: #ff4d4f

.ant-modal-content
    padding: 0 !important

.ant-image-preview-root, .ant-image-mask, .ant-image-preview-operations-wrapper
    display: none !important

.checkbox-group-custom
    display: block !important

.ant-popover-arrow
    &:before
        clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z')

