.home-page {
    line-height: 1.6;
    &.with-not-completed-steps {
        .column {
            min-height: 700px;
            max-height: 800px;
            &.column-left {
                grid-auto-rows: minmax(50px, 80px) auto minmax(250px, auto)
            }
            &.column-right {
                grid-auto-rows: minmax(50px, 60px) minmax(230px, auto) minmax(350px, auto)
            }
        }
    }
    .home-title {
        font-family: $InterFaceBold;
        font-size: 3.2rem;
        line-height: 1.2;
        margin-top: 0;
    }
    a {
        color: $taporange;
        text-decoration: none
    }
    img {
        max-width: none
    }
    .home-body {
        display: grid;
        grid-template-columns: minmax(780px, 1.5fr) minmax(400px, 1fr);
        grid-gap: 2rem;
        height: 95vh;
        min-height: 780px;
        max-height: 1000px;
        grid-auto-rows: 35px minmax(440px, 480px) 330px
    }
}
.intro-text {
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #222222;
    margin-bottom: 50px
}
.recent-activity-block {
    box-shadow: 0 0 3px 0 rgba(34, 34, 34, 0.09);
    background-color: #ffffff;
    padding: 16px 20px 0;
    .main-graph-label-wrapper {
        justify-content: unset;
        padding-left: 40px;
        margin-top: 14px
    }
    .chart-label-description {
        font-size: 1.4rem
    }
}
.graph-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center
}
.reporting-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.6rem;
    letter-spacing: normal;
    span {
        margin-right: 8px
    }
    svg {
        width: 12px !important
    }
}
.main-graph {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    padding: 0 19px 40px;
    border-radius: 5px;
    text-align: center;
    .no-data-title {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.27;
        letter-spacing: normal;
        text-align: center;
        color: #262e45;
        margin-top: 12px
    }
    .no-data-text {
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: center;
        color: #222222;
        opacity: 0.7;
        margin-bottom: 40px
    }
    img {
        width: 70%;
        margin: auto;
        max-height: 70%
    }
}
.chart-header {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .ant-select-selector {
        height: 35px !important;
        .ant-select-selection-item {
            line-height: 32.5px !important;
            text-align: left
        }
    }
}
.home-graph {
    overflow: hidden;
    width: calc(100% - 60px);
}
.recent-data-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem
}
.inactive-sa-block {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .inactive-ambassadors {
        overflow-y: auto
    }
    .inactive-sa {
        margin-bottom: 8px
    }
}
.inactive-sa-block, .recent-posts-block, .recent-answers-block, .recent-actions-block, .complete-setup-block {
    background-color: #ffffff;
    padding: 16px 20px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(34, 34, 34, 0.09)
}
.recent-posts-block, .recent-answers-block {
    display: grid;
    grid-template-rows: auto 1fr
}
.recent-posts-block-not-empty, .recent-answers-block-not-empty {
    display: flex
}
.recent-posts-with-data {
    display: flex;
    flex-direction: column
}
.recent-posts-title {
    margin-left: 0;
    width: 100%
}
.recent-posts-date-range, .recent-posts-answers-range {
    font-size: 1.4rem;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: rgba(34, 34, 34, 0.5)
}
.recent-posts-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    max-width: max-content;
    margin-top: 20px;
    .recent-post {
        position: relative;
        object-fit: cover;
        height: 60px !important;
        width: 60px !important;
        cursor: pointer;
        .ant-image-img {
            height: 100%;
            object-fit: cover;
        }
    }
    .recent-post:nth-child(1), .recent-post:nth-child(2) {
        height: 130px !important;
        width: 130px !important;
        min-height: 60px;
        min-width: 60px
    }
    .recent-post:nth-child(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 3
    }
    .recent-post:nth-child(2) {
        grid-column: 3 / 5;
        grid-row: 1 / 3
    }
}

.view-more-posts {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c4856;
    color: #ffffff;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.6)
    }
}
.recent-answers-block-not-empty {
    padding: 10px 10px 10px 0 !important;
    .recent-answers-block-with-data {
        padding: 6px 0 6px 20px;
        width: 100%
    }
}
.recent-answers-list {
    overflow-y: auto;
    height: 200px;
    margin-right: 8px;
    margin-top: 20px
}
.recent-answer-item {
    border-left: solid 2px #ff5100;
    padding-left: 10px;
    margin: 20px 0 10px;
    letter-spacing: normal;
    line-height: 1;
    &:first-child {
        margin-top: 0
    }
    .recent-answer-item-question, .recent-answer-text-block {
        cursor: pointer
    }
    b {
        line-height: 1.2
    }
    .recent-answer-item-data {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        img {
            height: 30px;
            width: 30px;
            min-width: 30px;
            border-radius: 50%;
            margin-top: 7px
        }
        span {
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: rgba(34, 34, 34, 0.8);
            line-height: 1.5;
            letter-spacing: normal;
            .DraftEditor-root {
                margin-bottom: 0
            }
        }
    }
}
.inactive-sa, .active-sa, .recent-posts-title, .recent-answers-title {
    font-size: 1.7rem;

    b {
        line-height: 1.28;
        letter-spacing: normal;
        color: #262e45
    }

    span {
        font-weight: 600;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: left;
        color: #aeaeae;
        margin-left: 10px;

        &.inactive-sa-number, &.recent-posts-number, &.recent-answers-number {
            color: $taporange !important
        }
    }
}
.inactive-sa-block .ant-image {
    display: flex;
    height: 100%;
}
.no-inactive-sa-image {
    display: flex;
    align-items: center;
    width: auto !important;
    max-width: 150px;
    margin: auto;
    height: 40% !important;
    min-height: 90px
}
.inactive-sa-list {
    overflow-y: auto;
    padding-right: 21px;
    .inactive-sa-item {
        display: flex;
        padding: 12px 0;
        align-items: center;
        font-size: 1.6rem;
        line-height: 1.31;
        letter-spacing: normal;
        &:not(:last-child) {
            border-bottom: 1px solid #bababa4a
        }
    }
}
.inactive-sa-item {
    img {
        width: 30px !important;
        height: 30px !important;
        object-fit: cover;
        border-radius: 50%
    }
    .sa-item-text-block {
        width: 100%;
        margin-left: 10px;
        .sa-item-text-line {
            display: flex;
            justify-content: space-between;
            a {
                white-space: nowrap
            }
        }
    }
    .inactive-sa-name {
        color: #222222;
        padding-right: 7px;
        word-break: break-word
    }
}
.inactive-sa-disabled-text, .inactive-sa-date-text {
    color: rgba(34, 34, 34, 0.7)
}
.no-answers-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: flex;
        width: auto !important;
        margin: auto;
        height: 150px !important;
        min-height: 50px
    }
}

.tip-admin-centre {
    display: flex;
    margin-left: auto;
    padding-top: 10px;
    align-items: center;
    img {
        width: 24px !important;
        height: 24px !important;
    }
    a {
        margin-left: 8px;
        font-size: 1.6rem;
        line-height: 1.25;
        letter-spacing: normal;
        color: #222222;
        opacity: 0.9;
        &:hover {
            opacity: 0.6
        }
    }
}
.graph-title, .complete-setup-title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #262e45;
    margin: 0 0 12px
}
.graph-title {
    margin: 0 !important
}
.complete-setup-block {
    .complete-setup-title {
        margin: 0
    }
    .steps-number-completed {
        font-size: 1.4rem;
        line-height: 1.29;
        letter-spacing: normal;
        margin-bottom: 19px;
        color: rgba(38, 46, 69, 0.7);
    }
    .complete-simple-link {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    .complete-simple-text {
        padding-bottom: 2px
    }
}

.complete-setup-list {
    display: grid;
    grid-template-rows: auto;
    .step-line {
        display: flex;
        align-items: center;
        cursor: pointer;
        min-height: 40px;
        max-height: 80px;
        padding: 10px 0
    }
    .complete-circle {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid $taporange;
        border-radius: 50%;
        &.with-line::before {
            position: absolute;
            bottom: 0;
            content: "";
            border-left: 1px solid $taporange;
            height: 24px;
            top: 130%;
            right: 50%
        }
        &.completed-circle {
            background-color: #4cd964;
            border: none;
            &::before {
                border-left: 1px solid #4cd964 !important
            }
            &::after {
                content: "";
                background-color: transparent;
                position: absolute;
                left: 5px;
                top: 2.3px;
                width: 4.5px;
                border-bottom: 1.5px solid #ffffff;
                height: 7px;
                border-right: 1.5px solid #ffffff;
                transform: rotate(45deg)
            }
        }
    }
    .complete-step-text {
        font-size: 1.6rem;
        letter-spacing: normal;
        color: #222222;
        margin-left: 16px;
        padding-bottom: 2px;
    }
    svg {
        margin-left: 10px;
        path {
            fill: $taporange
        }
    }
}

.recent-actions-block {
    display: grid;
    grid-auto-rows: minmax(35px, 41px) minmax(62px, 77px) minmax(61px, 76px) minmax(180px, auto);
    grid-gap: 1rem;
    font-size: 1.6rem;
    line-height: 1.25;
    letter-spacing: normal;
    color: #262e45;
    .recent-actions-title {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: left;
        color: #3c4856
    }
    .flagged-chats-line-inactive, .keywords-chats-line-inactive, .feedback-chats-line-inactive {
        svg path {
            fill: #bababa
        }
        .actions-chats-number {
            color: #bababa
        }
    }
    .feedback-type-item-grey .actions-chats-number, .recent-actions-type-inactive .actions-chats-number {
        color: #bababa
    }
    .action-line {
        display: grid;
        grid-template-columns: auto 120px 1fr auto;
        min-height: 40px;
        max-height: 80px
    }
    .keywords-chats-line svg, .feedback-action-line svg, .flagged-chats-line svg {
        width: 24px !important
    }
    .keywords-chats-line, .flagged-chats-line {
        border-bottom: solid 1px rgba(38, 46, 69, 0.1)
    }
    .flagged-chats-line {
        .actions-chats-text {
            padding-top: 2px
        }
        .recent-actions-types {
            padding-top: 2px
        }
    }
    .keywords-chats-line {
        .actions-chats-text {
            padding-top: 1px
        }
        .recent-actions-types {
            padding-top: 1px
        }
    }
    .review-link {
        margin-left: auto;
        font-size: 1.6rem
    }
    .feedback-chats-line {
        display: flex;
        flex-direction: column;
        cursor: default;
        .feedback-action-line {
            display: flex;
            align-items: center
        }
    }
    .actions-chats-number {
        color: $taporange;
        font-weight: 600
    }
    .actions-chats-text {
        margin: 0 10px 0 13px;
        text-decoration: underline
    }
    .recent-actions-types {
        padding-left: 5px;
        .recent-actions-type {
            display: flex;
            margin-bottom: 4px;
            white-space: nowrap;
            .recent-actions-type-text {
                margin-right: 8px
            }
            .actions-chats-number {
                margin-right: 20px
            }
        }
    }
    .feedback-chats {
        margin-left: 26px;
        width: 80%;
        margin-top: 10px
    }
    .feedback-chats-types {
        display: flex;
        justify-content: space-between;
        .feedback-type-item {
            display: flex;
            flex-direction: column;
            padding: 8px 0 8px 10px;
            .feedback-item-smile {
                height: 20px;
                width: 20px;
                margin-right: 8px
            }
            .review-link {
                margin: 4px 0 0 !important;
                text-align: center
            }
        }
    }
}

.inactive-ambassadors, .recent-answers-block-with-data {
    &::-webkit-scrollbar {
        width: 8px
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(#222222, 0.2)
    }
    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: unset
    }
}

@media (min-height: 900px) {
    .main-graph {
        min-height: 350px;
        .home-graph {
            bottom: 20px
        }
    }
}
@media (min-height: 950px) {
    .promotions-list p {
        margin-bottom: 6px
    }
}
