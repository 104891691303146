.ant-tabs-ink-bar {
    height: 0px;
}

.ant-calendar-next-year-btn {
  display: none !important;
}

.ant-calendar-prev-year-btn {
  display: none !important;
}

.ant-select-dropdown-menu-item {
    white-space: initial;
}

.ant-input-search-icon {
    display: none !important;
}

.ant-avatar{
    margin-right: 15px;
}

.ant-list-item-meta {
    align-items: center;
}

.ant-list-item-meta-title {
    font-size: 1.6rem;
    text-align: left;
}

.ant-table-pagination.ant-pagination {
    float: none;
    text-align: center;
}

th.ant-table-selection-column > div.ant-table-selection {
    width: 30px;
}

.ant-pagination-item-active {
    border: none;
}

.ant-checkbox-checked::after {
    border: none;
}

.ant-table-selection .anticon-down {
    color: #ff5100;
}

.ant-table-selection .anticon-down:hover {
    color: #ff5100;
}

.ant-popover-inner-content {
    padding: 0px;
}