// reporting page
//-----------------------------------------------------------------------------------

.settings-container
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  flex-wrap: wrap
  @media (max-width: 1354px)
    flex-direction: column

.tile-grid
  display: inline-grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  grid-gap: 2rem
  margin: 0 auto
  .tile
    background: white
    padding: 1rem
    display: flex
    align-items: center
    justify-content: center

    &::before
      content: ''
      float: left
      padding-top: 100%
    .tile-wrap
      height: 100%
      width: 100%
      display: flex
      flex-direction: column
      justify-content: center

.tile h3, .tile-stat
  width: 100%
  text-align: center
  font-family: $InterFace
  font-weight: normal
  height: 5rem

.tile-stat
  font-family: $InterFaceBold
  font-size: 5.5rem
  color: $taporange
  height: 12rem
  display: flex
  justify-content: center
  align-items: center

  @media (max-width: 1140px)
    font-size: 4rem

  &.feedback
    font-size: 3rem
    text-align: right

  &__sub-article
    font-size: 1.2rem

.choosen-item
  display: flex
  align-items: center
  *
    margin-bottom: 0!important
    margin-right: 10px
    color: #ff5100
  p
    font-size: 18px
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 1.17
    letter-spacing: normal
    text-align: left
    color: #ff5100
  #chevron-down
    fill: #ff5100
    cursor: pointer
  #help
    opacity: 0.3
    fill: #262e45

.text-select
  position: relative
  margin-bottom: 24px
  display: table
  margin-top: 24px
  .select-list
    position: absolute
    top: 100%
    left: 20px
    display: table
    min-width: calc( 100% - 20px )
    background: #fff
    width: auto
    box-shadow: 0px 4px  5px rgba(0,0,0,.1)
    li
      padding: 5px 10px
      cursor: pointer
      color: rgba(0,0,0,.7)
      &:hover
        color: #ff5100

.view-profile-info-btn.blue-mod
  background: #262e45
  color: #fff

.red-label
  color: #fe3057

.time-table-container
  .table-head-item:nth-child(6)
    min-width: 150px

  .table-head-item:not(:first-child),
  .table-item:not(:first-child)
    text-align: center

  .view-profile-info-btn
    width: 100px

.prospect-table-datepicker
  position: relative
  z-index: 999

.react-datepicker__close-icon
  margin: 0

.react-datepicker__close-icon::after
  line-height: 16px

.number-time-btn
  opacity: 0.2
  display: block
  margin: 0 auto

.time-limit-modal
  padding: 52px 20px 20px
  text-align: center

  .modal-title
    font-family: $InterFace
    margin-bottom: 36px

  .hidden-buttons-block
    display: block
    width: 100%
    text-align: right

  .input-row
    display: flex
    justify-content: center
    margin-bottom: 30px
    padding: 0 85px
    p
      font-family: $InterFace
      font-size: 22px
      font-weight: normal
      font-style: normal
      font-stretch: normal
      line-height: 1.18
      letter-spacing: normal
      text-align: left
      color: #262e45
      margin-left: 10px

    .number-time-input
      width: 50%
      margin: 0 15px
      display: flex
      align-items: center

      input
        width: 40px
        height: 40px
        border-radius: 3px
        border: solid 0.5px rgba(38, 46, 69, 0.3)
        background-color: rgba(38, 46, 69, 0.02)
        line-height: 40px
        font-size: 23px
        font-weight: normal
        font-style: normal
        font-stretch: normal
        line-height: 1.17
        letter-spacing: normal
        text-align: center
        color: #ff5100
        margin: 10px 0

.rp-time-filter
  display: inline-block
  vertical-align: top
  width: 405px
  max-width: 50%
  padding: 20px 0
  margin-right: 50px
  .time-filter-dates
      display: flex
  &.active
    *
      opacity: 1!important
    .radion-btn
      box-shadow: inset 0 0 1px 3px #fff
      border-color: #ff5100
      background: #ff5100

    .rp-time-filter-title
      p
        color: #ff5100

.rp-time-filter-title
  display: flex
  align-items: center
  margin-bottom: 15px
  span
    width: 16px
    height: 16px
    border: solid 1px  #262e45
    background-color: #ffffff
    border-radius: 50%
    display: inline-block
    vertical-align: middle
    margin-right: 8px
    opacity: 0.7
    cursor: pointer
  p
    display: inline-block
    vertical-align: middle
    margin-bottom: 0!important
    font-family: InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    color: #262e45
    opacity: 0.7
    cursor: pointer
  &.active-item
    *
      opacity: 1!important
    span
      box-shadow: inset 0 0 1px 3px #fff
      border-color: #ff5100
      background: #ff5100
    p
      color: #ff5100

.rp-time-filter
  .rp-time-filter-btns
    display: flex
    li
      display: block
      width: 140px
      text-align: center
      background: #fff
      opacity: 0.7
      font-family: InterFace
      font-size: 1.6rem
      font-weight: normal
      font-style: normal
      font-stretch: normal
      line-height: 1.19
      letter-spacing: normal
      text-align: center
      color: #262e45
      border-width: 1px
      border-style: solid
      border-color: #fff
      height: 3.3rem
      line-height: 3.3rem
      cursor: default
      &.active
        border-color: grey
      &:not(:last-child)
        margin-right: 15px

.rp-time-filter.active .rp-time-filter-btns li
  cursor: pointer
  &.active
    border-color: rgba(255, 81, 0, 0.7)!important

.time-limit-types
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: solid 1px rgba(38, 46, 69, 0.1)
  padding-bottom: 2px
  max-width: 250px
  width: 100%
  margin: 0 auto 25px
  .rp-time-filter-title
    margin: 0

.rp-select-block
  display: inline-block
  width: 150px
  margin-left: 15px

.reporting-block
  border-radius: 2px

.uni-multi-select
    width: 100%
    border: solid 1px #dbdbdb
    border-radius: 3px
    .ant-select-selector
        font-size: 1.6rem
        font-family: $InterFace
        input
            margin-left: 0 !important

.active-reporting-drop-dates-block
  justify-content: space-between
  margin: 0 0 40px 50px !important
  @media (max-width: 1100px)
    flex-direction: column
    justify-content: unset

.chart-button
  cursor: pointer
  font-family: $InterFace
  font-size: 1.8rem
  font-weight: normal
  font-stretch: normal
  font-style: normal
  letter-spacing: normal
  text-align: left
  color: #222222
  margin-right: 20px
  &.selected-chart-button
    cursor: default
    font-weight: 600
    color: #ff5100
    border-bottom: 2px solid #ff5100
  &:hover
    border-bottom: 2px solid #ff5100

.chart-title-header-block
  display: flex
  align-items: baseline
.chart-title-header
  font-family: $InterFace
  font-size: 2.4rem
  font-weight: normal
  font-stretch: normal
  font-style: normal
  line-height: 1.27
  letter-spacing: normal
  text-align: center
  color: #222222
  margin-left: auto
.m-left-auto
  margin-left: auto

.chart-label-wrapper
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: center
  margin-top: 10px
  &--display-single-label
    justify-content: flex-end

.chart-label-item-wrapper
  display: flex
  flex-direction: column
  align-items: center

.chart-title
  color: #0a2540
  font-size: 24px
  margin-left: 8px

.chart-label
  color: #ff5100
  font-size: 20px
  margin-right: 8px
  text-align: right

.chart-label-description
  color: rgba(0, 0, 0, 0.45)
  font-size: 12px
  margin-right: 8px
  text-align: right

.report-diagram-header
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-bottom: 40px
  margin-left: 50px

.search-control-container
  display: flex
  .search-control-item
    &.filter
      margin-left: 1.6rem
    .search-control-title
      margin-bottom: 0.4rem

.search-wrapper
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
.search-criteria-dropdown
  width: 16rem !important

.ambassadors-search-wrapper
  margin-top: 80px

.table-wrapper
  margin-top: 35px

.spinner-wrapper
  text-align: center

.avatars
  display: flex
  flex-direction: row

.avatars-wrapper
  display: flex
  flex-direction: column
  align-items: flex-end
  margin-top: 25px

.counter
  margin-top: 10px

.all-selected
  color: #ff5100
  margin-bottom: 15px
  font-size: 10px

.avatars-block
  display: flex
  flex-direction: column
  margin-right: 15px

.amnassadors-img-block
  width: 3rem
  height: 3rem
  border-radius: 50%
  position: relative
  overflow: hidden
  min-width: 3rem

.amnassadors-avatar-name
  overflow: hidden
  text-overflow: ellipsis
  text-align: center

tr.table-row > td
  text-align: left
  vertical-align: middle

// tr.table-row:nth-child(odd)
//   background: #fafafa

// tr.table-row:nth-child(even)
//   background: #fff

thead[class*="ant-table-thead"] th
  vertical-align: middle
  text-align: center
  &:first-child
    text-align: left

tbody[class*="ant-table-tbody"] td
  text-align: center
  &:first-child
    text-align: left

.ant-table-thead > tr > th.ant-table-column-sorters span
  font-size: 1.6rem

.ant-table-thead > tr > th.ant-table-cell
  font-size: 1.6rem

.graph-dropdown .ant-select-selector
  border: none !important
  box-shadow: none !important
  font-size: 20px

.graph-dropdown .ant-select-arrow
  color: #ff5100

.graph-dropdown-options
  font-size: 16px

.normal-action-button
  font-size: 16px
  color: #ff5100
  border-color: #ff5100
  margin-right: 8px

.view-conversations-btn
    font-family: $InterFace
    font-size: 1.2rem
    color: #222222
    background-color: #d9d9d9
    padding: 0.2rem 1.5rem
    border-radius: 0.5rem

.prospect-info-popup
  display: grid
  grid-template-rows: auto minmax(250px, 1fr)
  grid-row-gap: 30px
  height: 100%
  padding: 0 6px
  .prospect-data-blocks-body
    height: 350px
  .prospect-data-blocks
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    text-align: center
    cursor: pointer
    border-bottom: 1px solid #e0dcdc
    &.prospect-data-blocks-with-questions-data
        grid-template-columns: 1fr 1fr
  .prospect-data-block-name
    color: #262e45
    margin-bottom: -1px
    padding: 0 2.5rem 0.5rem
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 1.19
    letter-spacing: normal
    &.active
      color: #ff5100d6
      font-weight: bold
      border-bottom: 1.5px solid #ff5100d6
  .card-info-block
    padding: 30px 0 18px
    height: 100%
    &.one-card
      border-top: 1px solid #dadada
      margin-top: 30px

.loader-in-popup
  display: flex
  justify-content: center
  margin-top: 25%

.common-prospect-info-block
  display: flex
  flex-direction: row
  line-height: 1
  padding: 12px 0
  .ant-avatar
    font-size: 42px !important
    margin-right: 25px
    min-width: 70px
  .prospect-text-name
    font-family: "Source Sans Pro"
    font-weight: bold
    margin-right: 16px

.heat-score-popover
    font-size: 1.6rem
    padding: 12px
    font-family: 'Source Sans Pro'
    ul.popover-heat-score-list
        margin-top: 8px
        list-style: disc inside

.prospect-info-text
  font-size: 1.6rem
  width: 100%
  .heat-score-block
    display: flex
    justify-content: space-between
    width: 100%
    h2
      font-family: "Source Sans Pro"
      font-size: 2rem
      font-weight: 600
      line-height: 1.25
      letter-spacing: normal
      margin-bottom: 20px
    .heat-score-data
      display: flex
      flex-direction: column
      align-items: center
      cursor: pointer
      .anticon
          align-items: unset
    .heat-score-data .warm-heat-score, .heat-score-data .cold-heat-score
      width: auto
      padding: 5px 14px
      border-radius: 4px
      background-color: #4cd964
      color: #ffffff
      line-height: 1.5
      font-size: 1.4rem
      &.cold-heat-score
        background-color: #fe3057
    .heat-score-data svg
      cursor: pointer
      margin-top: 6px
      margin-left: 5px
      width: 16px
      height: 16px
      fill: #a7a7a7
    .heat-score-text-block
      display: flex
    .heat-score-text-block .heat-score-text
      padding-top: 2px
      opacity: 0.6
      font-family: $InterFace
      font-size: 1.6rem
      line-height: 1.5
      letter-spacing: normal
      color: #222222

.activity-table-column
  text-align: center

.optional-data-block
  height: 100%
  overflow-y: auto
  display: grid
  grid-template-columns: 1fr 1fr
  grid-auto-rows: max-content
  column-gap: 14px
  padding: 0 14px
.optional-data-name
  font-family: $InterFace
  font-weight: bold
  word-break: break-word
.optional-data-value
  word-break: break-word

.optional-data-item
  &:not(:last-child)
    padding-bottom: 26px

.no-prospect-info-data
  font-size: 1.6rem
  padding-left: 18px

.analytics-data-block
  overflow-y: auto
  overflow-x: hidden
  height: 100%
.analytics-text:nth-child(odd)
  background-color: #F6F6F6
.analytics-title, .topics-title, .feedback-title
  font-size: 1.6rem
  font-weight: 600
  line-height: 1.5
  letter-spacing: normal
  padding: 0 0 9px
.analytics-text
  display: grid
  grid-template-columns: 0.8fr 1fr
  position: relative
  font-size: 1.6rem
  line-height: 2.5
  letter-spacing: normal
  color: rgba(34, 34, 34, 0.8)
  padding: 0 14px
  word-break: break-all
.analytics-text-name
  padding-right: 10px

.topics-data-block
  display: grid
  grid-template-rows: auto 1fr
  height: 100%
.topics-list
  display: grid
  grid-template-columns: 1fr 1fr
  grid-auto-rows: max-content
  height: 100%
  overflow-y: auto
.topics-title
  padding-left: 14px
.topics-text:nth-child(4n+1), .topics-text:nth-child(4n+2)
  background-color: #F6F6F6
.topics-text
  font-size: 1.6rem
  line-height: 2.7
  letter-spacing: normal
  color: rgba(34, 34, 34, 0.8)
  word-break: break-word
  padding: 0 14px

.feedback-title
  display: grid
  grid-template-columns: 1fr 1.2fr
  grid-gap: 12px
  font-size: 1.6rem
  font-weight: 600
  line-height: 1.5
  letter-spacing: normal
  padding: 0 14px
.feedback-data-block
  height: 100%
  display: grid
  grid-template-rows: auto 1fr
  grid-gap: 10px
.feedback-data-items
  overflow-y: auto
  height: 100%

.feedback-data-item
  display: grid
  grid-template-columns: 1fr 1.2fr
  grid-gap: 12px
  padding: 16px 8px 16px 14px
.feedback-data-item:nth-child(odd)
  background-color: #F6F6F6

.feedback-sa-info
  display: grid
  grid-template-columns: auto 1fr
  img
    width: 42px
    height: 42px
    border-radius: 50%
  .feedback-sa-name-block
    margin-left: 14px
    .feedback-sa-name
      font-size: 1.6rem
      font-weight: 600
      line-height: 1.5
      letter-spacing: normal
      word-break: break-word
    .feedback-sa-courses
      font-size: 1.4rem
      line-height: 1.71
      letter-spacing: normal
      color: rgba(34, 34, 34, 0.8)
      word-break: break-word

.feedback-info
  .feedback-text-title
    font-family: "Source Sans Pro"
    font-size: 1.6rem
    font-weight: 600
    line-height: 1.5
    letter-spacing: normal
    .feedback-score
      color: $taporange
  .feedback-text
    font-size: 1.6rem
    line-height: 1.5
    letter-spacing: normal
    color: rgba(34, 34, 34, 0.6)
    word-break: break-word


// Faq, Content
.faq-reporting, .content-reporting
  .faq-table-title, .content-table-title
    height: 3.9rem
    font-size: 2.4rem
    font-family: $InterFace
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.27
    letter-spacing: normal
    text-align: left
    color: #000000
    margin-left: 15px
  .top-wrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: 26px
    .left-wrapper
      display: flex
      justify-content: flex-start
      .total-views, .total-likes
        font-size: 1.8rem
        color: #262e45
        height: 3.8rem
        opacity: 0.8
        font-family: $InterFace
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.25
        letter-spacing: normal
        text-align: left
        margin-left: 20px
        .numbers
          padding-right: 8px
          font-weight: 600
          color: #ff5100
          font-size: 2rem

  .ant-table-tbody
    tr:nth-child(1n) td
      background-color: #ffffff !important
    tr:nth-child(2n) td
      background-color: #fafafa !important
    tr:not(:last-child)
      td
        border-bottom: none !important
    td
      vertical-align: middle
      white-space: pre-wrap
    tr td
      &:first-child
        min-width: 300px

.content-reporting
  .post-image-wrapper, .post-video-wrapper
    display: flex
    align-items: center
  .report-post-image, .report-post-video
    top: 0
    left: 0
    object-fit: cover
    height: 10rem !important
    width: 10rem !important
    flex-grow: 0
    border-radius: 0
    transition: all .2s
    img, video
        object-fit: cover
  .report-post-text
    margin-left: 2rem

.activity-reporting, .ambassador-reporting, .prospects-reporting, .trend-analyzer-reporting
  thead[class*="ant-table-thead"] th
    &:nth-child(2)
      text-align: left
  tbody[class*="ant-table-tbody"] td
    &:nth-child(2)
      text-align: left

.table-university-field
  display: flex
  align-items: center
  width: max-content
  .table-university-img
    width: 20px
    height: 20px
    border-radius: 10px
    margin-right: 5px
  &.table-university-field-centered
      width: 100%
      justify-content: center

.group-name-popup
  cursor: default

.prospects-reporting
  .reporting-title-block
    margin: 0 0 12px !important
  .prospect-table-title
    font-size: 2.4rem
    font-weight: normal
    margin: 0

.table-item-heat-score
  color: #4cd964
  &-red
    color: #fe3057

.graph-donut-temporarily-block
  position: relative
  display: flex
  height: 300px
  img
    position: absolute
    height: auto
    max-height: 255px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  span
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-size: 25px
    color: $taporange
    text-decoration: underline
    text-align: center

.prospects-reporting
    .engagement-line
        text-align: left
        padding-bottom: 1rem
        height: 3.7rem
        a
            text-decoration: underline
    .table-item-rate
        font-size: 2rem !important
        height: 3.7rem
