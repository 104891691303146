.bulk-toolbar {
    box-shadow: 4px 4px 8px 0px rgba(32, 68, 103, 0.1);

    & > * + * {
        position: relative;
        margin-left: 2px;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 2px;
            top: 0;
            left: -2px;
            bottom: 0;
            border-radius: 2px;

            @apply bg-grey-200;
        }
    }
}

.toolbar-btn {
    font-size: inherit;
    line-height: inherit;
    @apply flex items-center gap-2 rounded px-4 py-2;
    margin-left: calc(0.8rem + 2px);

    &::before {
        left: calc(-0.4rem - 2px);
    }

    &:disabled {
        @apply cursor-not-allowed;
        opacity: 0.4;
        color: inherit;
    }

    &:hover:not(:disabled) {
        opacity: 1;
        @apply bg-grey-100;
    }

    &:active:not(:disabled) {
        @apply bg-grey-200;
    }

    &:focus-visible:not(:disabled) {
        @apply ring-1 ring-inset ring-orange-200;
    }
}

@supports not selector(:focus-visible) {
    .toolbar-btn:focus {
        @apply ring-1 ring-inset ring-orange-200;
    }
}
