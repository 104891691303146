
// # TODO: el-video
// # -----------------------------------------------------------------------------------

.video-player
  position: relative
  display: inline-block
  font-size: 0
  background-color: black
  width: 100%
  transition: all 0.2s
  margin-bottom: 1.9rem
  &:hover
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2)
  &:before
    content: ''
    display: inline-block
    width: 100%
    height: 100%
    position: absolute
    left: 0
    bottom: 0
    background: rgba(black, 0.7)

  .category
    color: #ffffff
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 700
    line-height: 18px
    text-transform: uppercase
    pointer-events: none
    margin-top: 10px

  &.play
    &:before
      opacity: 0
      visibility: hidden
    .center-btm-play, .name
      opacity: 0
      visibility: hidden

  .video-controls
    position: absolute
    bottom: 0
    left: 0
    background-color: rgba(#000000, 0.7)
    height: 60px
    width: 100%
    z-index: 1
    color: white
    box-sizing: border-box
    text-align: left
    @extend %clear-fix
    padding-right: 25px
    padding-top: 20px
    padding-bottom: 19px
    @media (max-width: $screen-xs-max)
      height: 40px
      padding-right: 20px
      padding-top: 10px
      padding-bottom: 10px

  .center-btm-play
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    display: inline-block
    width: 60px
    height: 60px
    background-color: rgba(#000000,0.7)
    border-radius: 100%
    line-height: 60px
    vertical-align: middle
    text-align: center
    transition: all 0.2s
    cursor: pointer
    z-index: 2
    &:hover
      cursor: pointer
      background-color: #30b1fc

    &:before
      display: inline-block
      width: 0
      height: 0
      content: ""
      border-style: solid
      border-width: 5px 0 5px 8px
      border-color: transparent transparent transparent white
      margin-right: -2px
      z-index: 1

  .video-scrubber, .video-progress
    cursor: pointer
    transition: all 0.1s

  .video-scrubber
    position: absolute
    bottom: 60px
    left: 0
    width: 100%
    cursor: pointer
    height: 4px
    background-color: rgba(#ffffff,0.5)
    @media (max-width: $screen-xs-max)
      height: 8px
    &:hover
      height: 8px
      .video-progress:after
        top: -5px
    @media (max-width: $screen-xs-max)
      bottom: 40px


  .video-progress
    width: 0
    height: 100%
    background-color: #30b1fc
    cursor: pointer



  .video-time
    display: inline-block
    color: #ffffff
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 400
    line-height: 18px
    text-transform: uppercase
    .video-current, .video-duration
      display: inline-block
      padding: 0 4px
      min-width: 34px

  .full-screen
    float: right
    padding: 5px
    width: 20px
    height: 20px
    box-sizing: border-box
    display: inline-block
    background: url(../../../svg/full.svg) no-repeat center/14px
    background-size: cover
    transform: translateY(-2px)
    cursor: pointer
    @media (max-width: $screen-xs-max)
      display: none !important

  .video-element
    max-width: 100%
    width: 100%
    max-height: calc(100vh - 25.4rem)
    position: relative
    z-index: 1

  .btn-mute
    transform: translateY(-3px)
    padding: 5px
    width: 20px
    height: 20px
    box-sizing: border-box
    cursor: pointer
    display: inline-block
    background: url(../../../svg/sound.svg) no-repeat center/16px
    background-size: cover

    &.muted
      background: url(../../../svg/volume-off.svg) no-repeat center/16px
      background-size: cover

  .play-pause
    transform: translateY(-3px)
    margin: 0 15px
    padding: 5px
    width: 20px
    cursor: pointer
    height: 20px
    box-sizing: border-box
    display: inline-block
    background: url(../../../svg/play-btn.svg) no-repeat center/24px
    background-size: cover

    &.pause
      background: url(../../../svg/pause-btn.svg) no-repeat center/12px
      background-size: cover

  .volume
    transform: translateY(-3px)
    position: relative
    display: inline-block
    margin: 0 15px
    width: 100px
    height: 2px
    cursor: pointer
    vertical-align: middle
    background-color: rgba(white, 0.5)
    @media (max-width: $screen-xs-max)
      width: 60px
    .volume-bar
      position: absolute
      z-index: 10
      top: 0
      left: 0
      width: 52%
      display: block
      height: 100%
      cursor: pointer
      background-color: #30b1fc
      &:after
        display: inline-block
        content: ''
        width: 16px
        height: 16px
        border-radius: 100%
        background-color: #ffffff
        cursor: pointer
        right: -7px
        top: -7px
        position: absolute
        z-index: 10
