@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
.pls-auth-container {
    --explainer-text: #B5AFAF;
    --btn-gradient-from: #FF702D;
    --pls-card-gray: #666666;
    height: 100%;
    .pls-sign-up-form {
        .ant-input, .ant-select-single  {
            min-height: 4.6rem;
        }
        .ant-input-password {
            padding: 0 1rem;
            min-height: 3.6rem;
        }
    
        .ant-form-item-explain-error {
            font-size: 1.2rem;
        }
        .ant-form-item {
            margin-bottom: 1.6rem;
        }
        .ant-form-item-label > label {
            font-size: 1.2rem;
        }
        .phone-number-input {
            border-radius: 0.6rem;
        }
        .react-tel-input .form-control {
            font-size: 1.6rem;
        }
        .flag-dropdown, .selected-flag  {
            border-radius: 0.6rem 0 0 0.6rem !important;
        }
        .ant-checkbox {
            .ant-checkbox-inner {
                background-color: transparent !important;
                border-color: black !important;
                &::after {
                    
                    box-sizing: border-box;
                    position: absolute;
                    top: 50%;
                    left: 21.5%;
                    display: table;
                    width: 0.5rem;
                    height: 0.9rem;
                    border: 2px solid black;
                    border-top: 0;
                    border-inline-start: 0;
                }
            }
        }
        .ant-form-item-explain-connected {
            position: absolute;
        }
    
        @media (max-width: 750px) {
            .ant-select-single,
            .ant-select-selector {
                min-height: 4.6rem;
            }
            .react-tel-input .form-control {
                font-size: 1.4rem;
            }
            .ant-form-item-explain-connected {
                position: unset;
            }
            .ant-select-arrow {
                left: auto;
                right: 1.2rem;
            }
        }
    }
}
