// settings page
//-----------------------------------------------------------------------------------

.settings-container
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    @media (max-width: 1354px)
        flex-direction: column
p.color-row-text
  margin-bottom: 0 !important

.settings-wrapper
  display: grid
  grid-template-columns: 1fr 1fr
  grid-column-gap: 50px

.social-article-link
    color: $taporange
    font-weight: 600

.settings-submit-btn
  grid-column: 1/-1
  padding: 1rem
  transition: all 0.3s
  border-radius: 0.4rem
  font-family: $InterFace
  font-size: 1.6rem
  cursor: pointer
  background-color: $tapgrey
  color: #fff
  border: 1px $tapgrey solid
  display: inline-block
  width: fit-content
  margin-top: 2rem

  &:hover
    border-color: $tapgrey
    background-color: #fff
    color: $tapgrey
    text-decoration: none

  &--disabled
    opacity: 0.3

    &:hover
      border: 1px $tapgrey solid
      color: #fff
      background-color: $tapgrey
      opacity: 0.3

.settings-title
  font-family: $InterFaceBold
  font-size: 2.1rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-bottom: 5rem
  span
      margin-left: 1rem
      color: #29d266
      font-family: $InterFaceBold
      font-size: 1.6rem
.settings-intro span
  font-size: 1.6rem
  line-height: 1.5
  max-width: 80%
  margin-bottom: 1.6rem
  &.toggle-info
    max-width: 100%
    span
      font-family: $InterFaceBold

.settings-intro a, .orange-link
  color: #ff5100
  text-decoration: none
.tutorial-intro p
  font-size: 1.6rem
  line-height: 1.5
  max-width: 80%
  margin-bottom: 1.6rem
.tutorial-intro a, .orange-link
  color: #ff5100
  text-decoration: none

.experience-based-questions-container
  display: flex
  flex-direction: column
  margin-top: 5rem
  span
    font-size: 1.6rem

hr.settings-hr
  border: none
  border-top: 1px solid #dbdbdb
  margin: 3rem 0
  max-width: 90%

.settings-content-link
    color: $taporange
.settings-grid
  border-top: 1px solid #dbdbdb
  padding-top: 3rem
  display: flex
  margin: 3rem 0
  &.has-tutorial
    border: none
    padding: 0
    margin: 0
  &.tap-page-widget
    border-top: none
  &.start-guides
    border-top: none
    padding-top: 0
    margin: 0
  &.no-submenu
      margin-left: 6.5rem
      width: calc(100vw - 6.6rem)
  &>.column
    flex: 1
    &>p
      width: 100%
      max-width: 100%
  &>.column.spacer
    max-width: calc(100% / 9)
  &>.column.resources
    max-width: calc(100% / 3)
    &>.resources-box
      background-color: #efefef
      padding: 3rem
      &>h3
        font-size: 1.8rem
        margin-bottom: 2rem
        font-family: $InterFaceBold
        opacity: 1
      &>ul
        opacity: .87
        &>li
          list-style-type: none
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii01IC03IDI0IDI0IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIiBjbGFzcz0iamFtIGphbS1jaGVjayI+PHBhdGggZD0nTTUuNDg2IDkuNzNhLjk5Ny45OTcgMCAwIDEtLjcwNy0uMjkyTC41MzcgNS4xOTVBMSAxIDAgMSAxIDEuOTUgMy43OGwzLjUzNSAzLjUzNUwxMS44NS45NTJhMSAxIDAgMCAxIDEuNDE1IDEuNDE0TDYuMTkzIDkuNDM4YS45OTcuOTk3IDAgMCAxLS43MDcuMjkyeicgLz48L3N2Zz4=) no-repeat left top
          background-position-x: left
          background-position-y: top
          padding: 0 0 3px 24px
          background-position: left -2px
          margin-bottom: .9rem
.settings-item
  position: relative
  margin-bottom: 2.5rem
  &>p
    max-width: 80%
  &>a
    color: #ff5100
  &:last-child
    margin-bottom: 0
  .radios
    line-height: 3rem
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    width: 100%
    padding: 1rem
    margin-bottom: 1rem
    label
      display: block
      text-align: left
      vertical-align: middle
      input
        margin-right: 5px
        margin-bottom: 5px
.settings-item.tap-page-embed > .settings-url
    width: 80%
.hosted-tap-feed
    .edit-url-link
      color: #222222
      opacity: 1
      text-decoration: underline
      &:hover
        opacity: 0.5

.settings-name
    font-family: $InterFaceBold
    font-size: 1.6rem !important
    margin-top: 1rem
    margin-bottom: .3rem
    &.mod-optioanl
        margin-bottom: 0
    span
      opacity: .66
      font-family: $InterFace
      font-weight: normal
      margin-left: .6rem
.settings-name-safeguard
    font-family: $InterFace
    font-size: 1.6rem
    margin-top: 1rem
    margin-bottom: .3rem
    max-width: 80%

.social-slide
    margin-top: 13px
.join-users-proof-text
    line-height: 1.5
    max-width: 100% !important
    svg
        overflow: visible
        transform: translateY(2.5px)
        margin: 0 1px
.social-proof-description
    font-size: 1.6rem
    line-height: 1.5
    letter-spacing: normal
    margin: 19px 0 22px
    max-width: 100% !important
.preview-social-title
    font-size: 1.6rem
    font-weight: 600
    line-height: 1.25
    letter-spacing: normal
    margin-bottom: 10px
.social-proof-amount
  color: #ff5100
  font-weight: 600
  svg > path
    fill:  #ff5100
    stroke: #ff5100

ol.ordered-list
  list-style-type: decimal
  list-style-position: inside
  li
    margin: 5px 0
    a
      font-weight: 600
      color: #ff5100

a.orange
  font-weight: 600
  color: #ff5100

.table
    border-collapse: collapse
    width: 50rem
    max-width: 100%

.ambassadors-table
  .table-head-item
    font-family: $InterFaceBold
  .table-head-item, .table-item
    &:nth-child(3), &:nth-child(6)
        display: none

.table-container
    &.mod-users-table
        overflow: visible
        margin: 2rem 0 5rem 0
        .table-head-item
            width: auto
        .table-item
            padding-right: 1rem
            padding-top: 1rem
        .table
            width: 100%
            min-width: 56rem
            .table-item:first-child
                min-width: 13rem
                border-left: 2rem solid transparent
    &.mod-prospect-table
        .table
            tr:nth-child(1)
                .table-item
                    padding-top: 20px
            .table-head-item
                border-bottom: 1px solid rgba(0,0,0,.1)
            .table-item
                padding-top: 10px
                padding-bottom: 10px
                &:first-child
                    min-width: 130px
                    max-width: 160px
                    font-size: 1.6rem
                &:nth-child(2)
                    min-width: 120px
.table-head-item
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    &.disabled
      opacity: 0.4
      pointer-events: none
    color: $dark
    width: 23%
    padding-bottom: 1rem
    box-sizing: border-box
    &:first-of-type
        width: 31%
.table-item
    font-style: normal
    font-stretch: normal
    line-height: normal

    padding-bottom: 1rem
    transition: all 0.2s
    vertical-align: middle
    position: relative
    &.disabled
      opacity: 0.4
      pointer-events: none
    &:nth-of-type(3)
        font-size: 1.6rem
        span
            font-size: 1.6rem
.settings-right
    width: 21rem
    margin-left: 20rem
    @media (max-width: 1354px)
        margin-left: 0
        margin-top: 2rem
.settings-row
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 0.4rem
    &.mod-space
        margin-bottom: 1.9rem
    &.account
      max-width: 50%
      & .user-img, .edit-user-label
        width: 6rem
        height: 6rem
.settings-input
    max-width: 50%
.privacy-input
    text-indent: 0 !important
    display: inline-block
    width: 25rem

.companies-form-container
  margin-bottom: 1.6rem
  p
    font-size: 1.6rem
    line-height: 1.5
    max-width: 80%
    margin-bottom: auto
.branding-input
    font-family: $monospace
    font-size: 16px
.privacy-form
    margin-top: 10px
.privacy-input
    height: auto
    padding: 0
    border: none
    margin: 0
    input
        height: 42px
.privacy-save
    display: flex
    align-items: center
    background-color: inherit !important
    border: none
    box-shadow: unset
    margin-left: 7px
    height: 19px
    top: -1px
.add-company-name
  margin-left: 1rem
.settings-row-name
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    color: $dark
.settings-btn
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal

    color: $taporange
    &:hover
        text-decoration: none
        border-color: #ffffff
    &:focus
        border-color: #ffffff

.settings-url
    display: block
    margin-top: 1rem
    background: #efefef
    border: 1px solid #dbdbdb
    border-radius: 3px
    padding: 1rem
    font-family: $monospace
    font-size: 1.4rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -.3px
    color: $tapgrey
    word-wrap: break-word
    &:hover
        text-decoration: none
.settings-status
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    opacity: .7
    margin-bottom: 1.9rem
    &.mod-green
        color: #23c619
    &.mod-space
        margin-bottom: 7.3rem
        @media (max-width: 1354px)
            margin-bottom: 3rem
.signout-btn
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal
    padding: 0

    color: $dark
    &:hover
        text-decoration: none
        color: $tapred
.table-container
    max-width: 100%
    overflow: auto
    width: 100%
    &.mod-сontent-requests
        .table-head-item
            width: auto
        .table-item
            padding-right: 10px
        .table
            width: 100%
            min-width: 560px
            .table-item:first-child
                min-width: 130px
                padding-left: 1rem
            tbody tr:hover .table-item
                color: $taporange
.settings-left
    max-width: 100%
.signin-input
    &.mod-hidden
        text-indent: 0
        margin-bottom: 0.5rem
.hidden-block
    padding-bottom: 2rem
    display: none
    .settings-btn
        margin-top: 1rem
        margin-right: 1rem
        &.mod-cancel
            color: $dark
            opacity: .3
            border-bottom: none

.rss-link, .university-access-code
    padding: 0
    margin: 0
    border: 0
    position: absolute
    z-index: -1
.table-item-link
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    &:hover
      text-decoration: none
      opacity: 1
    .mod-new::after
      content: 'New!'
      font-family: $InterFaceBold
      font-size: 1.4rem
      color: #29d266
      margin-left: 1rem
.table-item-first
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

.signout-btn
    margin-top: 30px
    display: inline-block
.table-item, .table-item:nth-of-type(3)
    min-width: 100px

.table-item.user-status-row
    min-width: 30px
.sharing-item
    width: 33rem
.university-input
    display: block
    background: #efefef
    border: 1px solid #dbdbdb
    border-radius: 3px
    padding: 0 1rem
    margin-right: 1rem
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: 4rem
    letter-spacing: -.3px
    color: $tapgrey
.university-submit
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 4rem
    letter-spacing: -0.2px
    color: $taporange
    &.button-disabled
      visibility: hidden
      &:hover
        cursor: none
        pointer-events: none
    &.button-disabled::before
      visibility: visible
      content: 'Type to change'
      &:hover
        cursor: default
.university-form
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
.companies_form
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    position: relative
    max-width: 80%
    &>div
      width: 85%
      position: relative
    .error
      color: #fe3057
      position: absolute
      right: 0
      top: 31px
      font-size: 14px
      top: 4.2rem
.settings-code-input
    font-family: $monospace
    width: 4rem
    height: 4rem
    border-radius: 3px
    border: 1px solid #dbdbdb
    background-color: #efefef
    text-align: center
    font-size: 2.4rem
    color: $tapgrey
    margin-right: .9rem
    &:last-of-type
     margin-right: 3rem
.settings-code-block
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
.training-centre-code
    font-family: $monospace
    margin-top: 1rem
    padding: 1rem
    border-radius: 3px
    border: 1px solid #dbdbdb
    background-color: #efefef
    text-align: left
    font-size: 1.6rem
    color: $tapgrey
    margin-right: .9rem
.university-logo-preview
    width: 9rem
    height: 9rem
    background-color: #002147
    border-radius: 50%
    position: relative
    overflow: hidden
    margin-right: 0.7rem
.university-logo-img
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    max-width: 100%
    max-height: 100%
    margin: auto
    height: 10rem
.university-logo-block
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin-bottom: 3rem
    margin-top: 1rem
.university-logo-input
    position: relative
.university-logo-text
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 2.7

    color: $taporange
    &:hover
        cursor: pointer
        text-decoration: underline
.university-logo-input
    position: absolute
    left: 0
    top: 0
    visibility: hidden

.shared-posts-container
    margin-top: 2rem
.style-form
  margin-top: 1rem
.style-page-name
    font-family: $InterFace
    font-size: 2rem
    font-weight: 500
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.06rem
    color: $dark
    margin-bottom: 1rem
.style-page-text
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 300
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.2px
    color: $dark
    margin-bottom: 2.4rem
.color-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin-bottom: 2.2rem
    .select.language .ui.dropdown > .text
        font-family: $InterFace
        font-size: 1.6rem
        font-weight: normal
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: -0.2px
        color: $dark
    .select.language .ui.selection.dropdown
        padding-left: 0
        height: 2.3rem
        min-width: 8rem
    .select.language .ui.selection.visible.dropdown > .text:not(.default), .select.language .ui.dropdown > .text
        color: $dark
        line-height: 2.3rem
    .select.language .ui.selection.dropdown > .dropdown.icon
        color: $dark
    .select.language .ui.selection.dropdown > .dropdown.icon
        height: 2.3rem
        line-height: 2.3rem
    .select.language .ui.selection.dropdown .menu > .item
        padding-left: 1rem !important
.color-row-text
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.2px
    color: $dark
    width: 137px
.color-input-label
    position: relative
    input[type="color"]
        position: absolute
        left: 0
        top: 0
        opacity: 0
.color-info-block
    font-family: $monospace
    font-size: 1.8rem
    padding: .9rem 1.2rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 2.1rem
    letter-spacing: -0.2px
    text-align: center
    color: $dark
    border-radius: 0.2rem
    border: solid 0.1rem #000
    box-sizing: border-box
.color-textarea
    resize: none
    width: 35.8rem
    height: 19.6rem
    border-radius: 0.2rem
    border: solid 0.1rem #e3e3e3
    background-color: #fafafa
    max-width: 100%
    box-sizing: border-box
    padding: 1rem
    font-family: $InterFace
    font-size: 1.2rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.2px
    color: $dark
    margin-bottom: 2.7rem
.style-preview-name
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 500
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.2px
    color: $dark
    margin-bottom: 1rem
.ambassador-information
  display: flex
  align-items: center
.ambassador-no-activity
  td
    opacity: .45
    &.full-visible
      opacity: 1
.table-item-img-container
    width: 4rem
    height: 4rem
    position: relative
    border-radius: 50%
    overflow: hidden
    margin-right: 1rem
    min-width: 4rem
.table-item-img
    object-fit: cover
    height: 100%
    width: 100%
.table-item-img-name
    width: 100%
    height: 100%
    background: grey
    line-height: 4rem
    text-align: center
    color: #fff
    text-transform: uppercase

    &--big
      font-size: 8rem
      line-height: unset

.view-profile-info-btn
    font-family: $InterFace
    font-size: 1.2rem
    color: $dark
    background-color: #d9d9d9
    padding: 0.2rem 1.5rem
    border-radius: 0.5rem
.optional-subtext
    font-size: 1.2rem
    font-family: $InterFace
    color: $dark
    margin-bottom: 3rem

.optional-form
    font-size: 1.6rem !important
    .settings-name
        font-size: 1.6rem !important
        color: $taporange
    .upload-file-block
         a
             color: #222222
.optional-form-row
    display: block
    width: 36rem
    max-width: 100%
    margin-bottom: 1.5rem
    &.disabled
        opacity: 0.5
        input,
        .optional-date-label,
        .remove-answer
            pointer-events: none
            cursor: default
.optional-form-question
    font-size: 1.6rem
    font-family: $InterFace
    color: $dark
.optional-form-row-head
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    position: relative
    margin-bottom: 1rem
    box-sizing: border-box
.optional-input-container
  position: relative
.optional-input-checkbox
  position: absolute
  top: 10px
  right: -170px
  .ant-checkbox
    transform: scale(1.25)
.optional-input-checkbox-label
  font-size: 1.6rem
.optional-input
    width: 100%
    background-color: #efefef
    border: 1px solid #dbdbdb
    border-radius: 3px
    box-sizing: border-box
    line-height: 4rem
    text-indent: 1.5rem
    font-family: $InterFace
    color: $dark
    font-size: 1.6rem
.optional-custom-text-head
  margin-top: 1rem
  font-family: $InterFace
  color: $dark
  font-size: 1.5rem
  margin-bottom: 1.3rem
.optional-custom-text-bottom
  margin-top: 2.1rem
  font-family: $InterFace
  color: $dark
  font-size: 1.5rem
  margin-bottom: 0.5rem
  a
    color: #2a87d0
  .person-info-link
    text-decoration: underline
.optional-precaution-text
    margin-left: 1.6rem
.optional-date-label
    display: flex
    margin-bottom: 2rem
    flex: 0 0 50%
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    position: relative
    z-index: 50
.optional-date-input, .optional-custom-input
    width: 90%
    background-color: #fafafa
    border: 1px solid #e3e3e3
    box-sizing: border-box
    height: 4rem
    text-indent: 1rem
    font-family: $InterFace
    color: $dark
    font-size: 1.6rem
.optional-date-label-text
    font-family: $InterFace
    color: $dark
    font-size: 1.6rem
    opacity: .66
    margin-bottom: 0.5rem
    display: block
.add-optional-dates-btn
    margin-top: 2.1rem
    font-family: $InterFace
    color: $taporange
    font-size: 1.6rem
    margin-bottom: 0.5rem
.upload-optional-dates-btn
    margin-top: 2.1rem
    margin-left: 2.1rem
    font-family: $InterFace
    color: $taporange
    font-size: 1.6rem
    margin-bottom: 0.5rem
.optional-custom-row
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    margin-bottom: 2rem
.optional-custom-input
    width: 100%
.optional-custom-label
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    position: relative
    z-index: 50
    width: 100%
    margin-top: 1.5rem
.remove-custom-answer, .remove-custom-question
    position: absolute
    display: flex
    align-items: center
    top: 35px
    left: calc(100% + 12px)
    width: max-content
    cursor: pointer
    svg
        fill: $taporange
        height: 17px !important
    span
        color: $taporange
        margin-left: 10px
.remove-custom-question
    top: 10px !important
    left: calc(100% + 18px) !important
.upload-file-block
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    position: relative
    margin-bottom: 1.5rem
    margin-top: 1rem
.upload-file-label
    position: relative
    display: flex
    overflow: hidden
    cursor: pointer
    align-items: center
    input
        cursor: pointer!important
        max-width: 100%
.upload-file-input
    position: absolute
    left: 0
    top: 0
    opacity: 0
.upload-file-btn
    display: block
    color: $taporange
    text-align: center
    cursor: pointer
    margin-right: 2rem
.optional-courses-list
  margin: 20px 24.7px 20px 0
  overflow: auto
  max-height: 260px

  &::-webkit-scrollbar
    width: 8px

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    background-color: rgba(#222222, 0.2)

  &::-webkit-scrollbar-track
    border-radius: 10px
    background-color: unset

.optional-course-text
    position: relative
    font-family: $InterFace
    display: flex
    align-items: center
    height: 36px
    color: #222222
    padding: 0 30px
    &:not(:last-child):after
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      content: ''
      width: calc(100% - 60px)
      height: 1px
      background-color: rgba(#222222, 0.1)

    &:hover
      background-color: rgba(#222222, 0.05)
      color: #222222

.optional-title-block
    display: flex
.optional-icon-info
    display: flex
    align-items: center
    svg
        cursor: pointer
        margin-left: 10px
        margin-top: 6px
        width: 16px
        height: 16px
        fill: #a7a7a7
.optional-help-text
    padding: 10px 16px
    line-height: 1.29


.optional-save
    display: block
    width: 10rem
    height: 2.5rem
    line-height: 2.5rem
    text-align: center
    font-family: $InterFace
    color: $dark
    font-size: 1.2rem
    border-radius: 0.5rem
    background-color: #d9d9d9
    cursor: pointer
    &:hover
        background: #82efa9

.specializations-load
    position: absolute
    top: 2rem
    left: 2rem
    .load__icon
        width: 20px
        height: 20px

input[disabled]
    background: #ececec

.error-text
    color: red
    font-size: 1.6rem
    text-align: right
    margin-left: auto

.remove-answer
    position: absolute
    top: 0.3rem
    right: 1.8rem
    z-index: 3
    //background: url('../../../svg/icons/close.svg') no-repeat center
    width: 10px
    height: 10px
    background-size: 100%
.legal-text
  font-family: $monospace
  max-width: 90%
  line-height: 1.66
  background-color: #efefef
  padding: 2rem
  border-radius: 3px
  border: 1px solid #dbdbdb
  margin-top: 2rem
  & .insert-item
    background-color: $color-selection
    padding: .3rem

ul.invite-list
  list-style: disc
  list-style-position: outside
  & a
    color: $taporange
  & li
    margin-left: 2.7rem
    & .invite-code
      color: $taporange

iframe.tap-page-frame
  width: 100%
  min-height: 300px

.copy-iframe-code
    margin-bottom: 0.3rem
    margin-left: 1rem

.table-item-rate
    font-size: 2.4rem
    text-align: center
    padding-right: 0!important

    &-placeholder
        filter: grayscale(1)

.save-overlay
    position: fixed
    top: 0
    left: 0
    z-index: 110
    width: 100%
    height: 100%
    display: none
    background-color: rgba(#000, 0.7)
    padding: 1.5rem
.save-popup-block
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    box-sizing: border-box
    width: 100%
    height: calc(100vh - 3rem)
.save-popup
    background-color: #fff
    max-width: 100%
    padding: 4rem
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
.save-popup-text
    font-family: $InterFace
    letter-spacing: -0.02rem
    font-size: 1.8rem
    font-weight: 700
    text-align: center
.save-popup-btn
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 4rem
    letter-spacing: -0.2px
    color: #ff5100
    margin: 0 5px
.popup-btn-row
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin: 0 -5px

textarea
    resize: none

#chat-message-textarea, #content-messaging-textarea
    padding: 1rem

.sa-detail-title
    font-size: 1.6rem

.column-prospects-grid
  flex: 1 1

.selector-wrapper
  padding: 50px 40px

.selectors
  font-family: $InterFace
  font-size: 2.1rem
  padding-bottom: 6px
  margin-bottom: 50px
  border-bottom: 2px solid rgba(38, 46, 69, 0.15)

  &__selector
    cursor: pointer
    padding: 0 20px 7px
    border-bottom: 2px solid transparent
    transition: all 0.3s

    &--active
      font-family: $InterFaceBold
      color: $taporange
      border-bottom: 2px solid $taporange

.filters-wrapper
  display: grid
  grid-template-columns: 1fr 1fr
  grid-column-gap: 100px

.filter
  margin-bottom: 40px

  &--third
    grid-column: 1/-1

  &__header
    display: flex
    font-size: 1.6rem

    &__title
      color: $taporange

    &__counter
      color: rgba($tapgrey, 0.5)
      margin-left: 4px

  &__body
    margin-left: 20px

    &__option-wrapper
      display: flex

    &__option-title
      font-family: $InterFace
      margin-right: 15px
      width: max-content

    &__option
      margin-right: 10px
      margin-bottom: 2px

    &__option-info
      color: rgba($tapgrey, 0.5)
      font-size: 1.4rem
      margin-top: 2px

    &__terms-wrapper
      display: flex
      flex-wrap: wrap
      width: 100%

    &__terms
      display: flex
      align-items: center
      margin-bottom: 2.5rem
      border: .1rem solid #e7e7e7

      &:focus
        border-color: #becdd5

      &__term
        font-family: $InterFace
        display: flex
        font-size: 2rem
        align-items: center
        border-radius: 18px
        padding: 0 15px
        margin: 10px 5px
        cursor: pointer
        background-color: $tapgrey
        color: white
        opacity: 0.6
        width: fit-content

      &__input
        flex-grow: 1
        padding: 5px 10px
        box-sizing: border-box
        color: $tapgrey
        border: 0
        margin: 10px 0
        font-size: 1.8rem
        outline: 0

        &::placeholder
          color: rgba($tapgrey, 0.5)

  &__add-btn
    font-family: $InterFace
    color: $taporange
    display: flex
    align-items: center
    font-size: 1.6rem
    margin-left: 20px

    &--disabled
      color: $tapgrey
      opacity: 0.5

    &__icon
      transform: rotate(45deg)
      fill: $taporange
      width: 14px
      height: 14px
      margin-right: 10px

      &--disabled
        fill: $tapgrey
        opacity: 0.5

    &--initial
      width: fit-content
      margin-bottom: 25px

.icon-close

  &--settings
    margin-left: auto

  &--term
    width: 10px
    opacity: 0.8
    margin-left: 7px
    margin-top: 2px

.tooltip-block
  position: relative
  padding: 5px
  width: fit-content
  display: block

  &__description
    font-family: $InterFace
    visibility: hidden
    position: absolute
    padding: 6px 10px
    background-color: #efefef
    border-radius: 2px
    bottom: 100%
    width: max-content
    margin-bottom: 8px
    z-index: 2

    &--visible
      visibility: visible
      transition: visibility 0s 0.5s

    &--left
      left: 0

    &--right
      right: 0

  &__triangle
    visibility: hidden
    position: absolute
    margin-bottom: 6px
    left: 50%
    top: 0
    background-color: #efefef
    width: 18px
    height: 18px
    transform: rotate(45deg) translate(-14px, -14px)

    &--visible
      transition: visibility 0s 0.5s
      visibility: visible

.dropdown-tags-filter
  top: -20px !important

.safeguard-page
  .head-title
    margin-bottom: 1rem
    color: #ff1b37
    font-weight: 600
  .text-warning
    color: #ff1b37
    font-family: $InterFace
    font-size: 1.5rem
  .text-top
    display: flex
    flex-direction: column
    line-height: 2rem
    .safeguard-link
      margin-bottom: 1rem
      color: #0000EE
      font-family: $InterFace
      font-size: 1.5rem
  .centered-line
    border-bottom: 1px #555555 solid
    margin: 1.3rem 0
  .text-bottom
    display: flex
    flex-direction: row
    line-height: 2rem
    align-items: center
    .safeguarding-explainer
      max-width: 80%
      .toggle-wrapper
        max-width: 40%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        margin-bottom: 20px
        p
          font-size: 16px
          color: #ff5100
          font-weight: 600
      .settings-section-title
        max-width: 70%
        margin-bottom: 20px
        margin-top: 50px
        p
          font-size: 16px
          color: #ff5100
          font-weight: 600
    .text-warning
      margin-left: 0
    .slider-button
      display: block
      margin-right: 0
      padding-left: 1.5rem

.description-container
    width: 50vw

.notification-control-form
    display: block
    width: 100%


.notification-control-form-row
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 1rem

.notification-name
    font-size: 1.6rem
    span
      opacity: .66
      font-family: $InterFace
      font-weight: normal
      margin-left: .6rem
      display: flex

.toggle-block-name
    font-family: $InterFaceBold
    font-size: 1.6rem
    color: #ff5100
    &.header
      margin-bottom: 20px

.notifications-toggle-block
    margin-bottom: 50px
    width: 60vh

.export-data-page
    .text-bottom
        margin-bottom: 25px
    .export-list
        margin: 5px 0 14px
        p
            padding: 3px
.export-data-modal
    .export-success-popup
        padding-bottom: 2rem
    .modal-body
        font-size: 1.6rem

.auto-deletion-page
  .auto-deletion-container
      width: 60%
  .text-bottom
    width: 90%
    .el-btn
      padding: 7px 35px 7px 35px

.data-management-select
  font-size: 100%
  .ant-select-selector
    border: none !important
    box-shadow: none !important
    color: #ff5100
    background-color: transparent !important
    font-size: 100%
  .ant-select-arrow
    color: #ff5100
  &.ant-select-single.ant-select-open
    .ant-select-selection-item
      color: #ff5100

.data-management-dropdown
  padding: 20px
  text-align: center
  .data-management-dropdown-header
    font-weight: 500
    margin-bottom: 10px
    font-size: 100%
  .data-management-dropdown-option
    border-bottom: 1px solid rgba(223,223,223,0.3)
    margin-left: 20px
    margin-right: 20px
    .ant-select-item-option-content
      color: rgba(38, 46, 69, 0.8)
      font-size: 100%
      &:hover
        color: #ff5100
  .ant-select-item-option-active:not(.ant-select-item-option-disabled)
    background-color: transparent
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    background-color: transparent
    .ant-select-item-option-content
      color: #ff5100

.keywords-control-container
  margin-top: 20px
  display: flex
  flex-direction: row

.keyword-list-container
  margin-right: 26px
  width: 287px
  .data-control-description
    font-size: 14px
    color: rgba(34, 34, 34, 0.7)
    margin-top: 8px
    margin-bottom: 16px

.keyword-list-action-btn
  width: 50%

.default-keywords-items-container
  overflow: scroll
  height: 300px

.keywords-items-container
  margin-top: 20px
  .first-item
    border-bottom: 1px solid #dadada
    padding-left: 44px
    padding-top: 8.5px
    padding-bottom: 7.5px
    font-weight: 600
    font-family: $InterFaceBold
    margin-right: 33px
    display: flex
    align-items: center
    &.custom
      padding-left: 20px
      margin-right: 0
  .ant-checkbox-wrapper
    font-size: 16px
  .items-group
    display: flex
    flex-direction: column
    height: 220px
    overflow: scroll
    &.save-btn-displayed
      height: 180px
    .ant-checkbox-group-item
      border-bottom: 1px solid #dadada
      padding-left: 44px
      padding-top: 8.5px
      padding-bottom: 7.5px
      margin-right: 33px
      &:last-child
        border: none

.custom-items-group
  display: flex
  flex-direction: column
  height: 220px
  overflow: scroll
  &.data-control
    height: 110px
  .group-item-container
    position: relative
    .group-item
      padding-left: 20px
      border-bottom: 1px solid #dadada
      padding-top: 8.5px
      padding-bottom: 7.5px
      padding-right: 27px
    &:last-child
      &>.group-item
        border: none
    .close-btn
      position: absolute
      right: 17px
      top: 9px

.keywords-list-wrapper
  display: block
  width: 287px
  height: 341px
  border-radius: 5px
  background: white
  padding-left: 10px
  padding-right: 10px
  padding-top: 23px
  border: 1px solid #dadada
  position: relative
  .keywords-item
    border-bottom: 1px solid #dadada
    padding-left: 44px
    padding-top: 8.5px
    padding-bottom: 7.5px
  .ant-empty-normal
    margin-top: 100px
  .save-btn-container
    width: 100%
    position: absolute
    left: 0
    bottom: 0
    height: 43px
    border-bottom-right-radius: 5px
    border-bottom-left-radius: 5px
    border-top: 1px solid #dadada
    display: flex
    justify-content: center
    .save-btn
      color: #ff5100
      align-self: center


.keyword-input-icon
  display: flex
  margin-right: 12.3px

.add-keyword-btn
  color: #ff5100
  font-weight: 600
  font-family: $InterFaceBold

.data-list-container
  margin-bottom: 50px
  margin-top: 20px
  .data-list-wrapper
    display: block
    width: 601px
    max-height: 250px
    border-radius: 5px
    background: white
    padding-left: 10px
    padding-right: 23px
    padding-top: 23px
    border: 1px solid #dadada
    position: relative
    &.one-item
      max-height: 165px
    &.two-items
      max-height: 205px
    .ant-empty-normal
      margin-top: 60px
    .save-btn-container
      width: 100%
      position: absolute
      left: 0
      bottom: 0
      height: 43px
      border-bottom-right-radius: 5px
      border-bottom-left-radius: 5px
      border-top: 1px solid #dadada
      display: flex
      justify-content: center
      .save-btn
        color: #ff5100
        align-self: center

.delete-selected-container
    width: 100%
    position: absolute
    left: 0
    bottom: 0
    height: 40px
    border-bottom-right-radius: 5px
    border-bottom-left-radius: 5px
    border-top: 1px solid #dadada
    display: flex
    justify-content: center
    .delete-selected-btn
      color: #ff5100
      align-self: center

.safeguarding-modal
  .ant-modal-content
    width: 400px
    height: 202px

.block-prospect-popup
  .ant-modal-content
    border-radius: 0.5rem

.search-container
  position: relative
  .ant-input-search
    border-radius: 5px
  .error-message
    position: absolute
    right: 0
    top: 31px
    font-size: 14px
    color: #9d0021
.safeguarding-popup
  box-sizing: border-box
  padding: 4rem 3rem
  text-align: center
  position: relative
  .safeguarding-modal-title
    font-size: 16px
    span
      color: #ff5100
  .confirm-ban-buttons
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: 3rem
    button
      width: 190px
      margin: 0
  .close-btn
    position: absolute
    top: 10px
    right: 10px
    margin: 0
    padding: 0
    svg
      width: 15px
      height: 15px
      path
        fill: rgba(34, 34, 34, 0.7)

.upload-company-button
  margin-top: 2rem
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  cursor: pointer

.upload-company-icon
  margin-right: 5px

.upload-company-text
  color: #ff5100

.remove-company-icon
  position: absolute
  right: 1rem
  bottom: 0
  top: 0
  margin: auto
  width: 3rem
  z-index: 100

.company-input-text
  color: #ff5100
  padding: 1rem 0 1rem 0

.company-name-input
  text-indent: 0 !important
  display: inline-block
  width: 100%

.companies-names-container
  max-width: 70%
  max-height: 30rem
  padding-left: 1rem
  margin-top: 1rem
  overflow: scroll
  .company-item-container
    position: relative
  .company-name-input
    text-indent: 0 !important
    display: inline-block
    width: 100%
    margin-top: 1rem
  .company-name-container
    position: relative
    margin-right: 3%
    .company-name-input-actions
      display: flex
      justify-content: flex-end
    &>.error
      font-size: 14px
      color: #fe3057
      position: absolute
      left: 0
      bottom: 0
  .company-item
    padding: 1rem 0 1rem 0
    margin-right: 1.5rem
    border-bottom: 1px solid #ebebeb
    position: relative
    &:hover
      background-color: #efefef
      cursor: pointer

.invite-admin-container
  .tutorial-intro p
    margin-bottom: auto
  .invite-admin-header
    display: flex
    flex-direction: row
    justify-content: space-between
  .settings-grid
    width: 60vw
  .admins-table
    .ant-table
      border-radius: 5px
      box-shadow: 0 0 3px 0 rgba(34, 34, 34, 0.09)
      border: solid 1px #f2f2f2
    .ant-table-content
      padding: 2.6rem 3.2rem 2.6rem 3.2rem
    tr.table-row:nth-child(odd)
      background: #fff

    tr.table-row:nth-child(even)
      background: #fff

    thead[class*="ant-table-thead"] th
      vertical-align: middle
      text-align: left
      padding-bottom: 0
      &:first-child
        text-align: left

    tbody[class*="ant-table-tbody"] td
      text-align: left
      &:first-child
        text-align: left
    .ant-table-tbody > tr > td
      border-bottom: none
    .ant-pagination-item-active
      background: transparent

.invite-admin-popup, .create-live-event, .edit-live-event
  max-width: 50rem
  padding: 3rem 4rem
  .send-invitation-btn-container
    display: flex
    justify-content: center
  .modal-title
    font-family: $InterFaceBold
    font-size: 2.1rem
    font-weight: 600
    text-align: center
    color: $tapgrey
    margin-bottom: 1.6rem
    @media (max-width: 767px)
        margin-bottom: 25px
        font-size: 20px
  .modal-explainer
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    color: rgba(34, 34, 34, 0.8)
    font-size: 1.6rem
    margin-bottom: 2.6rem
  .table-row
    .table-row-header
      display: flex
      align-items: center
      .icon-close
        width: calc(348.333/348.334)*1em
        opacity: 0.5
        transition: all .2s
        margin-bottom: 1rem
        &:hover
            transform: scale(1.2)
    h3
      font-size: 1.6rem
      font-family: $InterFaceBold
      text-decoration: underline
      margin-bottom: 1rem
    .signin-label
      .error
        right: 0
  .add-admin-btn-container
    text-align: center
    button
      margin-bottom: 4.2rem
      margin-top: 1.6rem

.block-prospect-popup-container
  padding: 2rem 2.6rem
  p
    font-size: 1.6rem
  .modal-name
    font-size: 2.1rem
    line-height: 1.69
    text-align: center
    margin-bottom: 3.2rem
  .confirm-ban-buttons
    display: flex
    justify-content: center
    .general-action-button
        min-width: 13rem
  .repotrt-field-block
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    margin: 2.6rem 0 4.2rem
    .repotrt-field
      height: 10rem !important
      width: 100%
      padding: 8px 8px 25px
      resize: none
      font-family: InterFaceInteraction,Arial,Helvetica Neue,Helvetica,sans-serif
      font-size: 1.6rem
      font-weight: 400
      font-style: normal
      font-stretch: normal
      line-height: normal
      letter-spacing: -.3px
      color: #222
      text-indent: 0.5rem
      border-radius: 3px
      border-color: rgb(211,211,211)
    .report-error
        position: absolute
        bottom: -2.5rem
        right: 0
        color: #FF0000
.text-size-flagged
  color: rgba(34, 34, 34, 0.7)
  position: absolute
  bottom: 0
  right: 10px

.api-key-block-container
    padding-top: 20px

.react-tel-input
  .phone-number-input
    width: 100%
    border-radius: 2px
    line-height: unset
    height: auto
    &:hover, &:focus
      border-color: #ff7429
      border-right-width: 1px !important
  .country-select-button
    margin: 1px
    border: none
    border-right: 1px solid #d9d9d9
  .country-select-dropdown > li > .fr
    float : unset

.copied-link
  position: relative
  .ant-typography-copy
    position: absolute
    display: block !important
    right: -30px
    top: 30%

.settings-collapse-item
  background: transparent
  width: 100%
  .ant-collapse-item
    border: none
    width: 70%
    .ant-collapse-header
      padding-left: 0
      max-width: 50%



