:root {
    --grey: 102, 102, 102;
    --grey-100: 246, 246, 246;
    --grey-200: 233, 233, 233;
    --grey-300: 192, 186, 186;
    --grey-400: 130, 131, 131;

    --black: 26, 26, 26;

    --orange: 255, 81, 0;
    --orange-200: 255, 168, 128;

    --text-primary: 34, 34, 34;
    --text-secondary: var(--grey-400);
    --text-disabled: 181, 174, 174;
}
