// reset styles
//-----------------------------------------------------------------------------------
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend
    margin: 0
    padding: 0
    border: 0
    outline: 0
    font-size: 100%
    vertical-align: baseline
    background: transparent

table, caption, tbody, tfoot, thead, tr, th, td
    margin: 0
    padding: 0
    outline: 0
    font-size: 100%
    vertical-align: top

table
    border-collapse: collapse
    border-spacing: 0

blockquote, q
    quotes: none
    &:before,
    &:after
        content: ''

input, textarea, a, button, select
    &:focus
        outline: 0

ins
    text-decoration: none

del, s, strike
    text-decoration: line-through

html
    -ms-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%

article, aside, details, figcaption, figure, fieldset, footer, header, hgroup, main, nav, section, summary
    display: block
    margin: 0

ul
    list-style: none

b
    font-family: InterFace-Bold !important
