// header
//-----------------------------------------------------------------------------------
.header-top-nav
    display: flex
    align-items: center
    width: auto
    height: 7.5rem
    z-index: 101
    padding: 0 4.5rem 0 2.5rem
    border-bottom: solid 1px #e6e6e6
    background-color: #fff
    grid-area: header
    &.no-submenu
        left: 6.5rem
        width: calc(100% - 10rem)
    .header-top-nav-container
        display: flex
        align-items: center
        width: 100%
        height: 100%
        padding: 1.4rem 0
    .top-nav-title-block
        display: flex
        align-items: center
        .group-university-img
            height: 3.2rem
        .title
            margin-left: 1.6rem
            font-family: Source Sans Pro
            font-size: 2.1rem
            font-weight: normal
            font-stretch: normal
            font-style: normal
            line-height: 1.29
            letter-spacing: normal
            text-align: left
            color: #262e45
    .top-nav-dropdown-block
        margin-left: auto
        .ant-select-selector
            border-color: #ff5100 !important

    @media (max-width: 767px)
        width: 100%
        left: 0
