.dropdown-tags-block
  height: 300px
  .dropdown-tags
    max-height: 100%
.dropdown-tags
  font-size: 16px
  cursor: pointer
  top: 4.5rem
  overflow: hidden
  background-color: white
  box-sizing: border-box
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1)
  transition: max-height 0.3s
  height: auto
  max-height: 300px
  border: solid 1px rgba(38, 46, 69, 0.1)
  border-top: none
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  z-index: 3
  &.dropdown-tags-main
    position: absolute
  &.no-top
    top: 0

  .dropdown-tags-menu
    margin: 20px 24.7px 20px 0
    overflow: auto
    max-height: 260px

    &::-webkit-scrollbar
      width: 8px

    &::-webkit-scrollbar-thumb
      border-radius: 10px
      background-color: rgba(#222222, 0.2)

    &::-webkit-scrollbar-track
      border-radius: 10px
      background-color: unset

  .dropdown-tags-item
    position: relative
    font-family: $InterFace
    display: flex
    align-items: center
    height: auto
    color: #222222
    padding: 10px 30px
    &.active
      background-color: rgba(#222222, 0.05)
      color: #222222
    &:not(:last-child):after
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)
      content: ''
      width: calc(100% - 60px)
      height: 1px
      background-color: rgba(#222222, 0.1)

    &:hover
      background-color: rgba(#222222, 0.05)
      color: #222222
