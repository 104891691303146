.ant-btn-secondary {
    color: #fff;
    background-color: #262e45;
    box-shadow: 0 2px 0 rgba(255, 125, 5, 0.1);
}
.ant-btn-danger {
    color: #fff;
    background-color: red;
    box-shadow: 0 2px 0 rgba(255, 5, 5, 0.1);
}
