.icon
  cursor: pointer
  display: inline-block
  vertical-align: middle
  width: 1em
  height: 1em
  fill: currentColor

.icon-analytics
  width: calc(29/34)*1em

.icon-arrow
  width: calc(9/14)*1em

.icon-arrow-top
  width: calc(24/24)*1em

.icon-chat
  width: calc(35/31)*1em

.icon-close
  width: calc(348.333/348.334)*1em
  opacity: 0.5
  transition: all .2s
  &:hover
      transform: scale(1.5)

.icon-content
  width: calc(34/32)*1em

.icon-help
  width: calc(36/33)*1em

.icon-lock
  width: calc(24/24)*1em

.icon-mail
  width: calc(24/24)*1em

.icon-menu
  width: calc(512/512)*1em

.icon-settings
  width: calc(35/34)*1em

.icon-book-a-call
  width: 1.5em
  height: 1.5em