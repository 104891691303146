.admin-answer
  margin-top: 1.8rem
.admin-answers
  margin-top: 2rem
.students-adding
  margin-top: 1.8rem

.new-question, .question
  color: $dark

  &:last-child
    margin-bottom: 0

.new-question
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  width: 65rem
  max-width: 100%

.new-question-title, .question-title
  margin-right: 1rem
  font-size: 2.5rem
  font-family: $InterFaceBold

.answer-title
  margin-right: 1rem
  margin-left: 4rem
  line-height: 3rem
  font-size: 2rem
  font-family: $InterFaceBold

.new-question-bottom, .question-bottom, .answer-bottom
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  width: 100%
  font-size: 1.6rem
  & span
    font-size: 1.2rem
    margin-top: .3rem

.new-question-tag-row, .question-tag-row
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  margin-right: 1rem

.new-question-tag
  margin-right: 0.5rem

  &:last-child
    margin-right: 0

.new-question-body
  font-size: 1.8rem
  color: $dark
  & p
    line-height: 3rem

.new-question-bottom
  font-size: 1.6rem

.question
  display: flex
  padding: 12px 24px 18px 24px

  &__buttons
    margin-left: auto
    flex-shrink: 0

    &__button

      &--red
        margin: 2px 0 auto 17px
        color: rgba($taporange, 0.7)

      &:first-child
        margin-right: 25px

      &:hover
        color: $taporange

      &__icon
        margin-right: 5px
        opacity: 0.7

  &__title-icon
    flex-shrink: 0
    width: 18px
    height: 18px
    border-radius: 50%
    box-sizing: border-box
    padding: 3px
    background-color: $taporange
    margin-right: 15px
    opacity: 0.7

  &__body
    width: 100%

    &__time
      opacity: 0.6
      font-size: 1.4rem
      margin-bottom: 2px

    &__content
      display: flex
      width: 100%

      &__text
        margin-right: 130px
        display: inline-block
        font-weight: bold
        font-size: 1.8rem
        border-bottom: 2px solid rgba($taporange, 0.7)
        padding-bottom: 2px

        &--new-question
          font-weight: normal
          border: none

    &__answers
      padding: 20px 0 0 0

.tags-manager-button
  margin: 2px 0 auto auto
  color: rgba($taporange, 0.7)

.answer
  display: flex
  width: 100%
  box-sizing: border-box
  padding-left: 30px
  margin-bottom: 2.5rem

  &--editing
    flex-direction: column
    background-color: white
    padding: 15px
    border: 2px solid $taporange

  &__body
    word-break: break-word

    &__publisher
      font-family: $InterFaceBold
      font-size: 1.4rem

    &__content
      padding: 0
      font-size: 1.8rem
      color: $dark
      background: transparent
      border: none
      user-select: none
      margin-right: 30px
      max-height: 150px
      overflow-y: auto
      margin-top: 6px

      &.is-active
        background-color: white
        user-select: auto
        border: 1px solid $tapgrey
        &:hover
          cursor: text

  &__editable-content
    display: block
    font-size: 1.8rem
    background: white
    border: 2px solid $taporange
    padding: 12px 20px
    margin-right: 30px
    width: calc(100% - 200px)
    min-width: 250px

    &--editing
      height: 100px

    &:hover
      cursor: default

    &.is-active
      background-color: white
      user-select: auto
      border: 1px solid $tapgrey
      &:hover
        cursor: text

  &__buttons
    margin-top: auto
    margin-left: auto
    flex-shrink: 0

    &__button
      margin-right: 20px

      &--cancel
        opacity: 0.5

        &:hover
          opacity: 1

      &:hover
        color: $taporange

      &__icon
        margin-right: 5px
        opacity: 0.7


.DraftEditor-root
  box-sizing: border-box
  word-break: break-word
  padding-right: 15px
  margin-bottom: 10px
  width: 100%
  max-height: 200px
  overflow-y: auto

  &::-webkit-scrollbar
    width: 8px

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    background-color: rgba($tapgrey, 0.2)

  &::-webkit-scrollbar-track
    border-radius: 10px
    background-color: none

  a
    text-decoration: underline
    text-decoration-color: #0000EE
    color: #0000EE

.answered-question
  width: 100%
  margin: 15px 0
  background-color: rgba($tapgrey, 0.02)

.question-answer-edit-field--wrapper
  font-size: 1.8rem
  padding: 0.5rem 0rem 0.5rem 0.5rem
  width: 98.5%
  color: $dark
  background: transparent
  border: none
  user-select: none
  &:hover
    cursor: default

  &.is-active
    background-color: white
    user-select: auto
    border: 1px solid $tapgrey
    &:hover
      cursor: text

.answer-publisher
  margin-top: 1.1rem
  margin-left: 0.5rem

.hidden-buttons-block
  position: relative

  .error
    position: static

.warning-span-row
  display: block
  margin: 1rem 0 0
  font-size: 1.4rem

.faq-dropdown-tag-create
  position: absolute
  z-index: 9
  width: 100%
  background-color: #ffffff
  margin-top: -10px
  border-bottom: none

.content-header
  margin-top: 30px
.create-faq-btn
  margin-left: auto
  width: fit-content
  min-width: 140px
