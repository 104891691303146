.search-filter-custom
  font-size: 1.4rem
  position: relative
  margin: 1rem 0
  padding: 1.3rem 1.5rem 0.2rem 1.5rem
  
  .search-message-terms
    width: 100%
    height: auto
    padding-bottom: 15px
    .term-text
      display: flex
      .tilde
        height: 12px
        align-self: center
        padding: 0 0.5rem
    .search-message-term
      display: inline-flex
      font-family: $InterFace
      font-size: 1.6rem
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.19
      letter-spacing: normal
      text-align: left
      height: auto
      color: #262e45
      padding: 0.5rem 0 0.5rem 1.5rem
      white-space: pre
      cursor: default

      .search-message-term-icon
        width: 9px
        cursor: pointer
        margin-top: 1px
        margin-left: 0.7rem
        fill: rgba(0, 0, 0, 0.85)

  .input-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    height: 4.4rem
    border-radius: .3rem
    border: solid 1px #dadada
    transition: all 0.3s 0.3s
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1)
    &.messaging
      position: relative

    &.input-wrapper-open
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      border-bottom: solid 1px #dadada75
      box-shadow: none
      z-index: 3
      transition: all 0.3s
      
    .search-messages-input-icon
      width: 50px
      height: 50px
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      padding-top: 3px

    .chevron
      width: 14px
      transition: transform 0.3s
      fill: #262e45
      &.chevron-open
        transform: rotate(180deg)

  .search-filter-input
    font-family: $InterFace
    font-size: 1.6rem
    border: none
    width: 100%
    height: 100%
    cursor: text
    font-weight: 400
    font-style: normal
    font-stretch: normal
    line-height: 1.19
    letter-spacing: normal
    color: #262e45

  .icon-open-block
    display: flex
    justify-content: center
    align-items: center
    width: 50px
    height: 100%
    padding: 0 5px
    cursor: pointer
    &.messaging
      position: absolute
      top: 40px
    
  .menu-wrapper
    display: none
    position: absolute
    max-height: 0
    overflow: hidden
    width: calc(100% - 3rem)
    background-color: white
    box-sizing: border-box
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1)
    transition: max-height 0.3s
    z-index: 1

    &.menu-wrapper-open
      display: block
      height: auto
      max-height: 500px
      border: solid 1px #dadada
      border-top: none
      border-bottom-left-radius: 10px
      border-bottom-right-radius: 10px
      z-index: 3
    &.messaging
      margin-top: 5rem
      border-top: solid 1px #dadada

  .search-filter-menu
    margin: 10px 0 0
    overflow: auto
    max-height: 450px

    &::-webkit-scrollbar
      width: 8px

    &::-webkit-scrollbar-thumb
      border-radius: 10px
      background-color: rgba($dark, 0.2)

    &::-webkit-scrollbar-track
      border-radius: 10px
      background-color: unset

    .search-filter-item
      position: relative
      display: flex
      align-items: center
      font-family: $InterFace
      font-size: 1.6rem
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.19
      letter-spacing: normal
      text-align: left
      height: auto
      color: #262e45
      padding: 1.1rem 0 1.1rem 1.5rem
      text-transform: capitalize
      cursor: pointer

      &:not(.feedback-title):after
        position: absolute
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        content: ''
        width: calc(100% - 3rem)
        height: 1px
        background-color: #dadada

      &:hover
        background-color: rgba($dark, 0.05)
        color: $dark

      &--selected
        font-weight: bold
        color: $dark

        &:hover
          color: $dark

      &--selectAll
        opacity: 0.3

.search-filter-check-icon
  width: 1.5rem
  height: 1.5rem
  border: 0.5px solid #dbdbdb
  border-radius: 1px
  display: block
  position: relative
  margin-right: 1rem
  &.checked:before
    content: ""
    position: absolute
    width: 100%
    height: 100%
    background: url(../../../svg/check.svg) center center no-repeat
    background-size: 100%
  
.feedback-items
  font-family: $InterFace
  padding: 0.2rem 2rem
  position: relative
  padding-bottom: 10px
  &:after
    position: absolute
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    content: ''
    width: calc(100% - 3rem)
    height: 1px
    background-color: #dadada
  .feedback-body
    padding: 1rem 0 1rem 0
    display: flex
    justify-content: space-around
    .feedback-item
      font-size: 1.5rem
      position: relative
      .feedback-label
        padding-left: 2rem
        display: flex
        align-items: center
        cursor: pointer
        &:before
          position: absolute
          background-color: #fff
          content: ''
          -webkit-transform: none
          transform: none
          width: 1.5rem
          height: 1.5rem
          border-radius: 500rem
          border: 1px solid #d4d4d5
          left: 0
        &:after
          position: absolute
          content: ''
          left: 0
          width: 1.5rem
          height: 1.5rem
          border-radius: 500rem
          -webkit-transform: scale(.46666667)
          transform: scale(.46666667)
          background-color: rgba(255, 116, 0, 0.87)
          text-align: center
          opacity: 0
          color: rgba(0,0,0,.87)
      .feedback-label.feedback-item-checked
        &:after
          opacity: 1
          
      input[type='radio']
        visibility: hidden
        
.date-filter, .back-to-filter
  display: inline-block
  font-family: $InterFace
  margin: 1.9rem 2rem 1.9rem 3rem
  line-height: 1.19
  letter-spacing: normal
  text-align: left
  font-size: 1.6rem
  color: #ff5100
  .button-calendar-back
    cursor: pointer
    width: auto
    display: flex
  .date-chevron
    transform: rotate(-90deg)
    width: 10px
    height: auto
    fill: #ff5100
    margin: auto 0 0.45rem
  .date-chevron-text
    font-family: $InterFace
    padding-right: 10px
    letter-spacing: normal
    line-height: 1
    font-size: 1.6rem
    cursor: pointer

.chat-transcripts-button
  margin-top: 10px
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center
  cursor: pointer
  .chat-transcripts-icon
    margin-right: 5px
  .chat-transcripts-text
    color: #ff5100
  
.search-filters-calendar-custom
  left: auto !important
  right: 3.1rem !important
  .ant-picker-input-wrap, .ant-picker-range-middle
    display: none
  .ant-picker-footer
    border-top: none
  .ant-picker-footer-extra
    display: flex
    justify-content: center
    float: none !important
    .back-to-filter
      .date-back-chevron
        width: 13px
        height: auto
        transform: rotate(90deg)
        fill: #ff5100
      .date-back-text
        font-family: $InterFace
        padding-left: 5px
        letter-spacing: normal
        font-size: 1.6rem