// used in structure-sidebars.less, structure-grid.less and structure-grid-px.less
//-----------------------------------------------------------------------------------
$widthContent: 960px
$widthPageMax: none
$widthPageMin: 0

html
  height: 100%
  font-size: 62.5%
  box-sizing: border-box
  overflow: hidden
  overflow-x: auto
  &::-webkit-scrollbar
    width: 14px
  &::-webkit-scrollbar-thumb
    background-color: rgba(34,34,34,0.2)
  &::-webkit-scrollbar-track
    border-radius: 10px
    background-color: unset
body
  font-size: 1.6rem
  color: $dark
  letter-spacing: -.2px
  .no-flexbox &
    min-height: 100%
    height: 100%
    display: block !important

.page-wrapper,
.footer
  max-width: $widthPageMax
  min-width: $widthPageMin
  width: 100%
  margin: 0 auto
  .no-flexbox &
    width: auto

.page-wrapper
  min-height: 100vh
  height: 100%
  display: grid
  grid-template-columns: 6.5rem auto 1fr
  grid-template-rows: auto 1fr
  grid-template-areas: "nav submenu header" "nav submenu content"
  .lte-ie-11 &,
  .lt-ie-11 &
    min-height: 0
  @media (max-width: 767px)
    @supports (min-height: 100dvh)
      min-height: 100dvh

.page-wrapper > div
  &:last-child
    grid-area: content

  .no-flexbox &
    display: block !important
    width: auto
    min-height: 100%
    height: auto !important
    height: 100%

.page-body
  flex: 1
  &.flex-none
    flex: none

.wrapper
  position: relative
