.ad-uma-modal {
  list-style-type: disc;
  text-align: left;
  margin-right: 2rem;
}

.upl-bm {
  flex-direction: row;
  padding: 2rem;
}

.jc-c {
  justify-content: center;
  display: flex;
}

.upload-custom-data-btn {
  margin-top: 2.1rem;
  font-family: $InterFace;
  color: $taporange;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.optional-custom-data-label-text {
  font-family: $InterFace;
  color: $dark;
  font-size: 1.6rem;
  opacity: .66;
  margin-bottom: 0.5rem;
  display: block;
  margin-top: 2.1rem;
  margin-left: 2.1rem;
}

.optional-custom-answers-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.custom-column-upl-ans-modal {
  border: 1px solid #8f8e92;

  .ant-table-thead>tr>th {
    height: 10px;
    padding: 0 1rem;
    background: #ececec;
    color: #706f72;
  }

  tbody td,
  th {
    border: 1px solid #8f8e92;
    height: 10px;
    padding: 0 1rem;
    background: #ececec;
    color: #706f72;
    text-align: center;
  }

  thead td,
  th {
    border: 1px solid #8f8e92;
    background: #ececec;
    color: #706f72;
    height: 10px;
    padding: 0 1rem;
    text-align: center;
  }
}