.button-checkbox {
    &.chx-default {
        &:has(:focus-visible) {
            label {
                @apply border-orange-200 bg-grey-100;
            }
        }

        label {
            @apply text-grey rounded border border-solid border-grey-300 bg-white;
        }

        input:hover + label {
            @apply bg-grey-200;
        }

        input:active + label {
            @apply border-grey;
        }

        input:checked + label {
            @apply border-orange text-orange bg-[#FFBFA1];
        }
    }
}

@supports not selector(:focus-visible) {
    .button-checkbox {
        &.chx-default {
            &:focus-within {
                label {
                    @apply border-orange-200 bg-grey-100;
                }
            }
        }
    }
}
