.tap-pop-card {
  position: relative;
    margin-top: 10px;
}
.pop-card-wrapper {
  border: 0;
  vertical-align: baseline;
  font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,“Roboto”,“Droid Sans”,“Helvetica Neue”,Helvetica,Arial,sans-serif;
  font-weight: 700;
  min-width: 200px;
  width: fit-content;
  background-color: rgba(0,0,0,.69);
  margin-bottom: 2rem;
  padding: 1.7rem;
  box-shadow: 0 0 5px rgba(0,0,0,.02),0 5px 22px -6px rgba(0,0,0,.1),0 -12px 35px -19px rgba(0,0,0,.2);
  border-radius: 7px;
  text-decoration: none;
  color: #efefef!important;
  transition: all .2s;
  z-index: 99999;
}
.pop-card-wrapper:hover .avatar-wrapper img:not(:first-child) {
  margin-left: -.9rem;
}

.pop-card-wrapper:hover .avatar-wrapper img:nth-child(4) {
  margin-right: .25rem;
}

.pop-card {
  color: #efefef!important;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-card.pop-card-mobile {
    flex-direction: row;
}

.minimize-pop-card {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  position: absolute;
  right: -15px;
  top: -13px;
  width: 28px;
  height: 28px;
}

.pop-card-avatar {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #efefef;
    transition: 0.4s all;
    margin-right: 10px;
}

.avatar-wrapper {
  color: #efefef!important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  overflow: hidden;
  position: relative;
  margin: 8px 0 16px;
}

.avatar-wrapper img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #efefef;
  transition: 0.4s all;
  margin: 10px 0;
}

.avatar-wrapper img:nth-child(1) {
  z-index: 1;
}

.avatar-wrapper img:nth-child(2) {
  z-index: 2;
  margin-left: -1.3rem;
}

.avatar-wrapper img:nth-child(3) {
  z-index: 3;
  margin-left: -1.3rem;
}

.avatar-wrapper img:nth-child(4) {
  z-index: 4;
  margin-left: -1.3rem;
}

.pop-card-column {
  margin-left: 2rem
}

.pop-card-title {
  -webkit-font-smoothing: antialiased;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Lato','Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  margin: 0;
  font-weight: bold;
  text-align: center;
  width: auto;
  min-width: 12rem;
  line-height: 1.3;
  padding: 0;
  color: #efefef;
  font-size: 1.8rem;
}

.button.chat-now-button {
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  padding: 7px 24px;
  border-radius: 5px;
  background: #efefef;
  border: none;
  transition: all .2s;
  letter-spacing: normal;
  color: #262e45;
  font: 400 1.75rem Arial;
  font-family: Source Sans Pro,-apple-system,BlinkMacSystemFont,“Roboto”,“Droid Sans”,“Helvetica Neue”,Helvetica,Arial,sans-serif;
  font-weight: 700;
  text-transform: none!important;
  opacity: 0.9;
}

.button.chat-now-button:hover {
  cursor: pointer;
  color: #fff;
  background-color: #262e45;
  opacity: 0.7;
}

.mini-pop-card {
    width: fit-content;
}
.button.chat-now-button.chat-now-mini-button {
    display: flex;
    align-items: center;
    padding: 9px 18px;
    border-radius: 12px;
    font-family: sans-serif;
    font-weight: normal;
}
.chat-now-mini-button span {
    font-size: 18px;
    height: 18px;
    letter-spacing: 1px;
}
