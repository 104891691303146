// index/dashboard page
//-----------------------------------------------------------------------------------

.right-menu
  position: fixed
  top: 0
  right: 0
  width: 24rem
  height: 100vh
  z-index: 100
  background-color: #f3f3f6
  @media (max-width: 991px)
    transition: 0.3s
    transform: translateX(100%)
    z-index: 101
    &.is-active
      transform: translateX(0)
  @media (max-width: 767px)
    height: calc(100vh - 9rem)
    top: 6rem
    z-index: 101

.right-menu-header
  width: 100%
  height: 7rem
  background-color: #f3f3f6
  box-sizing: border-box
  padding: 2.15rem 2.1rem 1.8rem 2.4rem
  position: relative
  @media (max-width: 767px)
    height: 6rem
    padding: 1rem 2rem

.close-right-menu
  position: absolute
  color: #fff
  right: 2rem
  top: 50%
  height: 1.6rem
  margin-top: -0.7rem
  display: none
  @media (max-width: 991px)
    display: block
    z-index: 2

.menu-header-title
  opacity: 0.5
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  color: #8183c0

.edit-user-block
  position: relative
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  overflow: hidden
  width: 6rem

.edit-user-label
  position: absolute
  left: 0
  top: 0
  width: 40px
  height: 40px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: #666b80
  z-index: 2
  cursor: pointer
  transition: 0.3s
  opacity: 0

.edit-user-input
  position: absolute
  width: 0
  height: 0
  left: 0
  top: 0
  visibility: hidden

.edit-user-text
  color: #fff
  font-size: 1.2rem
  font-family: $InterFace

.menu-header-status
  font-family: $InterFace
  font-size: 1.8rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  color: #ffffff
  margin-bottom: 1.15rem

  span
    font-family: $InterFaceBold

  @media (max-width: 767px)
    margin-bottom: 0

.menu-header-progres-cotainer
  width: 100%
  height: 0.8rem
  border-radius: 0.5rem
  background-color: #fff
  @media (max-width: 767px)
    display: none

.menu-header-progresbar
  border-radius: 0.5rem
  height: 0.8rem
  background-color: #33b4ff

.right-menu-body
  padding: 2rem 1.85rem 2rem 2.05rem
  box-sizing: border-box
  height: calc(100vh - 10rem)
  overflow: auto
  @media (max-width: 767px)
    height: calc(100vh - 9rem)
    padding: 2rem

.search-input
  width: 100%
  height: 4rem
  border-radius: 0
  background-color: #E7E7ED
  border: 0.2rem solid rgba(#e7e7ed, 0.05)
  box-sizing: border-box
  transition: 0.3s
  text-indent: 3.68rem
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  color: $tapgrey
  background-image: url(../../../img/svg/search.svg)
  background-position: center left 1.43rem
  background-repeat: no-repeat

  &:hover
    border-color: #33B4FF

  &:focus
    border-color: #33B4FF

  &::placeholder
    color: rgba($tapgrey, 0.5)

.menu-search-form
  margin-bottom: 2rem

.tag-item
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: 1rem
  padding: 0.7rem 1.02rem 0.6rem 1.54rem
  transition: 0.3s
  border-radius: 1rem
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal
  color: #788998
  cursor: pointer
  border: 0.1rem solid transparent

  &:hover
    border-color: #ddd

  &:last-of-type
    margin-bottom: 0

  &.is-active
    box-shadow: 0 0.5rem 1.5rem 0 rgba(16, 27, 79, 0.15)
    background-color: #30b1fc
    color: #fff

    &:hover
      border-color: #30b1fc

.menu-header-block
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: flex-start

.content-iframe
  overflow: auto
  background: #fafafa
  height: 100vh
  margin-top: 0
  width: calc(100vw - 19.6rem)
  margin-left: 19.6rem
  box-sizing: border-box
  padding: 0

  &.no-submenu
    margin-left: 6.5rem
    width: calc(100vw - 6.6rem)

.super-admin-dashboard .content
  height: calc(100vh - 7.5rem) !important
  &.mod-chat
    height: auto !important
    .chat-nav, .chat-block
      height: calc(100vh - 7.5rem)
.content
    overflow-y: auto
    background: #fafafa
    height: 100vh
    margin-top: 0
    box-sizing: border-box
    padding: 4rem 2rem 2rem 2rem
    &.no-submenu
        width: calc(100vw - 6.6rem)
    &.setup-wizard
      padding: 2.5rem 5rem
    @media (max-width: 1040px)
        width: calc(100%)
    @media (max-width: 767px)
        width: 100% !important
        margin-left: 0 !important

    &--posts
        width: auto

    &::-webkit-scrollbar
      width: 14px
    &::-webkit-scrollbar-thumb
        background-color: rgba(34,34,34,0.2)
    &::-webkit-scrollbar-track
      border-radius: 10px
      background-color: unset

.content.full-width
  width: calc(100vw - 10rem)

.sort-row
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: center
  margin-bottom: 2rem

.sort-block
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  height: 4rem
  border-left: solid 0.1rem rgba($tapgrey, 0.1)
  padding-left: 2rem

.content-wrap
    position: relative
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-column-gap: 3rem
    box-sizing: border-box
    flex-wrap: wrap

    @media (max-width: 991px)
      grid-template-columns: repeat(2, 1fr)


    @media (max-width: 500px)
      grid-template-columns: 1fr

.content-column
    box-sizing: border-box
    flex-basis: 25%
    min-width: 25%
    padding-bottom: 3rem
    @media (max-width: 991px)
        flex-basis: 50%
        min-width: 50%
    @media (max-width: 500px)
        flex-basis: 100%
        min-width: 100%
.content-preview
  position: relative
  padding-top: 100%
  border-radius: 0
  display: block
  cursor: pointer
  overflow: hidden
  background-color: #f8f8f8
  transition: 0.3s

  &:hover
    .content-preview-hover
      opacity: 1

  &.mod-video
    &:before
      content: ''
      position: absolute
      width: 0
      height: 0
      border-top: 2.3rem solid transparent
      border-left: 3rem solid #fafafa
      border-bottom: 2.3rem solid transparent
      bottom: 2rem
      right: 2rem
      z-index: 2
      @media (max-width: 767px)
        border-top: 1.3rem solid transparent
        border-left: 2.6rem solid #fff
        border-bottom: 1.3rem solid transparent

.content-preview-img, .content-preview-video video
    position: absolute
    top: 0
    left: 0
    object-fit: cover
    height: 100% !important
    width: 100% !important
    flex-grow: 0
    border-radius: 0
.select
  &.sort
    @media (max-width: 767px)
      width: 100%

    .ui.selection.dropdown
      min-height: 4rem
      box-sizing: border-box
      border: 0
      border-left: 0.1rem solid rgba($tapgrey, 0.1)
      height: 4rem
      width: 42rem
      padding: 1.6rem 3rem 0 2rem
      border-radius: 0
      box-shadow: none
      @media (max-width: 767px)
        width: 100%

    .ui.dropdown > .text
      font-family: $InterFace
      font-size: 1.6rem
      font-style: normal
      font-stretch: normal
      line-height: normal

      color: $tapgrey

    .ui.selection.active.dropdown .menu
      box-sizing: border-box
      border-color: rgba($tapgrey, 0.1)
      border-radius: 0

    .ui.selection.dropdown .menu > .item
      font-family: $InterFace
      font-size: 1.6rem
      font-style: normal
      font-stretch: normal
      line-height: normal

      color: $tapgrey
      padding-left: 2rem !important

.sort-label
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  color: #9b9b9b
  margin-left: 2.1rem
  margin-bottom: -1.5rem
  position: relative
  z-index: 11

.content-preview-hover
  position: absolute
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.51)
  left: 0
  top: 0
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  padding: 2.7rem
  transition: 0.3s
  opacity: 0
  @media (max-width: 1450px)
    padding: 1.5rem
  @media (max-width: 1250px)
    padding: 1rem

  .content-preview-share
    position: absolute
    left: 2rem
    bottom: 2rem
    right: auto

.content-preview-top
  display: flex
  justify-content: space-between
  align-items: flex-start
  flex-direction: column !important
  width: 100%
  position: relative

.content-preview-user-info
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start !important

.content-preview-user-img
  border-radius: 50%
  width: 4rem
  height: 4rem
  margin-right: 10px

.content-preview-user-name
  font-family: $InterFaceBold
  font-size: 2.1rem
  font-style: normal
  font-stretch: normal
  line-height: 0.76

  color: #ffffff
  margin-bottom: 0.5rem
  word-break: break-all

.content-preview-user-text
  font-family: $InterFace
  font-size: 1.2rem
  font-style: normal
  font-stretch: normal
  line-height: 1.33
  display: none
  color: #ffffff

  &.mod-body-text
    text-align: left
    margin-right: auto

.content-preview-date
  font-family: $InterFaceBold
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: 2.76

  color: #ffffff
  @media (max-width: 1450px)
    font-size: 1.2rem

.content-preview-tags-line
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  flex-wrap: wrap
  margin-bottom: -1rem

.content-preview-tag
  font-family: $InterFaceBold
  font-size: 2.1rem
  font-style: normal
  font-stretch: normal
  line-height: 0.76

  text-align: center
  color: #ffffff
  margin-right: 1rem
  margin-bottom: 1rem
  @media (max-width: 1250px)
    font-size: 1.2rem

.content-preview-share
  font-family: $InterFace
  color: #ffffff
  background-color: $tapgrey
  border-radius: 4px
  padding: 0.7rem 1.2rem 1rem
  text-align: center
  box-sizing: border-box
  font-size: 1.8rem
  line-height: 1rem
  border: 2px solid $tapgrey

  &:hover
    text-decoration: none
    background-color: transparent
    border-radius: 0.4rem
    color: #fff
    border-color: #fff

.content-preview-delete
    color: red
    border: 0.1rem solid red
    border-radius: 3px
    padding: 0.7rem 1rem
    text-align: center
    box-sizing: border-box
    font-size: 1.8rem
    line-height: 1.5rem
    &:hover
        text-decoration: none
        color: #ffffff
        opacity: 0.7
        background-color: red

.post-galery-delete
  color: red
  border: 1px solid red
  background: none

.more-content-button
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  box-sizing: border-box

.tutorial-popup
  text-align: left

.dashboard-section-name
    font-size: 1.6rem
    font-family: $InterFaceBold
    color: $tapgrey
    padding-bottom: 1rem
    margin-bottom: 2rem
.requests-name
  font-size: 1.8rem
  letter-spacing: -.03rem
  font-family: $InterFaceBold
  margin-bottom: .6rem
  color: $tapgrey

.requests-text
  font-family: $InterFace
  font-size: 1.6rem
  color: $tapgrey
  margin-bottom: 3rem

.back-register-btn
  width: 100%
  color: #262e45
  display: flex
  justify-content: center
  margin-top: 10px
  font-size: 14px
  font-family: $InterFace

.registration-success
  height: 15rem
  text-align: center
  &.forgot-password-success
    height: 20rem
  &.university-name-changer
      height: 22rem
      .modal-title
          padding-top: 7rem

  .modal-title
    font-size: 2.1rem
    font-family: $InterFaceBold
    margin-bottom: 1rem
    color: #000
    margin-top: 7rem

.registration-success-button
  margin-top: 10px
  margin-bottom: 20px

.confirm-delete-popup
  height: 25rem
  text-align: center
  padding: 20px

.confirm-copy-popup
  text-align: center
  padding: 20px

.modal
  height: auto
  border-radius: 10px

  &__closeIcon
    display: block
    width: 14px
    opacity: 0.5
    margin-left: auto
    cursor: pointer

  &__img
    width: 200px
    margin-left: 25px
    margin-top: -25px

  &__title
    font-size: 1.6rem
    font-family: $InterFaceBold
    margin-bottom: 1rem
    color: #000
    margin-top: 7rem
    font-weight: normal

    &--upd
      margin-top: auto

  &__warning, &__warning-question
    display: block
    opacity: 0.4
    font-size: 1.4rem

  &__buttons
    display: flex
    justify-content: flex-end
    margin-top: 20px

    & button
      margin: 0

.users-container
  width: 100%

.content-header
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: 15px

.page-title
  font-size: 2.1rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-bottom: 2.4rem

.notification-block
  width: 100%
  padding: 1.5rem
  box-sizing: border-box
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  border-radius: 0.5rem
  margin-bottom: 1rem

  &:last-child
    margin-bottom: 0

  &.notification-orange
    background-color: $taporange

  &.notification-grey
    background-color: $tapgrey

  &.notification-red
    background-color: $tapred

.notification-text
  color: #fff
  font-family: $InterFace
  font-size: 1.4rem

.notification-btn
  font-family: $InterFace
  font-size: 1.2rem
  color: #fff
  display: block
  margin-left: auto
  width: 6rem
  height: 2.5rem
  box-sizing: border-box
  border: 0.1rem solid #fff
  border-radius: 0.5rem

.notifications-wrapper
  position: fixed
  left: 1rem
  top: 1rem
  width: calc(100vw - 2rem)
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  z-index: 1005

.closed-text
  &.unflag
    margin: 15px 0 0

  &.unword
    margin: 15px 0 0

.textarea-toolbar
  display: flex
  width: fit-content
  align-items: baseline

  &__buttons
    border-bottom: 1px solid black
    padding-bottom: 3px
    margin-bottom: 10px

    &__button
      cursor: pointer
      border-right: 1px solid black
      margin-right: 6px
      padding-right: 6px

      &--link
        border-right: none
        margin-right: 0
        padding-right: 0

      &:hover
        opacity: 0.5

  &__input-block
    display: inline-flex
    align-items: center
    transition: width 0.3s
    width: 200px
    height: fit-content
    margin-top: 2px

    &--hide
      width: 0
      overflow: hidden
      transition: width 0.3s

    &__input
      border: none
      border-bottom: 1px solid black
      margin: 0 10px
      padding: 5px

    &__button
      border-bottom: 1px solid black
      padding: 5px

/*Global Tags Start*/
.add-tags-link
  font-size: 1.6rem !important
  font-weight: normal
  font-style: normal
  font-stretch: normal
  letter-spacing: normal
  color: #ff5100
  opacity: 0.8
  display: flex
  justify-content: flex-end
  margin: 1rem 1rem 0 0
  span
    cursor: pointer

.post-galery-item
  .global-tags-block
     margin-top: 30px

.global-tags-modal
  padding-top: 3rem
  padding-bottom: 3rem
  .ant-modal-body
    padding: 0 !important
.modal-global-tags
  min-height: 400px
  padding: 3rem 0
  box-shadow: 1px 1px 2px 1px grey
  z-index: 999
  .global-tags-block
    display: flex
    flex-direction: column
    align-items: center
    margin: 0 1.5rem
    .tags-term-wrapper
        max-height: 320px

.add-tags-block
  display: flex
  flex-direction: column
  align-items: center
  margin: 2rem 1.5rem 0

.input-block
  position: relative
  display: flex
  flex-direction: row

.input-block .search
  position: absolute
  align-items: center
  height: 40px
  display: flex
  cursor: pointer
  padding: 0 2.8rem

.add-tag-input
  padding: 0.8rem
  padding-left: 6rem
  border-radius: 5px
  display: block
  width: 100%
  border: 1px solid #ccc
  color: #262e45
  font-size: 1.6rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  height: 40px
  letter-spacing: normal
  background-image: url(../../../img/svg/search.svg)
  background-position: center left 15px
  background-size: 24px 14px
  background-repeat: no-repeat

.add-tag-input:focus
  border: 1px solid #262E45 !important
  background-color: #FFFFFFFF
  opacity: 0.7
  outline: none !important
  outline-color: unset

.search-term-wrapper
  display: flex
  flex-wrap: wrap
  max-height: 200px
  overflow-x: hidden
  overflow-y: auto
  &::-webkit-scrollbar
    width: 6px
  &::-webkit-scrollbar-thumb
    border-radius: 10px
    background-color: rgba(#222222, 0.2)
  &::-webkit-scrollbar-track
    border-radius: 10px
    background-color: unset
    margin: 8px 0
  &.main-search-terms
    padding: 0.5rem 0 0 2.2rem

.search-term
  font-family: 'InterFace', Arial, 'Helvetica Neue', Helvetica, sans-serif
  font-size: 1.6rem
  display: flex
  align-items: center
  border-radius: 15px
  padding: 0 12px 1px 12px
  margin: 10px 10px 0 0
  cursor: pointer
  background-color: #262e45
  color: white
  opacity: 0.8

  &__close-icon
    width: 10px
    margin-top: 1px
    margin-left: 1rem
    fill: white

    &:hover
      fill: #ff5100
  &__block-icon
    width: 13px
    height: 13px
    margin-top: 1px
    margin-left: 1rem
    margin-right: 0.2rem
    fill: white
    &:hover
      fill: #ff5100
  &__block-icon-report
    width: 13px
    height: 13px
    margin-top: 1px
    margin-left: 1rem
    margin-right: 0.2rem
    fill: white

.more-tags
  padding: 0 1.2rem 1px 1.2rem
  margin: 1rem 1rem 0 0
  color: #262e45
  opacity: 0.6
  cursor: pointer

.no-tags-found
  display: flex
  height: 100%
  width: 100%
  justify-content: center
  align-self: center
  .text
    align-self: center
    text-align: center
    margin-bottom: 1rem
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    letter-spacing: normal
/*Global Tags End*/

/*Search*/
.search-tab-btn
  max-width: 39rem
  min-width: 200px
  width: 200px
  border-radius: 3px
  border: solid 1px #ff5100
  margin: 1rem 0 2rem auto
  font-family: 'InterFace', Arial, 'Helvetica Neue', Helvetica, sans-serif
  font-size: 1.8rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  letter-spacing: -.2px
  text-align: center
  color: #ff5100
  transition: 0.3s
  box-sizing: border-box
  display: block
  line-height: 38px

  @media (max-width: 767px)
    font-size: 1.6rem
  &.is-active
    color: #fff
    background-color: #ff5100
  &:hover
    color: #fff
    background-color: #ff5100
    text-decoration: none

  &--back
    position: relative
    margin: 0 0 0 auto

.search-head-filter-btn
  min-width: 200px
  text-align: center
  margin: 0 0 0 1.5rem
  padding: 10px 0
  color: #222222
  border-color: #222222
  transition: all .2s

  @media (max-width: 767px)
    height: 40px
    padding: 0
    line-height: 40px

  @media (max-width: 600px)
    max-width: 100%
    width: 100%
    margin: 5px 0 0 0

.search-footer-clear-btn
  display: block
  font-family: 'InterFace', Arial, 'Helvetica Neue', Helvetica, sans-serif
  color: #ff5100
  margin: 0 1rem 0 auto
  font-size: 2rem
  opacity: 1

  &:hover
    opacity: 0.7

.search-tags-form
  position: relative
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

  @media (max-width: 600px)
    flex-direction: column

  label
    position: absolute

  .search-icon
    position: absolute
    left: 1rem
    fill: #999
    width: auto
    height: 20px
    color: #999
    padding: 0.2rem 0.8rem 0 1rem
    cursor: pointer

    @media (max-width: 600px)
      top: 1.8rem

.search-tags-input
  box-sizing: border-box
  padding: 1rem 1.5rem 1rem 5.4rem
  width: 100%
  border-radius: .3rem
  border: solid 1px #dadada
  font-family: 'InterFace', Arial, 'Helvetica Neue', Helvetica, sans-serif
  font-size: 1.8rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1
  letter-spacing: -0.2px
  color: #222222
  transition: all .2s
  @media (max-width: 767px)
    height: 4rem
  &:disabled
    background-color: #f1f1f1 !important

.search-footer-clear-btn
  width: 100px
  letter-spacing: normal
  font-size: 1.8rem
  font-weight: bold
  margin: 0 0 0 auto
/*Search end*/

.flexible-page
  display: flex
  flex-direction: column
  .top-side-container
    margin-bottom: 2.7rem
    display: flex
    flex-direction: row
    .global-tags-block
      display: flex
  .bottom-side-container
    display: flex
    flex-direction: row
    margin-top: 3rem
  .left-side-container
    display: flex
    flex-direction: column
    width: 75%
  .right-side-container
    display: flex
    flex-direction: column
    width: 25%
    margin-left: 3rem

.no-info-found
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  letter-spacing: normal

.tag-orange
  background-color: $taporange

.user-item
  height: 4rem
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  cursor: pointer
  &:hover
    .edit-user-label
      opacity: 1
  a
    &:hover
      text-decoration: none
  @media (max-width: 767px)
    border-left: 0
    margin-left: auto
    padding-left: 0
.user-img
  border-radius: 50%
  width: 4rem
  height: 4rem
  @media (max-width: 767px)
    margin-right: 0
.user-name
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  color: $dark
  @media (max-width: 767px)
    display: none

.close-modal-button
  position: absolute
  height: 30px
  width: 30px
  right: -50px
  top: -15px
  fill: #ffffff
  cursor: pointer

.modal-restricted-area
    .ant-modal-mask, .ant-modal-wrap
        width: calc(100vw - 20.6rem)
        margin-left: 20.6rem
.restricted-modal-body, .partner-modal-body, .live-events-modal-body
    text-align: center
    padding: 30px 50px
.restricted-modal-header, .partner-modal-header, .live-events-modal-header
    font-size: 2.1rem
    font-weight: 600
    line-height: 1.29
    letter-spacing: normal
    text-align: center
    color: #222
    padding-bottom: 16px
.restricted-modal-text, .partner-modal-text, .live-events-modal-text
    font-size: 1.6rem
    font-weight: normal
    line-height: 1.25
    letter-spacing: normal
    text-align: left
    color: #222
    margin-top: 16px
.image-restricted
    padding-right: 20px
.contact-button
    display: inline-block
    line-height: 18px
    width: 141px
    border-radius: 4px
    margin-top: 32px
    background-color: $taporange
    color: #ffffff
    font-size: 1.6rem
    text-align: center
    padding: 10px 20px

.modal-partner-area, .modal-live-events-area
    .ant-modal-mask, .ant-modal-wrap
        z-index: 2 !important
        width: calc(100vw - 6.5rem)
        margin-left: 6.5rem
.image-partner
    height: 140px
.the-partner-link
    display: block
    font-size: 1.6rem
    font-weight: normal
    line-height: 1.25
    letter-spacing: normal
    color: $taporange
    text-align: left
    margin-top: 16px

.live-events-feature-page
    .live-events-modal-header
        padding-bottom: 0
    .video-live-events-wrapper
        padding-top: 0
        margin-bottom: 0
    .live-events-modal-text-start
        margin: 8px 0 16px
    .live-events-modal-text-center
        margin-top: 8px
    .live-events-text-bold
        font-weight: 600
        font-size: 1.6rem
        line-height: 1.25
        letter-spacing: normal
        font-family: 'Source Sans Pro'
    .events-items-example
        margin: 0.4rem 3rem 0.8rem
        p
            position: relative
            line-height: 1.25
        p::before
            content: "•"
            color: $taporange
            display: inline-block
            position: absolute
            width: 1rem
            margin-left: -1.5rem
            margin-top: 2px

.success-popup
    width: 40rem !important

.pined-messages-container
  width: 100%
  .ant-list-item
    background: #F6F7F8
    padding: 0.7rem 0
    cursor: pointer
    .ant-list-item-meta
      align-items: center
      .ant-list-item-meta-avatar
        fill: black
        width: 2.4rem
        padding-top: 0.5rem
        margin-left: 1.2rem
        margin-inline-end: 1.2rem !important
      .ant-list-item-meta-title
        margin: 0
      .ant-list-item-meta-description
        text-overflow: ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 1
        .bad-word
          color: red
    .unpin-button
      fill: black
      width: 10px
    &:last-child
      border-block-end: 1px solid rgba(5, 5, 5, 0.06)
