.tap-modal {
    max-height: 90vh;
    overflow-y: auto;

    .ant-modal-body {
        padding: 3.2rem;
    }
}

.ant-modal-confirm {
    .ant-modal-body {
        padding: theme('spacing.8') !important;

        .ant-modal-confirm-paragraph {
            max-width: 100%;
            row-gap: 2rem;

            .ant-modal-confirm-content {
                @apply text-reading-lg text-center font-interface;
            }
        }
        .ant-modal-confirm-btns {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            gap: 1.6rem;

            .ant-btn {
                @apply text-reading-lg py-3 grow rounded m-0 h-auto;

                &.ant-btn-default {
                    @apply text-brand-orange border-brand-orange;
                }
            }
        }
    }
    .ant-modal-confirm-title {
        @apply text-header-sm font-bold text-center font-interface-bold;
    }
}
