.pagination
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 5rem
    li
        display: block
        padding: 0 1rem
        &:hover
          cursor: pointer
          background-color: #fff
        a
            font-family: $InterFace
            font-size: 1.6rem
            color: #000
        &.active
            background-color: #70bbfd
