h1, h2 {
    color: $dark;
    font-size: 1.8rem;
    font-family: $InterFaceBold;
}

td {
    color: $dark;
    font-size: 1.6rem;
    font-family: $InterFace;
}

.ui-kit-input {
    display: block;
    padding: 0.3rem;
    width: 100%;
    border: 0.1rem solid #e7e7e7;
    color: $tapgrey;
    font-size: 1.6rem;
    line-height: 2;
    user-select: text;
    border-radius: 1px;
    transition: border-color 0.15s linear;
    box-sizing: border-box;
    text-indent: 0.9rem;
    font-family: $InterFace;
    background-color: #fff;
}