.community-container {
    .content {
        padding: 0 2rem 0 2rem;
    }
}

.ambassador-modal {
    .ant-modal-body {
        padding: 3.2rem;
    }

    .ant-checkbox-checked {
        :not(.ant-checkbox-disabled) {
            background-color: black !important;
            border-color: black !important;
        }
    }
    .ant-checkbox {
        :not(.ant-checkbox-disabled) {
            border-color: black !important;
        }
    }
    .ant-checkbox-inner {
        width: 2.1rem;
        height: 2.1rem;
        &:after {
            inset-inline-start: 30%;
        }
    }
}

.upload-members-modal {
    .ant-modal-body {
        padding: 3.2rem;
    }
}

.communities-members-table {
    tbody[class*='ant-table-tbody'] td {
        text-align: left;
    }
    .ant-table-tbody > tr > td {
        @apply text-reading-base;
    }
    .ant-table-thead > tr > th {
        @apply text-reading-base font-interface-bold;
    }
    .ant-pagination-next,
    .ant-pagination-item,
    .ant-pagination-prev {
        @apply border border-solid border-border-gray;
    }
    .ant-pagination-item-active {
        @apply border-brand-orange;
    }
    .ant-pagination-total-text {
        margin-inline-end: 1.6rem;
    }
}
