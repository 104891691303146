@tailwind base
@tailwind components
@tailwind utilities

  // import standards
//-----------------------------------------------------------------------------------
@import standards/mixins.sass
@import standards/variables.sass
@import standards/reset.sass
@import standards/common.sass
@import standards/structure.sass
@import standards/icon_default.sass
@import standards/ui_kit.scss
@import standards/colors.scss

// import grid
//-----------------------------------------------------------------------------------
@import standards/structure-grid-bootstrap.sass
@import standards/responsive-utilities.sass

// import components
//-----------------------------------------------------------------------------------
@import components/custom-dropdown.sass
@import components/dropdown.sass
@import components/search-filter.sass
@import components/elements.sass
@import components/header.sass
@import components/footer.sass
@import components/plugin.sass
@import components/nav.sass
@import components/video.sass
@import components/table.sass
@import components/pagination.sass
@import components/custom-answers-modal.scss
@import components/modal.scss

// import pages
//-----------------------------------------------------------------------------------
@import pages/index.sass
@import pages/signin.sass
@import pages/home.scss
@import pages/settings.sass
@import pages/content.sass
@import pages/chat.sass
@import pages/live-events.sass
@import pages/faq.sass
@import pages/start-guides.sass
@import pages/setup-wizard.sass
@import pages/sa.sass
@import pages/reporting.sass
@import pages/analytics.sass
@import pages/promotion.sass
@import pages/community.scss
@import pages/sso.scss
@import pages/pls.scss
