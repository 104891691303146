.profile-info-wrap
  margin: 2rem 3rem
  .global-tags-block
    width: 100%
    display: flex
    flex-direction: column
    .tags-title
      display: flex
      justify-content: space-between
      align-items: flex-end
      color: #262e45
      font-weight: bold
      font-size: 16px
      font-family: $InterFaceBold
      letter-spacing: normal
    .add-tags-link
      margin-bottom: 1rem

.profile-info-popup
  padding: 10px

  td
      padding-bottom: 5px
      padding-top: 5px
      vertical-align: top

  .profile-info-tags-items
      display: flex
      flex-wrap: wrap
      align-items: center
      text-align: left

  .profile-info-tags-name
      margin: 2rem 0 0.5rem
      padding-right: 2rem
      font-size: 1.6rem
      font-weight: 600

  .profile-info-row
      text-overflow: hidden

  .popup-header
      overflow: hidden
      font-size: 1.6rem

  .confirm-ban-buttons
      margin-top: 2rem

  .sa-detail-title
      font-size: 2.6rem
      width: auto

  .profile-info-tags-items
    display: flex
    font-size: 1.6rem
    .student-subject
      border: none
      padding-left: 0
      margin: 0
      min-width: 20rem
      display: grid
      grid-template-columns: 170px 30px
      &.edit
        display: block
    .student-subject-input
        font-size: 1.6rem
    span
        display: block
        margin-right: .66rem
        margin-bottom: .66rem
        padding: .3em .8rem
        background-color: #fff
        border: 1px solid #d9d9d9
        border-radius: 0.3rem
        font-size: 1.2rem
        font-family: $InterFace
        color: $tapgrey
        text-transform: capitalize

.max-limit-popup
    .modal-title
        text-align: center
        font-size: 1.6rem
    .max-limit-users-confirm-button
        margin-top: 2rem
        text-align: center
.interests-societies-row
  text-align: left
  padding-bottom: 5px
  padding-top: 5px
  vertical-align: top

.description-row
  text-align: left
  margin-top: 20px

.input

  &__wrapper
    display: flex
    flex-direction: column
    margin-top: 5px
    word-break: break-word

    &--active
      padding: 8px
      border: 1px solid $tapgrey

    &--warning
      padding: 8px
      border: 1px solid red

  &__length-indicator
    width: 100%
    text-align: right
    font-size: 1.4rem
    margin-top: 5px
    color: $tapgrey

  &--warning
    color: red

  &__buttons
    display: flex
    justify-content: flex-end
    margin: 10px 10px 0 0

    &__button--disabled
      opacity: 0.4

.input-control-button
  font-size: 16px
  color: $taporange
  margin-left: 20px
  padding: 0

.edit-student-subject-btn
  margin-left: 5px

.profile-avatar
  float: left
  width: 12rem
  height: 12rem
  min-width: 12rem
  margin: 2rem 3rem

  &--message-section
    float: none
    width: 5rem
    height: 5rem
    flex-shrink: 0
    margin-top: 2px
    margin-right: 30px

.first-message-wrapper
  margin-top: 10px

.first-message-content-wrapper
  position: relative
  width: 100%
  display: flex
  margin-top: 10px

.first-message

  &__message
    z-index: 1

  &__triangle
    position: absolute
    width: 25px
    height: 25px
    background-color: white
    border-bottom: 1px solid $light-grey
    border-left: 1px solid $light-grey
    transform: rotate(45deg)
    left: -14px
    top: 12px
    z-index: -1

  &__message-wrapper
    position: relative
    border: 1px solid $light-grey
    border-radius: 4px
    padding: 10px
    flex-grow: 1
    word-break: break-word
    z-index: 1

    &--active
      width: 100%
      border: none
      padding: 0


.pencil-icon
  width: 18px
  margin-right: 2px

  &:hover
    opacity: 1

.ambassadors-table
  .table-head-item
    font-family: $InterFace
    letter-spacing: normal
    color: #000000
    font-size: 1.8rem
    &:nth-child(1)
      text-align: left
      padding-left: 2rem
    &:after
      content: ''
      width: inherit
      display: block
      padding-bottom: 0.5rem
      margin-left: -1rem
      border-bottom: 1px solid #e3e3e3
  .table-head-item, .table-item
    text-align: center
    &:nth-child(3), &:nth-child(6)
      display: none
  td:nth-child(7) > .slideOne::before
    content: 'Toggle ambassador for Chat'
  td:nth-child(8) > .slideOne::before
    content: 'Toggle ambassador for Content'
  td:nth-child(9) > .slideOne::before
    content: 'Toggle ambassador for FAQ'
  /* select from second*/
  .table-item + .table-item
    padding-left: 0.8rem
    padding-right: 0.8rem

.actions-buttons-block button
  width: 8rem
  height: 2.4rem
  font-family: $InterFace
  font-size: 1.4rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.14
  letter-spacing: normal
  text-align: center
  border-radius: 0.5rem
  &.view-profile-btn
    color: $darklight
    background-color: #d9d9d9
    margin-right: 0.6rem
    border: 1px solid $darklight
  &.chat-profile-btn
    color: #ffffff
    background-color: $darklight
    margin-left: 0.6rem
    &:hover
      background-color: $darklight
      opacity: 0.9

.profile-info-popup .chat-btn
  display: flex
  width: 130px
  height: 30px
  opacity: 0.8
  border-radius: 5px
  background-color: #262e45
  cursor: pointer
  margin: -1rem 0 0
  align-self: center
  button
    font-size: 16px
    font-family: $InterFace
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.19
    letter-spacing: normal
    text-align: center
    color: #ffffff
    margin: auto

.settings-title
  margin-bottom: 2rem !important
.invite-btn
  margin-left: auto
  width: fit-content
  min-width: 140px

.tabs
  border-bottom: 1px solid #e0dcdc
  display: flex
  flex-direction: row
  justify-content: space-evenly
  margin-top: 3rem
  cursor: pointer
  .tab-name
    color: #262e45
    margin-bottom: -1px
    padding: 0 2.5rem 0.5rem
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 1.19
    letter-spacing: normal
    &.active
      color: #ff5100d6
      font-weight: bold
      border-bottom: 1.5px solid #ff5100d6


.user-profile-modal
  padding-top: 3rem
  padding-bottom: 3rem

.user-profile-name
  font-size: 1.6rem
  text-decoration: underline
  &:hover
    color: unset

.textarea-info
  display: inline-block
  border: none
  height: 100px
.profile-info-child-container
  display: flex
  flex-wrap: wrap
  margin-top: 1rem
.profile-info-child-age-container
  flex: 0 50%
  margin-bottom: 1rem
.profile-info-child-age-container-one-row
  flex: 0 30%
  margin-bottom: 1rem
.profile-info-child-age
  margin-left: 1rem
  padding-right: 2rem
  font-size: 1.6rem
  font-weight: 600


.employee-profile-info-container
  margin: 0 3rem 0 3rem
  .employee-profile-info-row
    display: flex
    flex-direction: row
    align-items: baseline
    .profile-info-tags-name
      width: 35%
    p
      color: #222222
      font-size: 1.6rem
