.ant-modal-confirm-success.tap-event-modal {
    .ant-modal-body {
        @apply p-8;
        min-width: 550px;
    }

    .ant-modal-confirm-body {
        @apply justify-center;
        @apply items-center;
        .ant-modal-confirm-paragraph {
            flex: unset
        }
    }
}
