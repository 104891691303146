.custom-dropdown
    font-size: 14px
    position: relative
    cursor: pointer
    width: 461px
    margin: 4px 0 49px

    .input-wrapper
        display: flex
        justify-content: space-between
        align-items: center
        box-sizing: border-box
        padding: 0 20px 0 10px
        height: 4rem
        border-radius: .5rem
        border: solid 1px #dadada
        transition: all 0.3s 0.3s
        background: #fff

        &.input-wrapper-open
            border-bottom-left-radius: 0
            border-bottom-right-radius: 0
            border-bottom: transparent
            box-shadow: none
            z-index: 3
            transition: all 0.3s

        .chevron
            width: 14px
            transition: transform 0.3s
            &.chevron-open
                transform: rotate(180deg)

    .dropdown-input
        font-family: $InterFace
        font-size: 1.6rem
        border: none
        width: 100%
        height: 100%
        font-weight: 400
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: -.3px
        color: #222

        &:hover
            cursor: pointer

    .menu-wrapper
        position: absolute
        max-height: 0
        overflow: hidden
        width: 100%
        background-color: white
        box-sizing: border-box
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1)
        transition: max-height 0.3s
        z-index: 1
        margin-top: 4px

        &.menu-wrapper-open
            height: auto
            max-height: 300px
            border: solid 1px #dadada
            border-top: none
            border-bottom-left-radius: 10px
            border-bottom-right-radius: 10px
            z-index: 3

    .dropdown-menu
        margin: 0 24.7px 20px 0
        overflow: auto
        max-height: 260px


        &::-webkit-scrollbar
            width: 8px

        &::-webkit-scrollbar-thumb
            border-radius: 10px
            background-color: rgba(#222222, 0.2)

        &::-webkit-scrollbar-track
            border-radius: 10px
            background-color: unset

.dropdown-item
    position: relative
    font-family: $InterFace
    font-size: 1.6rem
    display: flex
    align-items: center
    height: auto
    color: #262e45
    padding: 10px 20px

    &:not(:last-child):after
        position: absolute
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        content: ''
        width: calc(100% - 40px)
        height: 1px
        background-color: #dadada78


    &:hover
        background-color: rgba(#222222, 0.05)
        color: #222222

    &--selected
        font-weight: bold
        color: #222222

        &:hover
            color: #222222

    &--selectAll
        opacity: 0.3

