.upcoming-events, .in-progress-events, .previous-events
    .content
        background-color: #fff
        padding: 0
        &.mod-chat
            padding: 0
    .events-list-block
        padding: 1.9rem 1.9rem 0 0
        &.stream
            padding: 1.9rem 1.9rem 0 3rem
            height: calc(100vh - 7.5rem)
    .live-event
        padding-top: 1.9rem
        .event-nav
            padding-right: 1.9rem
    .event-head
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        box-sizing: border-box
        border-bottom: solid 0.1rem rgba(#979797, 0.2)
        width: 100%
        padding: 0 3.1rem 1.4rem
        .live-event-date
            display: flex
            align-items: center
            font-size: 1.4rem
            line-height: 1.5
            letter-spacing: normal
            color: rgba(34, 34, 34, 0.8)
            margin-top: 4px
            svg
                margin-right: 6px
.event-name-block
    display: grid
    grid-template-columns: 1fr auto
    width: 100%
    .current-event-name-block
        overflow: hidden
        display: flex
        align-items: center
        .current-event-name
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
.live-events-start-guides
    background: #fafafa
    padding: 4rem 0 0 2rem
.react-create-event
    .ant-modal-body
        margin: 40px 0
.edit-live-event
    .modal-title
        margin-bottom: 20px !important
.create-live-event, .edit-live-event
    padding: 3rem 5rem !important
    .title-with-go-back
        position: relative
        svg
            position: absolute
            top: 50%
            transform: rotate(90deg)
            fill: $taporange
            cursor: pointer
    .select-all
        padding: 1rem 2rem 0
        width: 100%
    .content-group-users
        padding: 0 2rem
    .content-group-selected-names
        padding-left: 25px
        margin-bottom: 20px
    .add-amb-block
        padding: 0 !important
    .event-date-block, .event-time-block
        position: relative
        width: 90%
    .ant-picker
        width: 100%
        height: 3.5rem
        &.ant-picker-focused
            box-shadow: none
        .ant-picker-input
            height: 100%
            display: flex !important
            padding: 0 3.5rem
            input
                cursor: pointer
                font-size: 1.6rem !important
                &::placeholder
                    opacity: 1 !important
                    color: #9e9797

    .datepicker-icon
        position: absolute
        display: flex
        left: 1rem
        top: 50%
        transform: translateY(-50%)
        z-index: 9
        cursor: pointer
        .hide
            opacity: 0
    .datepicker-arrow-block
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        padding-right: 5px
        height: 100%
        width: 30px
        right: 0
        top: 53%
        transform: translateY(-50%)
        cursor: pointer
    .datepicker-arrow
        fill: #bcbcbc
        &.datepicker-arrow-up
            transform: rotate(180deg)
    .modal-title
        margin-bottom: 0
        font-family: "Source Sans Pro"
    .event-modal-text
        font-size: 1.6rem
        line-height: 1.25
        letter-spacing: normal
        color: #222222
        margin-bottom: 2.6rem
    .global-tags-block
        margin-bottom: 26px
    .private-chat-block
        margin-bottom: 50px
    .send-invitation-btn-container
        position: relative
        margin-bottom: 20px
        button
            width: 162px
            margin: 0
.event-sa-edit-title
    font-size: 2.4rem
    font-weight: 600
    line-height: 1.29
    letter-spacing: normal
    text-align: center
    color: #222222
    margin-bottom: 2.6rem
.event-time-block
    display: flex
    align-items: center
    border: solid 0.1rem #cec8c8
    border-radius: 5px
    height: 3.5rem
    font-size: 1.6rem
    margin-left: 10%
    .datepicker-icon
        svg
            width: 20px
            height: 18px
    svg path
        fill: #bcbcbc
    .event-time
        padding: 0 3.5rem
        width: 100%
        input
            border: none !important
            height: auto
            text-indent: 0
.event-time-drop
    height: 300px
    position: absolute
    display: flex
    z-index: 99
    border: 1px solid #bababa
    top: 37px
    background: #ffffff
    width: 100%
    left: 0
    .event-time-title
        font-size: 1.6rem
        font-weight: 600
        line-height: 1.63
        letter-spacing: normal
        color: #222222
        padding-top: 10px
        padding-left: 15px
    .event-time-list
        overflow-y: auto
        height: 260px
        text-align: center
        .event-time-item
            position: relative
            padding: 10px 10px 10px 0
            cursor: pointer
            &:hover
                background-color: #eeeeee
            &:after
                content: ""
                position: absolute
                height: 1px
                width: calc(100% - 20px)
                padding-right: 10px
                background-color: #e8e8e8
                left: 10px
                bottom: 0


.create-event-row
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    margin-bottom: 26px
    &.flex-end
        align-items: flex-end
    .custom-dropdown
        width: 100%
        margin-bottom: 0
.create-event-row .input-chat-wrapper
    display: flex
    flex-direction: column
    margin-top: 5px
    word-break: break-word
    padding: 0.6rem 1.6rem
    border-radius: 3px
    border: solid 1px #e3e3e3
    &.input-chat-wrapper-warning
        border: 1px solid red
    .input-length-indicator
        width: 100%
        text-align: right
        font-size: 1.4rem
        margin-top: 5px
        color: #262e45
        &.input-length-indicator-warning
            color: red
.event-step-number
    font-family: "Source Sans Pro"
    text-align: center
    color: rgba(34, 34, 34, 0.7)
    font-size: 1.8rem
    margin-bottom: 2.5rem
    line-height: 1.28
    letter-spacing: normal
.event-secondary-title
    font-size: 1.6rem
    line-height: 1.25
    padding: 0 0 16px
.invited-speakers-number
    font-size: 1.6rem
    text-decoration: underline
    line-height: 1.25
    letter-spacing: normal
    color: #222222
.invited-speakers-list
    margin: 10px 0 18px
    .invited-speaker
        display: flex
        align-items: center
        font-size: 1.6rem
        line-height: 1.5
        letter-spacing: normal
        color: #222222
        margin-bottom: 8px
        .speaker-email
            position: relative
            margin-left: 32px
            &:before
                content: ""
                background-color: $taporange
                position: absolute
                top: 43%
                left: -12px
                width: 5px
                height: 5px
                border-radius: 50%
        .speaker-remove-btn
            margin-left: auto
            cursor: pointer
.event-image-block
    position: relative
    margin-left: 10px
.event-cover-image-example
    display: flex
    flex-direction: row
    .event-image-card-example
        width: 114px
        background-color: rgba(10, 37, 64, 0.8)
        .event-image-card
            display: flex
            align-items: center
            height: 100%
            position: relative
        img
            position: absolute
        span
            position: absolute
            text-align: center
            font-size: 1.4rem
            line-height: 1.29
            color: #ffffff
.event-example-description
    padding: 5px 10px 4px
    min-width: 240px
    .event-name-example
        font-size: 1.4rem
        font-weight: 600
        line-height: 1.29
        letter-spacing: normal
        color: #222222
        margin-bottom: 2px
    .event-date-example
        display: flex
        align-items: center
        svg
            margin-right: 6px
        span
            font-size: 1.4rem
            line-height: 1.5
            letter-spacing: normal
            color: #4c4c4d
    .event-example-description-text
        font-size: 1.4rem
        line-height: 1.45
        color: #4c4c4d
        margin: 6px 0 9px
    .event-join-example
        color: $taporange
        margin-bottom: 8px
        svg
            margin-left: 8px
            transform: rotate(-90deg)
            fill: $taporange

.event-image-icon-info
    display: flex
    align-items: center
    margin-left: 8px
    svg
        cursor: pointer
        margin-left: 10px
        margin-top: 2px
        width: 16px
        height: 16px
        fill: #a7a7a7

.event-name
    font-size: 1.6rem
    font-weight: 600
    font-family: $InterFace
    margin-top: 0
    margin-bottom: 0.6rem
    color: $dark
    letter-spacing: normal
    line-height: 1.17
    width: 100%
.event-name-input
    height: 6rem !important
    padding: 0 0 2.2rem 0.6rem !important

.event-image-upload
    position: relative
    border-radius: 5px
    border: 1px dashed #e3e3e3
    &.event-image-uploaded
        background-color: #000000
        border: 1px solid #e3e3e3
        &:after
            content: ''
            position: absolute
            opacity: 0.4
            background-color: #000000
            width: 100%
            height: 20px
            bottom: 0

.change-event-image-button
    position: absolute
    bottom: 0
    width: 100%
    height: 20px
    color: #ffffff
    cursor: pointer
    z-index: 1
    display: flex
    align-items: center
    justify-content: center
.event-image-preview
    position: absolute
    opacity: 0
    max-width: 100%
    max-height: 100%
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    cursor: pointer

    &.visible
        opacity: 1
        &:before
            content: 'Change'
            position: absolute
            color: #ffffff

.event-cover-image
    display: flex
    align-items: center
    font-size: 1.6rem
    font-weight: 600
    font-family: $InterFace
    margin: 0 0.4rem 0.6rem 0
    color: $dark
    letter-spacing: normal
    line-height: 1.17
    svg
        margin-left: 0 !important

.event-upload-image-button
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 60px
    font-family: $InterFace
    font-size: 1.6rem
    letter-spacing: -0.2px
    text-align: center
    color: $taporange
    cursor: pointer
    padding-right: 10px

.speaker-number
    font-size: 1.6rem
    font-weight: 600
    letter-spacing: normal
    line-height: 1.26
    margin-bottom: 8px
    text-decoration: underline
.event-speakers-submit
    width: 162px
.create-event-btn
    background-color: #0a2540 !important
    color: #ffffff !important
    width: 162px
    border: 0
    margin: auto
    display: flex
    justify-content: center
    padding: 1.1rem 0 !important

.new-event-button
    text-align: right
.create-event-popup-btn
    width: 260px
    text-align: center
    margin: 25px 0 42px
.live-events-loader .load
    height: calc(100vh - 180px) !important
.events-list
    display: inline-grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 20px 40px
    width: 100%
    .event-item
        display: inline-grid
        grid-template-rows: 1fr auto
        width: 100%
        .event-item-description
            margin-top: 8px
        .event-stream-item-description
                margin-top: 8px
                margin-bottom: 8px
        .live-event-name
            word-break: break-all
            font-size: 1.6rem
            font-weight: 600
            line-height: 1.25
            letter-spacing: normal
            color: #000000
            cursor: pointer
            padding-bottom: 1px
            &:hover
                opacity: 1
                color: $taporange
                text-decoration: underline
        .live-event-date
            display: flex
            font-size: 1.4rem
            font-weight: normal
            line-height: 1.5
            letter-spacing: normal
            color: #4c4c4d
            .cancelled-event-text
                color: #dc2e2e
                margin-left: auto
        .event-item-date
            display: flex
            font-size: 1.6rem
            font-weight: normal
            line-height: 1.5
            letter-spacing: normal
            color: #4c4c4d
            margin-top: 8px
            margin-bottom: 8px
            .event-item-date-time
                margin-right: 2rem
            .state-event-element
                font-size: 1.3rem
                border-radius: 0.5rem
                padding: 0 1rem
                text-transform: capitalize
                &.public, &.private
                    border: 1px solid #D9E783
                    background-color: #D9E783
                &.archived
                    border: 1px solid #ed8773
                    background-color: #ed8773
            .status-event-element
                font-size: 1.3rem
                padding: 0 1rem
                margin-left: 2rem
                text-transform: capitalize
                border: 1px solid #000

        .event-item-description-text-block
            overflow: hidden
            text-overflow: ellipsis
            word-break: break-word
        .image-event-block
            position: relative
            display: flex
            overflow: hidden
            flex-direction: column
            padding-bottom: 50%
            width: 100%
            &:hover
                opacity: 1
        img
            position: absolute
            object-fit: cover
            cursor: pointer
            height: 100%
            width: 100%
            top: 0
            left: 0
            bottom: 0
            right: 0
        .prospects-number-block
            display: flex
            align-items: center
            svg
                fill: rgba(34, 34, 34, 0.8)
            span
                font-size: 1.4rem
                font-weight: normal
                line-height: 1.5
                letter-spacing: normal
                color: #222222
                margin-left: 5px
        &.stream
            display: flex
            flex-direction: column


.live-event
    display: grid
    grid-template-columns: minmax(600px, auto) 38rem
    height: calc(100vh - 5rem)
    width: 100%
    box-sizing: border-box
    background-color: #fff
    &.live-group-event
        height: calc(100vh - 12rem)
    .chat-footer-closed
        height: auto
        .closed-text
            margin-right: 40px

.event-preview-block
    opacity: 1
    height: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    .event-preview-image-block
        display: flex
        align-items: center
        position: relative
        height: 100%
        margin: 0 10px
    .event-preview-image
        position: relative
        margin: auto
        width: calc(100% - 20px)
        .event-mask
            position: absolute
            display: none
            top: 50%
            transform: translateY(-50%)
            padding: 20px 0
            left: 0
            width: 100%
            background-color: rgba(34, 34, 34, 0.4)
            &.cancelled
                background-color: rgba(220, 46, 46, 0.7)
        img
            object-fit: cover
            width: 100%
            max-height: calc(100vh - 250px)
    .event-image-placeholder
        display: flex
        flex-direction: column
        height: 100%
        width: 100%
        color: #ffffff
        text-align: center
        letter-spacing: normal
        z-index: 1
        .event-image-start-header
            font-size: 1.6rem
            font-weight: 600
            line-height: 1.9
        .event-image-time-left
            font-size: 4.2rem
            min-height: 58px
        .ant-statistic-content-value
            color: #fff
            font-size: 4.2rem

.stream-image-container
    position: relative
    .stream-image-placeholder
        position: absolute
        top: 40%
        background-color: rgba(34, 34, 34, 0.4)
        text-align: center
        width: 100%
        color: #fff

.event-video-stream-block
    padding: 0 32px
    height: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    width: 100%
    background-color: #000000
    .event-preview-user-name
        position: absolute
        bottom: 0
        padding-left: 12px
        color: #FFFFFF
        font-size: 1.4rem
        line-height: 1.5
        letter-spacing: normal
.event-video-stream-list
    position: relative
    margin: auto
    width: 100%
    height: 100%
    max-height: 100%
    display: grid
    grid-gap: 20px
    grid-template-columns: repeat(12, 1fr)
    &.event-speakers-1, &.event-speakers-2
        .fm-video:nth-child(1),
        .fm-video:nth-child(2)
            grid-column-start: span 12
    &.event-speakers-3, &.event-speakers-5
        .fm-video:nth-child(1)
            grid-column-start: span 12
        .fm-video:nth-child(2), .fm-video:nth-child(3),
        .fm-video:nth-child(4), .fm-video:nth-child(5)
            grid-column-start: span 6
    &.event-speakers-4, &.event-speakers-6
        .fm-video:nth-child(1), .fm-video:nth-child(2), .fm-video:nth-child(3),
        .fm-video:nth-child(4), .fm-video:nth-child(5), .fm-video:nth-child(6)
            grid-column-start: span 6
    &.event-speakers-7, &.event-speakers-8, &.event-speakers-9
        .fm-video:nth-child(1), .fm-video:nth-child(2),
        .fm-video:nth-child(3), .fm-video:nth-child(4),
        .fm-video:nth-child(5), .fm-video:nth-child(6),
        .fm-video:nth-child(7), .fm-video:nth-child(8),
        .fm-video:nth-child(9)
            grid-column-start: span 4
    &.event-speakers-10, &.event-speakers-11, &.event-speakers-12
        .fm-video:nth-child(1), .fm-video:nth-child(2),
        .fm-video:nth-child(3), .fm-video:nth-child(4),
        .fm-video:nth-child(5), .fm-video:nth-child(6),
        .fm-video:nth-child(7), .fm-video:nth-child(8),
        .fm-video:nth-child(9), .fm-video:nth-child(10),
        .fm-video:nth-child(11), .fm-video:nth-child(12)
            grid-column-start: span 3
    .fm-video
        width: 100%
        height: 100%
        display: flex
        align-items: center
        transform: none !important
        video
            width: 100%
            height: auto
            background-color: #000000
            object-fit: contain
        .user-video-name-block
            position: absolute
            color: #FFFFFF
            padding: 2.5px 42.3px 1.8px 13.5px
            text-align: center
            margin: auto
            display: flex
            justify-content: center
            bottom: 0
            grid-row-start: 1
            grid-row-end: 3
.video-avatar
    position: absolute
    width: 100%
    height: 100%
    z-index: 9
    object-fit: contain
.bottom-block
    padding: 10px 0
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    .left-control-block
        color: #FFFFFF
    .middle-control-block
        display: flex
        align-items: center
        justify-content: center
        svg
            cursor: pointer
            // -- changes
            margin: 0 12px
            color: #fff
            // -- END changes
        //svg:not(:first-of-type)
        //    margin-left: 25px
.event-prev-preview
    .event-mask
        height: 70px !important
        display: flex
        align-items: center
        &.event-mask-red
            background-color: rgba(220, 46, 46, 0.7)
        .event-image-start-header
            width: 100%
            color: #ffffff
            text-align: center
            letter-spacing: normal
.event-nav-header
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    margin-bottom: 20px
    &.event-nav-header-not-speaker
        grid-template-columns: 1fr 1fr
    span
        font-size: 1.6rem
        line-height: 1.25
        letter-spacing: normal
        color: #000000
        padding: 5px 0
        text-align: center
        border-bottom: 2px solid #e9e9ec
        cursor: pointer
    .event-active-tab
        font-weight: 600
        color: $taporange
        border-bottom: 2px solid $taporange
        cursor: default
.event-users-list-block
    margin-bottom: 16px
    .event-list-header
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        font-size: 1.6rem
        letter-spacing: normal
        margin-bottom: 10px
        .event-header-users-number
            display: flex
            color: $taporange
            span
                display: flex
                padding-left: 3px
                &:before
                    content: '('
                    font-size: 1.7rem
                &:after
                    content: ')'
                    font-size: 1.7rem
        .event-header-users-manage
            color: rgba(34, 34, 34, 0.7)
            cursor: pointer

.mod-online
    position: relative
    &:before
        content: ""
        position: absolute
        width: 14px
        height: 14px
        border: 0.25rem solid #fff
        background-color: #25d366
        z-index: 2
        border-radius: 50%
        bottom: 6px
        left: 32px
.event-nav
    display: flex
    flex-direction: column
    width: 38rem
    box-sizing: border-box
    height: 100%
    border-left: 0.1rem solid rgba(151, 151, 151, 0.2)
    overflow: auto
    padding-left: 20px
    .chat-block
        width: 100%
        overflow: auto
        .chat-body
            overflow: auto
            padding: 0
        .msgs-block
            max-width: 28rem
            min-width: 18rem
        .msg-avatar-block
            margin: 0 19px 0 12px

        .event-chat-dividing-line
            height: 1px
            width: 100%
            background-color: #e9e9ec
        .chat-footer
            padding: 0
            background-color: #ffffff
            margin-top: 2rem
            .chat-input
                width: calc(100% - 6.2rem)
                border: none
                border-radius: unset
.event-users-list
    .event-user-item
        display: flex
        letter-spacing: normal
        padding: 4.5px 0 5.5px
        &:not(:last-of-type)
            border-bottom: solid 1px #e9e9ec
        img
            width: 45px
            height: 45px
            border-radius: 50%
            object-fit: cover
            margin-right: 20px
        .event-user-name
            font-size: 1.6rem
            color: #262e45
            line-height: 1.38
        .event-user-type
            font-size: 1.4rem
            line-height: 1.57
            color: rgba(38, 46, 69, 0.7)

.view-more-event-users
    margin: 10px 0 16px
    font-size: 1.6rem
    line-height: 1.38
    letter-spacing: normal
    display: flex
    align-items: center
    cursor: pointer
    span
        margin-right: 6px
        color: rgba(34, 34, 34, 0.7)
    svg
        transform: rotate(90deg)
        margin-top: 2px
        path
            fill: #bababa !important
    &.view-less-event-users
        svg
            transform: rotate(-90deg)

.event-cancel-popup
    padding: 4rem 5rem
    border: solid 1px #dbdbdb
    text-align: center
    .confirm-cancel-buttons
        margin-top: 40px
    .modal-title
        font-size: 2.1rem
        letter-spacing: normal
        line-height: 1.48
.confirm-cancel-event-button
    width: 110px
    top: 30px
    margin-right: 20px
.cancel-event-button
    width: 110px
    top: 30px

.header-event-name
    display: flex
    align-items: center
    cursor: pointer
    width: max-content
    span
        overflow: hidden
        max-width: 290px
        text-overflow: ellipsis
    svg
        transform: rotate(90deg)
        margin-left: 10px
        width: 12px
        margin-top: 2px
        path
            fill: #bababa
    &.header-event-name-active
        svg
            transform: rotate(-90deg)
.event-link
    display: flex
    align-items: center
    margin-left: 26px
    cursor: pointer
    svg
        width: 16px
        height: 16px

.events-drop-block
    max-height: 350px
    overflow: auto
    .options-drop-item
        margin: 0 10px !important

.opacity-0
    opacity: 0
.opacity-1
    opacity: 1

.event-users-list-form
    .add-amb-title
        display: none
.event-date-time-input
    position: relative
    width: 100%
.error-event-message, .error-event-bottom
    position: absolute
    font-size: 1.6rem
    font-weight: normal
    line-height: 1.25
    letter-spacing: normal
    text-align: right
    color: red
    width: 100%
    top: 100%
.error-event-bottom
    text-align: center !important

.stream-event-nav
    width: 42.1rem
    box-sizing: border-box
    height: 100vh
    border-left: 0.1rem solid rgba(151, 151, 151, 0.2)
    overflow: auto
    .video-element
        width: 100% !important
        height: 25rem !important
    .video-element-processing
        position: absolute
        margin-left: auto
        margin-right: auto
        left: 0
        right: 0
        text-align: center
        top: 40%
        color: black
        font-size: 2rem
    .event-card-stream-container
        display: grid
        grid-gap: 5px
        padding-left: 20px
        padding-right: 20px
        margin-top: 20px
        word-break: break-word
        font-size: 1.4rem
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.5
        letter-spacing: normal
        text-align: left
        color: #4c4c4d
        img
            width: 100%
            height: 9rem
            object-fit: cover
        .event-card-stream-name
            font-family: $InterFaceBold
            font-size: 2.1rem
            font-weight: 500
            font-style: normal
            font-stretch: normal
            line-height: normal
            letter-spacing: -0.2px
            color: $dark
        .event-card-stream-date-container
            display: flex
            font-size: 1.7rem
            .event-card-stream-date
                margin-right: 20px
        .event-card-stream-description-text-block
            display: flex
            flex-direction: column
            align-items: flex-start
    .event-card-participants-container
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        font-size: 2.5rem
        .event-card-participants-statistic
            display: flex
            width: 95%
            background: #ebebeb
            margin-top: 2rem
            margin-bottom: 3rem
