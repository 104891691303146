.modal-button-arrow:focus {
    &.slick-next {
        .icon {
            transform: scale(1.5);
        }
    }
    &.slick-prev {
        .icon {
            transform: rotate(180deg) scale(1.5);
        }
    }

    &.slick-next,
    &.slick-prev {
        .icon {
            outline-color: theme('colors.brand-orange');
            outline-style: solid;
            outline-width: 2px;
            border-radius: 4px;
        }
    }
}
