// elements
//-----------------------------------------------------------------------------------
.el-btn
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: 1rem
  padding: 1rem 3rem
  transition: 0.3s
  border-radius: 0.4rem
  font-family: $InterFace
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal
  cursor: pointer
  background-color: $tapgrey
  color: #fff
  border: 1px $tapgrey solid
  transition: 0.3s
  display: inline-block

  &:hover
    border-color: $tapgrey
    background-color: #fff
    color: $tapgrey
    text-decoration: none

  &.mod-disabled
    background-color: $light-grey

.el-btn-neutral
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  font-size: 1.2rem
  border: 1px solid $dark
  padding: 0.8rem
  color: $dark
  display: inline-block
  margin-right: 1rem

  &.is-active
    color: $taporange
    border: 1px solid $taporange

.el-btn-outline
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin-bottom: 1rem
  padding: 0.9rem 1.8rem
  transition: 0.3s
  border-radius: 0.4rem
  font-family: $InterFaceBold
  font-size: 1.6rem
  font-style: normal
  font-stretch: normal
  line-height: normal

  cursor: pointer
  transition: 0.3s
  display: inline-block
  border: $tapgrey 0.1rem solid
  background-color: $tapgrey
  color: white

  &:hover
    text-decoration: none
    color: $tapgrey
    background-color: transparent

.btn-top-right
  float: right

.anchor-button
  position: absolute
  top: 2.5rem
  right: 2.5rem

  &a
    color: #fff

.el-checkbox
  @extend %clear-fix
  display: flex
  position: relative
  cursor: pointer
  font-size: 1.6rem
  font-weight: normal

  color: $dark
  line-height: 21px
  text-align: left
  vertical-align: middle
  transition: all 0.2s

  &:hover
    color: black

  input[type="checkbox"]
    position: absolute
    width: 0
    height: 0
    opacity: 0

    &:checked
      & ~ .checkbox-text
        border: 1px solid $taporange
        background: $taporange
        color: white


  .checkbox-text
    white-space: nowrap
    line-height: 30px
    padding: 0 12px
    vertical-align: middle
    display: inline-block
    border-radius: 2px
    border: solid 1px $tapgrey
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 500
    text-align: center
    color: $tapgrey

  &.mod-disabled
    cursor: default
    pointer-events: none

    .checkbox-icon
      border-color: black

    .checkbox-text
      color: black

.new-update-button
  top: 50px
  left: 50%
  transform: translateX(-50%)
  margin-top: 12px
  border-radius: 16px
  background-color: $taporange
  white-space: nowrap
  z-index: 1
  display: flex
  flex-direction: column
  align-items: center
  border: 0
  box-sizing: border-box
  color: #fff
  cursor: pointer
  display: inline-block
  font-size: 1.6rem
  height: 32px
  line-height: 32px
  outline-width: 2px
  padding: 0 16px
  position: absolute
  transition-duration: 167ms
  transition-property: background-color, box-shadow, color
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
  vertical-align: middle
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .15), 0 6px 9px rgba(0, 0, 0, .2)
  @media (max-width: 991px)
    left: calc(50% + 5rem)
  @media (max-width: 767px)
    left: 50%

  &.active
    opacity: 1
    visibility: visible
    font-family: $InterFaceBold

  .new-update-icon
    width: 16px
    height: 16px
    padding: 0 2px 0 0
    margin: 0
    top: 2px
    display: inline-block
    position: relative

  .new-update-text
    position: relative
    animation: textSlide .5s forwards
    animation-delay: 1s

.button-disabled
  color: $light-grey !important

.load__icon
  path
    fill: $tapgrey

.tabs-wrap
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row
  margin-bottom: 2.4rem

.tabs-btn
  display: inline-block
  width: 100%
  user-select: none
  border-radius: .5rem
  height: 4rem
  font-family: $InterFace
  font-size: 1.6rem
  transition: 0.3s
  background-color: #fff
  color: $tapgrey
  border: 0.1rem solid #ff6114
  line-height: 3.5rem
  text-align: center
  min-width: 150px
  &:first-of-type
    margin-right: .5rem
  &:last-of-type
    margin-left: .5rem
  &.is-active
    background-color: #ff6114
    color: #fff
    &:hover
      background-color: #ff6114
      color: #fff
  &:hover
    background-color: #fff
    color: $tapgrey
    cursor: pointer


.loader-component
  width: 100%
  display: block 
  padding: 60px 0
  text-align: center 
  img 
    max-width: 100px