#tap-page-button {
  font-size: 2rem;
  padding: 1rem 2rem;
  border-radius: 6px;
  border: none;
  background: #141e30;
  background: linear-gradient(to right, #141e30, #243b55);
  color: #fff;
  cursor: pointer;
  font-family: system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  letter-spacing: -.3px;
  transition: all .3s
}

#tap-page-button:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .15), 0 6px 9px rgba(0, 0, 0, .2);
  background: #141e30;
  background: linear-gradient(to right, #141e30, #243b55);
  opacity: 1
}
