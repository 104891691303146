// signin page
//-----------------------------------------------------------------------------------
.unregistered-page
    overflow-y: auto
    height: 100vh
    display: flex
    @media (max-width: 767px)
        @supports (height: 100dvh)
            height: 100dvh

.signin-container
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    min-height: 100vh
    padding: 0 2rem
    background-color: #f8f8f8
.signin-title
    font-size: 2.4rem
    font-weight: normal
    line-height: 0.75
    letter-spacing: normal
    margin-bottom: 6rem
    text-align: center
.signin-bottom-text
    font-size: 1.4rem
    line-height: 1.5
    letter-spacing: normal
    margin-top: 60px
    text-align: center
.tap-login-link
    color: $taporange
    text-align: center
    font-size: 1.4rem
    line-height: 1.5
.registration-preamble
    font-family: $InterFace
    text-align: center
    color: $tapgrey
    font-size: 1.6rem
    display: flex
    flex-direction: column
    width: 36rem
    max-width: 100%
    box-sizing: border-box
    padding: 3rem 2rem 0 2rem
    & img
     width: 20rem
     max-width: 40vw
     align-self: center
     margin: 0 auto 3rem auto
.signin-block
    border-radius: 0.3rem
    border: 1px solid #dbdbdb
    margin: 2.0rem auto
    padding: 3rem
    max-width: 100%
    width: 40rem
    background: #fff
    // box-shadow: 0 0 0.1rem rgba(0, 0, 0, .06), 0 0.2rem 0.5rem rgba(0, 0, 0, .03)
    box-sizing: border-box
.signin-input
    display: block
    padding: 1.2rem
    padding-left: 1.2rem
    width: 100%
    border: .1rem solid #e7e7e7
    color: $tapgrey
    font-size: 1.4rem
    line-height: 1em
    user-select: text
    border-radius: .2rem
    transition: border-color .15s linear
    box-sizing: border-box
    text-indent: 2.3rem
    font-family: $InterFace
    background-color: #fff
    &:focus
        border-color: #becdd5
    &::placeholder
        color: rgba($tapgrey, 0.5)
    &.mod-pass
        padding-right: 1rem
.registration-form, .recovery-form
    .signin-label
        margin-bottom: auto
        .input-block-field
            margin-bottom: 2.4rem
            &.input-block-field-error
                margin-bottom: 0.8rem
        .input-icon-block
            position: relative
.signin-label
    position: relative
    display: block
    margin-bottom: 2.4rem
    .error
        position: absolute
        top: 100%
        padding: 5px 0
        font-size: 1.4rem
        color: #dc2e2e
        font-family: $InterFace
        &.error-relative
            position: relative
            display: flex
            justify-content: flex-end

.icon-mail
    position: absolute
    color: #becdd5
    transition: 0.3s
    width: 1.4rem
    height: 1.4rem
    top: 50%
    margin-top: -0.7rem
    left: 1.2rem
.icon-lock
    position: absolute
    color: #becdd5
    transition: 0.3s
    width: 1.4rem
    height: 1.4rem
    top: 50%
    margin-top: -0.7rem
    left: 1.2rem
.signin-btn
    width: 100%
    color: #fff
    fill: #fff
    font-family: $InterFaceBold
    background-color: $tapgrey
    user-select: none
    border-radius: .5rem
    height: 4rem
    font-family: $InterFace
    font-size: 1.6rem
    transition: 0.3s
    border: 0.1rem solid $tapgrey
    box-sizing: border-box
    &:hover
        background-color: #fff
        color: $tapgrey
        transform: translate3d(0,-3px,0)
.recover-link
    text-align: right
    margin: -2rem 0 2rem

.v1-login
    display: block
    font-family: $InterFace
    font-size: 1.8rem
    transition: .2s
    color: rgba($tapgrey,.87)
    margin-top: 1rem
    &:hover
        text-decoration: none
        opacity: 1
        color: rgba($tapgrey,.66)
    span
        transition: .2s
        color: $taporange
        text-decoration: none
        cursor: pointer
        &:hover
            color: rgba($taporange,.66)

.privacy-link
  text-decoration: underline
  color: #ff5800

.registration-checkbox
    border-radius: 0

.forgot
    display: block
    height: 3.3rem
    line-height: 3.3rem
    font-family: $InterFace
    font-size: 1.4rem
    transition: 0.3s
    color: rgba($tapgrey, 0.5)
    &:hover
        text-decoration: none
        color: $tapgrey

.registration-form-container, .invite-admin-popup, .create-live-event, .edit-live-event, .pls-sign-up-form
    .signin-title
        font-size: 2.4rem
        font-weight: 400
        line-height: .75
        letter-spacing: normal
        text-align: center
        margin-bottom: 4.2rem
        span
            color: #ff5800
        &.trial
            font-size: 2.3rem
            margin-bottom: 1rem
    .signin-description
        margin-bottom: 3.2rem
    .registration-form-name-container
        display: flex
        flex-direction: row
        justify-content: space-between
        .signin-label
            width: 47%
    .signin-label-text
        color: $tapgrey
        font-size: 1.4rem
        line-height: 1em
        font-family: $InterFace
    .signin-input
        padding-left: 0
        padding-top: 0.8rem
        padding-bottom: 0.7rem
        text-indent: 1rem
        border-radius: 5px
    .signin-btn
        background-color: #ff5800
        border: none
        margin-bottom: 3.2rem
        &:hover
            color: #fff
            transform: none
        &.pls
            margin-top: 3rem
    .has-account
        display: flex
        flex-direction: row
        align-items: baseline
        p
            font-size: 1.4rem
            line-height: 1em
            font-family: $InterFace
        .forgot
            display: block
            font-family: $InterFaceBold
            font-size: 1.4rem
            transition: 0.3s
            color: #ff5800
            margin-left: 0.6rem
            &:hover
                text-decoration: none
                opacity: 0.4
    .legal-notice-container
        display: flex
        position: relative
        margin-bottom: 3.2rem
        .ant-checkbox-wrapper
            margin-right: 1.3rem
        .legal-notice
            font-size: 1.4rem
            margin-bottom: 2rem
            margin-right: 1.3rem
        .error
            position: absolute
            top: 85%
            right: 0
            padding: 5px 0
            font-size: 1.4rem
            color: #dc2e2e
            font-family: $InterFace
.sign-up-sso-btn, .sign-in-sso-btn
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

.sso-email-field
    display: flex
    width: 100%
    min-width: 100%
    border: 0.1rem solid #e7e7e7
    color: #262e45
    font-size: 1.6rem
    height: 40px
    border-radius: 0.2rem
    transition: border-color .15s linear
    box-sizing: border-box
    text-indent: 2.3rem
    font-family: $InterFace
    background-color: #fff
    padding: 0

    .ant-input-prefix
        position: absolute
        height: 40px
        z-index: 2
        left: 1.2rem
        padding: 0
    .icon-sso-mail
        margin-right: 6px
        color: #becdd5
        width: 1.4rem
        height: 1.4rem
    input
        padding-left: 35px !important

.eye-icon
    position: absolute
    color: #becdd5
    transition: 0.3s
    width: 2.2rem
    height: 2.2rem
    top: 50%
    margin-top: -0.7rem
    right: 1.2rem

.validation-icon-container
    position: absolute
    width: 1.5rem
    height: 1.5rem
    top: 70%
    margin-top: -0.7rem
    right: 1.2rem
    background-color: #4cd964
    border-radius: 50%
    .validation-icon-wrapper
        position: relative
        .validation-icon
            width: 2rem
            height: 2rem
            position: absolute
            top: 4px
            left: -1px
.maintenance-container
    display: grid
    grid-template-rows:  25rem 1fr 10rem
    grid-row-align: center
    min-height: 100vh
    padding: 0 2rem
    background-color: #f8f8f8
    justify-items: center
    text-align: center
    font-size: 2rem
    font-family: $InterFace
    .maintenance-logo
        width: 36rem
        max-width: 100%
        box-sizing: border-box
        padding: 3rem 2rem 0 2rem
        & img
            width: 20rem
            max-width: 40vw
            align-self: center
            margin: 0 auto 3rem auto
    .maintenance-description-title
        font-size: 6rem
        font-family: $InterFace
        @media (max-width: 767px)
            font-size: 3.5rem
    .maintenance-description-text
        margin-bottom: 4rem
        margin-top: 2rem
    .maintenance-description-email
        color: $taporange
    .maintenance-description-footer
        width: 100%
        padding-top: 3.5rem
        border-top: 1px solid #dbdbdb

.signin-container-trial
    display: grid
    width: 100%
    height: 100%
    .signin-blocks-container
        display: grid
        grid-template-columns: 60rem 76rem
        .pls-sign-up-info-block
            display: grid
            grid-template-rows: 15rem 10rem 25rem 10rem
            background: #E0E0E0
            padding: 3.6rem 7rem 0
            text-align: center
            .pls-sign-up-info-title
                font-size: 4.7rem
            .pls-sign-up-info-desription
                font-family: $InterFace
                font-size: 2.2rem
                color: #222
                text-align: start
            .pls-sign-up-info-logo
                width: 20rem
                max-width: 40vw
                align-self: center
            .pls-sign-up-info-partner-logos
                display: flex
                justify-content: center
                align-items: center
        .pls-sign-up-form-block
            background: #FFFFFF
            padding: 12rem 16rem 0 20rem
            .pls-sign-up-form-title-block
                margin-bottom: 3.2rem
                .pls-sign-up-form-title
                    font-size: 2.8rem
                    font-family: $InterFace
                    span
                        font-family: $InterFaceBold
                .pls-sign-up-form-description
                    font-size: 1.8rem
                    color: #707070
.pls-sign-up-form
    .pls-sign-up-form-item
        .ant-form-item-label
            padding: 0
            font-size: 1.4rem
            color: #222222
        #confirmPassword, #password
            box-shadow: unset
        .ant-form-item-explain > div
            text-align: end
    .pls-sign-up-form-information-button
        font-family: $InterFace
        color: #A1A1A1
        font-size: 1.4rem
        padding: 0
        display: flex
        align-items: baseline
        p
            text-decoration: underline
            margin-bottom: 2rem
        .chevron
            width: 12px
            transition: transform 0.3s
            margin-left: 0.4rem
            fill: #A1A1A1
            &.chevron-open
                transform: rotate(180deg)

.pls-privacy-policy-form
    font-size: 1.6rem
    .pls-privacy-policy-form-resources-list
        list-style-type: disc
        margin-left: 2.5rem
        a
            text-decoration: underline
            color: #ff5800
    .pls-privacy-policy-form-text
        font-family: $InterFace
        .pls-privacy-policy-form-bold-text
            font-family: $InterFaceBold
        .pls-privacy-policy-form-opacity-text
            opacity: 0.66
        &.form-item
            margin-bottom: 1rem
.pls-successful-modal
    .ant-modal-body
        padding-bottom: 0


