$base64-png: 'image/png;base64'
$base64-jpg: 'image/jpeg;base64'
$base64-svg: 'image/svg+xml;base64'

// font var
//-----------------------------------------------------------------------------------
$default-font: -system-ui,-apple-system,BlinkMacSystemFont
$alt-font: Arial, 'Helvetica Neue', Helvetica, sans-serif

+font-face('InterFace', '../../../fonts/InterFace/InterFace', 'InterFace', normal)
+font-face('InterFace-Bold', '../../../fonts/InterFace-Bold/InterFace-Bold', 'InterFace-Bold', 600)
//
//
$InterFace: 'InterFace', $default-font, $alt-font
$InterFaceBold: 'InterFace-Bold', $default-font, $alt-font

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro|PT+Mono')

$monospace: 'PT Mono', Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace



// selection color
//-----------------------------------------------------------------------------------
$color-selection: #ffe3ba


// color var
//-----------------------------------------------------------------------------------
$color-link: #ff5100
$dark: #222222
$tapgrey: #262e45
$taporange: #ff5100
$taplightorange: #ff9500
$tapnavy: #1f1646
$light-grey: #d4d4d4
$tapred: #fe3057
$darklight: #505669
