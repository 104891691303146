.setup-wizard
    p
        line-height: 1.5
    .settings-title
        font-size: 3.2rem
        &.trial
            font-size: 2.4rem
            margin: 0
.setup-modal .ant-modal-content
    border-radius: 5px
.setup-start-intro
    margin-bottom: 3rem
    max-width: 600px
    word-break: break-word
.setup-start-block
    display: flex
    flex-direction: column
    margin-bottom: 3rem
    max-width: 700px
    .university-form-input
        margin: 0 0 50px
        width: 100%
        font-size: 1.8rem
        padding: 7px 34px 9px 16px
        border-radius: 5px
        border: solid 1px #dadada
        color: rgba(34, 34, 34, 0.6)

.setup-policy-page
    .setup-start-block
        max-width: 550px
    .svg-wizard-slider
        .preview-slide svg
            height: 52vh
            max-height: 500px
            min-height: 250px

.setup-steps-buttons
    display: flex
    font-size: 1.6rem
    padding-top: 3rem
    button
        width: 14rem
        padding: 6px 25px
        border-radius: 5px
        color: #ffffff
        background-color: $taporange
        font-family: "Source Sans Pro"
        &:nth-child(1)
            margin-right: 20px
        &.disabled-button, &.previous-step-button
            color: rgba(38, 46, 69, 0.7)
            background-color: rgba(218, 218, 218, 0.6)

.setup-skip-text
    display: flex
    align-items: flex-end
    width: fit-content
    position: relative
    margin-top: 32px
    opacity: 0.7
    font-family: $InterFace
    font-size: 1.6rem
    font-style: normal
    line-height: 1.25
    letter-spacing: normal
    text-align: left
    color: #222222
    white-space: pre
    &::before
        position: absolute
        content: "("
        bottom: -0.2rem
        left: -5px
        font-size: 1.8rem
    &::after
        position: absolute
        content: ")"
        bottom: -0.2rem
        font-size: 1.8rem
        right: -5px
    svg
        width: 16px
        height: 15px
        margin: 2px

.policy-title, .setup-start-block-title
    font-family: $InterFaceBold
    font-size: 2rem
    line-height: 2.4rem
    font-weight: 600
    color: $taporange
    flex: 0 0 3rem

p.setup-start-block-description
    margin: 0
    color: rgba(0, 0, 0, 0.66)
    font-family: $InterFace
    font-weight: normal
    font-size: 1.6rem
    max-width: 85%

.video-privacy-link
    margin: 22px 0
    span
        color: $taporange
        cursor: pointer

.start-guide-wizard-grid
    display: flex
    justify-content: space-between
    margin: 0
    &.pls
        display: grid
        grid-template-columns: 60rem 1fr
    &.book-a-call
        display: grid
        grid-template-columns: 80rem 1fr
    .column
        flex: 1 1
        &:first-of-type
            margin-right: 5rem
        &.pls
            display: grid
            grid-gap: 1.5rem
            margin: 0
    .settings-name
        font-size: 1.6rem
        .label-rest-text
            margin: 0
    .credential-item
        font-family: $InterFaceBold
        font-size: 1.6rem
        margin-top: 1rem
        margin-bottom: .3rem
        span
            margin: 0
            opacity: .66
            font-family: $InterFace
            font-weight: normal
    .qr-code-container
        max-width: 80%
        display: grid
        grid-template-columns: 1fr 1fr 1fr
    .email-and-sms-invitation-container
        .email-and-sms-invitation-button
            font-family: $InterFace
            color: #000
            font-size: 1.6rem
            padding: 0
            opacity: .66
            span
                text-decoration: underline
        .email-and-sms-invitation-fields
            display: none
            &--opened
                display: flex
                flex-direction: column
        .chevron
            width: 12px
            transition: transform 0.3s
            fill: #262e45
            margin-left: 3px
            &.chevron-open
                transform: rotate(180deg)
    .branding-input
        font-family: $InterFace
        width: 110px
        padding: 7px 16px 7px 14px
        border-radius: 5px
        background-color: #ffffff
        text-align: center
        height: 40px
    .form-name-container
        max-width: 78%
        display: grid
        grid-template-columns: 1fr 1fr
    .form-institution-name-container
        position: relative
        .form-item-input-counter
            position: absolute
            top: 3.2rem
            right: 10rem
            font-size: 1.6rem
            opacity: .66
    .form-item
        max-width: 85%
        &.name
            max-width: 100%
        &.email, &.mobile-number
            flex-flow: column
        &.tap-url
            margin: 0
        &.toggle
            padding-top: 1rem
            padding-bottom: 2.2rem
        &.conversation-section
            .ant-form-item-control
                flex-direction: column-reverse
            .conversation-section-error-message
                color: #ff4d4f
                .conversation-section-error-message-link
                    color: #ff4d4f
                    text-decoration: underline
                    &:hover
                        opacity: 0.66
        .ant-form-item-label
            font-family: $InterFaceBold
    .form-item-input
        max-width: 85%
        .ant-input-suffix
            position: absolute
            right: -5rem
    .form-item-toggle
        display: flex
        align-items: center
        gap: 10px
    .resend-sms-counter
        position: absolute
        width: 10rem
        left: -5.7rem
        top: 0.5rem
        opacity: .66

.preview
    width: auto
    height: 100%
    position: relative
    padding: 26px 50px 40px
    margin-bottom: 30px
    margin-left: 10px
    background-color: #ffffff
    box-shadow: 0 1px 3px 0 rgba(34, 34, 34, 0.16)
    &.preview-branding
        width: 680px
    .preview-title
        font-family: $InterFace
        font-size: 2rem
        line-height: 1.27
        letter-spacing: normal
        text-align: left
        color: #222222
        span
            &:nth-child(1)
                font-size: 2.2rem
                color: $taporange

            &:nth-child(2)
                position: relative
                padding-left: 8px
                white-space: pre
                &::before
                    position: absolute
                    content: "("
                    bottom: -0.4rem
                    left: 0
                    font-size: 2.4rem
                &::after
                    position: absolute
                    content: ")"
                    bottom: -0.4rem
                    font-size: 2.4rem

.preview-body
    width: 580px
    margin: 50px auto 25px
    text-align: center

.mt-60
    margin-top: 60px

.svg-wizard-slider
    .slick-slide
        height: auto
        min-height: 0
        padding: 1px
    button
        top: 40%
    .icon-slider-arrow
        width: 1.5rem
        height: 2rem
        fill: rgba(34, 34, 34, 0.4) !important
        &:hover
            fill: $taporange !important

    .preview-slide svg
        height: 70vh
        max-height: 500px
        min-height: 300px


.setup-organization-details, .setup-branding-page
    .setup-university-logo-block
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        margin-top: 4rem
        margin-bottom: 3rem
    .setup-university-logo-preview
        height: 4rem
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        cursor: pointer
        &:hover
            .edit-university-label.with-avatar
                opacity: 1
    .edit-university-block
        position: relative
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        overflow: hidden
        width: 9rem
        border-radius: 50%
    .edit-university-label
        position: absolute
        left: 0
        top: 0
        width: 9rem
        height: 9rem
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border-radius: 50%
        background-color: #666b80
        z-index: 2
        cursor: pointer
        transition: 0.3s
        opacity: 0
    .edit-university-input
        position: absolute
        width: 0
        height: 0
        left: 0
        top: 0
        visibility: hidden
    .edit-university-text
        color: #fff
        font-family: $InterFace
        font-size: 1.6rem
    .setup-university-logo-img
        width: 9rem
        height: 9rem
        border-radius: 50%
        &.setup-university-logo-img-empty
            display: flex
            align-items: center
            justify-content: center
            color: #fff
            font-size: 1.6rem
            background-color: #bababa

    .setup-start-block
        max-width: 900px
    .color-row
        display: grid
        grid-template-columns: auto auto 1fr

.preview-floor
    font-family: $InterFace
    font-size: 1.8rem
    line-height: 1.28
    letter-spacing: normal
    text-align: center
    color: #222222
    opacity: 0.8
    span
        font-family: $InterFaceBold

.policy-modal-body
    margin-top: 20px
    text-align: center

    .legal-title-floor
        margin: 26px 0 10px
        font-family: $InterFace
        font-size: 2.6rem
        line-height: 1.04
        letter-spacing: normal
        color: #222222
    .legal-text-floor
        font-size: 1.8rem
        line-height: 1.5
        letter-spacing: normal
        color: #222222
    .disabled-close-button
        width: 160px
        margin: 20px
        padding: 6px 25px
        border-radius: 5px
        font-weight: 600
        color: rgba(38, 46, 69, 0.5)
        background-color: rgba(218, 218, 218, 0.6)

.training-centre-page
    .setup-start-block
        max-width: 572px
    .preview
        min-width: 700px
        .preview-body
            width: 100%
            border: solid 1px #dfdfdf
            background-color: #fafafa
            margin-top: 10px
            padding: 32px 4px
    .training-centre-link-title
        margin-top: 0 !important
.invite-link-block
    display: flex
    align-items: center
    margin: 10px 0 16px
    .invite-training-link
        background-color: #fff
        border-radius: 5px
        border: solid 1px #dadada
        padding: 6px 16px
        margin-right: 10px
        word-break: break-word
    .copy
        cursor: pointer
        color: $taporange

.tip-block
    display: flex
    margin: 16px 0 50px
    &.branding-tip
        margin: 16px 0
    img
        width: 26px
        height: 26px
    span
        opacity: 0.7
        margin-left: 16px
        font-size: 1.6rem

.copy-template
    display: flex
    justify-content: flex-end
    margin-top: 8px
    cursor: pointer
    font-family: $InterFace
    font-size: 1.8rem
    line-height: 1.5
    letter-spacing: normal
    text-align: right
    color: $taporange

.preview-body
    .legal-text-setup
        width: 100%
        height: calc(100vh - 420px)
        min-height: 350px
        overflow-y: auto
        font-family: $InterFace
        font-size: 16px
        font-weight: normal
        font-stretch: normal
        font-style: normal
        line-height: 1.5
        letter-spacing: normal
        text-align: left
        color: #222222
    .legal-template-setup
        padding: 0 40px
        p
            font-size: 16px
        .legal-first-text
            margin-bottom: 30px
        .legal-forth-text
            margin: 26px 0
        .legal-option-text
            position: relative
            margin-left: 35px
            &:before
                content: ""
                position: absolute
                width: 8px
                height: 8px
                border-radius: 50%
                background-color: $taporange
                top: 9px
                left: -16px
    &.tap-page-preview-body
        width: 600px
        margin-top: 0 !important

.setup-university-input-block
    display: flex
    align-items: baseline
    span
        color: rgba(34, 34, 34, 0.6)
        font-size: 16px
.setup-university-input
    width: 450px
    background: #fff
    border: solid 1px #dadada
    border-radius: 5px
    padding: 0 1rem
    margin: 1rem 1rem 0 0
    font-size: 1.8rem
    font-style: normal
    font-stretch: normal
    line-height: 4rem
    letter-spacing: -.3px
    color: rgba(34, 34, 34, 0.6)

.start-guide-wizard-grid
    .branding-input
        font-family: $InterFace
        width: 110px
        padding: 7px 16px 7px 14px
        border-radius: 5px
        background-color: #ffffff
        text-align: center
        height: 40px

.mb-0
    margin-bottom: 0

.preview-search-form
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding-top: 1rem
    width: 100%
.preview-search-icon
    position: absolute
    left: 1rem
    height: 14px
    width: 14px
    fill: $taporange
.preview-search-input
    height: 4rem
    width: 100%
    border-radius: 2px
    border: solid 1px $taporange
    padding-left: 3.9rem
    line-height: 1
.preview-widget-head-filter-btn
    display: block
    line-height: 1.25
    height: 4rem
    width: 170px
    border-radius: 5px
    border: 1px solid #dadada
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: 500
    letter-spacing: normal
    text-align: center
    color: #ffffff
    box-sizing: border-box
    margin: 0 0 0 1rem
    background-color: $taporange
    padding: 6px 9px



.preview-slide
    width: 336px !important
    margin: auto
    &.preview-large-slide
        width: 100% !important

.tap-settings-step
    .preview
        min-width: 700px
        .preview-body
           text-align: unset
    .filter
        width: 500px !important
    .tap-tip-block
        margin-bottom: 32px !important
    .tap-configuration-text
        font-family: $InterFace
        font-size: 1.8rem
        font-weight: 600
        line-height: 1.5
        letter-spacing: normal
        margin: 32px 0 6px
        color: $taporange
    .tap-settings-name
        margin-top: 2.6rem
        margin-bottom: .3rem
        font-family: $InterFace
        font-size: 1.8rem
        font-weight: 600
        line-height: 1.5
        letter-spacing: normal
        text-align: left
        color: #222222
    .tap-settings-domain-names
        width: 500px
        .search-term
            font-size: 1.8rem !important
            word-break: break-all
        .domains-selector
            width: 100%
            font-family: $InterFace
            font-size: 1.8rem
            .ant-select-selector
                padding: 3px 32px 4px 10px
                .ant-select-selection-placeholder
                    left: 16px
                    right: 32px
    .tap-promotion-input
        width: 500px
        height: 40px
        font-family: $InterFace
        font-size: 1.8rem
        line-height: 40px
        padding: 7px 33px 7px 16px
        border-radius: 5px
        border: solid 1px #dadada
        background-color: #ffffff
    .tap-promotion-input-textarea
        width: 500px
        height: auto
        font-family: $InterFace
        font-size: 1.8rem
        padding: 7px 32px 7px 16px
        border-radius: 5px
        border: solid 1px #dadada
        background-color: #ffffff
        line-height: 1.28

    .tap-dropdown-filters, .tap-social-proof
        font-family: $InterFace
        font-size: 1.8rem
        font-weight: 600
        line-height: 1.5
        letter-spacing: normal
        color: $taporange
        margin: 32px 0
    .tap-social-proof
        margin: 32px 0 22px !important

    .preview-header-text
        margin-top: 50px
        font-family: $InterFace
        font-size: 2.5rem
        font-weight: 600
        line-height: 1.25
        letter-spacing: normal
        text-align: left
        color: #262e45
    .preview-header-text-small
        font-family: $InterFace
        font-size: 1.6rem
        line-height: 1.5
        letter-spacing: normal
        color: #262e45
        text-align: left
    .search-icon
        fill: $taporange
    .search-input-slide
        border-color: $taporange
    .widget-head-filter-btn
        background-color: $taporange
    .cards-title-left
        display: flex
        justify-content: space-between
        width: 100%
        margin: 23px 0 6px !important
        font-family: $InterFace
        font-size: 1.6rem
        line-height: 1.5
        letter-spacing: normal
        color: #262e45
        span:nth-child(1)
            width: 50%
            word-break: break-all
            text-align: left
    .profile-cards, .post-cards, .faq-cards
        display: inline-grid
        width: 100%
        grid-template-columns: 190px 190px 190px
        grid-gap: 1.5rem
        position: relative
        &::after
            content: ""
            background: url("../../../img/setup/arrow_orange.png") no-repeat 0 0
            background-size: 60px
            position: absolute
            height: 60px
            width: 60px
            right: 180px
            top: -20px
    .post-cards
        &::after
            right: 180px
            top: -20px
    .faq-cards
        grid-template-columns: 292px 292px !important
        margin-bottom: 25px
        &::after
            right: 210px
            top: -20px

    .title-input
        display: flex
        align-items: baseline
        span
            color: rgba(34, 34, 34, 0.6)
            font-size: 16px
            margin-left: 5px

.preview-filters
    display: grid
    grid-template-columns: 190px 190px 190px
    grid-gap: 1.5rem
    margin-top: 20px
    .filter-name
        font-family: $InterFace
        font-size: 1.4rem
        line-height: 1.5
        letter-spacing: normal
        color: #262e45

.setup-optional-data-page .setup-start-block
    min-width: 470px
.preview-optional-data
    max-width: 400px
    margin: 70px auto 70px
    border: 1px solid #bababa
    padding: 25px 40px 50px
    .optional-data-header-title
        margin: 25px 0 28px
        display: flex
        align-items: center
        svg
            transform: rotate(90deg)
            fill: $taporange
        span
            font-family: $InterFace
            font-size: 2rem
            line-height: 0.9
            letter-spacing: normal
            text-align: center
            color: #222222
            margin-left: 14px
    .step-block-title
        display: flex
        justify-content: center
        margin: 40px 0 26px
        .first-step, .second-step
            display: flex
            flex-direction: column
            align-items: center
        .register-step-circle
            position: relative
            width: 1.5rem
            height: 1.5rem
            border: 1px solid $taporange
            border-radius: 50%
        .first-step .register-step-circle::after
            content: ""
            background-color: rgba(0,0,0,0)
            position: absolute
            left: 4.2px
            top: 2px
            width: 4.5px
            border-bottom: 1.5px solid #ff5100
            height: 8px
            border-right: 1.5px solid #ff5100
            transform: rotate(45deg)
        .step-text
            opacity: .5
            font-family: $InterFace
            font-size: 14px
            font-weight: 400
            font-stretch: normal
            font-style: normal
            line-height: 1.29
            letter-spacing: normal
            text-align: center
            color: #222
        .steps-line
            margin-top: 7.5px
            width: 80px
            height: 1px
            background-color: $taporange

.preview-optional-questions
    margin-top: 50px
    .preview-question-title
        font-family: $InterFace
        font-size: 14px
        font-weight: normal
        line-height: 1.5
        letter-spacing: normal
        text-align: left
        color: #222222
        margin: 20px 0 2px
    .date-button, .boolean-button
        padding: 6px 12px
        border: 1px solid #bababa
        border-radius: 5px
        font-size: 14px
        margin-right: 10px
        margin-bottom: 5px
.dropdown-custom
    font-size: 14px
    position: relative
    cursor: pointer
    margin: 0

    .input-wrapper
        display: flex
        justify-content: space-between
        align-items: center
        box-sizing: border-box
        padding: 0 12px 0 10px
        height: 4rem
        border-radius: .5rem
        border: solid 1px #dadada
        transition: all 0.3s 0.3s

        .chevron
            width: 14px
            transition: transform 0.3s

    .dropdown-input
        font-family: $InterFace
        font-size: 14px
        border: none
        width: 100%
        height: 100%
        font-weight: 400
        font-style: normal
        font-stretch: normal
        line-height: normal
        letter-spacing: -.3px
        color: #222
        cursor: pointer

.modal-fake-input
    width: 100%
    height: 32px
    border: 1px solid #bababa
    border-radius: 5px

.preview-start-conversation-button
    margin: 50px auto 30px
    padding: 9px 19px 8px 20px
    display: flex
    width: 220px
    justify-content: center
    border-radius: 4px
    background-color: $taporange
    color: #ffffff
    font-family: $InterFace
    font-size: 14px

.social-proof-floor-text
    font-size: 1.6rem
    font-weight: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    margin: 26px 0

.joined-social-text
    display: flex
    font-size: 1.6rem
    font-weight: normal
    line-height: 1.25
    letter-spacing: normal
    .user-social-icon
        margin: 0 2px 0 5px
        display: flex
        align-items: center
    svg
        width: 9px
        path
            fill: $taporange !important
    span
        color: $taporange
        font-family: $InterFaceBold
        padding-right: 4px
.input-error-message
    font-family: 'Source Sans Pro'
    font-size: 1.8rem
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: right
    color: #b30000
    &.color-error-message
        margin-left: 10px
        text-align: left
    &.w-450
        width: 450px

.modal-welcome
    text-align: center
    padding: 58px 33px 70px
    .close-modal-internal
        position: absolute
        top: 20px
        right: 20px
        font-size: 2.2rem
        line-height: 1
        svg
            opacity: 0.5
            fill: #000000
    .image-welcome
        padding-bottom: 29px
    .welcome-text
        font-size: 1.8rem
        line-height: 1.5
        letter-spacing: normal
        color: rgba(38, 46, 69, 0.8)

.edit-url-button
    width: 20px
    margin-left: 20px
    font-size: 1.6rem
    color: #ff5100
    display: flex
    align-self: flex-end

.conversation-section-error-message
    -webkit-animation-duration: 0.3s
    animation-duration: 0.3s
    -webkit-animation-fill-mode: both
    animation-fill-mode: both
    -webkit-animation-play-state: paused
    animation-play-state: paused
    -webkit-animation-name: animate_error_message
    animation-name: animate_error_message
    -webkit-animation-play-state: running
    animation-play-state: running
    opacity: 0
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1)
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1)

.pls-onboarding-modal-content
    padding-top: 1rem
    .steps-content-container
        min-height: 40rem
        padding-top: 6rem
        padding-bottom: 1rem
        display: flex
        justify-content: center
        .steps-content
            font-size: 1.6rem
            .steps-content-title
                margin-bottom: 2rem
            .steps-content-description
                margin-bottom: 2rem
            .setup-university-logo-block
                display: flex
                flex-direction: row
                justify-content: center
                align-items: center
                margin-top: 4rem
                margin-bottom: 3rem
            .setup-university-logo-preview
                height: 4rem
                display: flex
                flex-direction: row
                justify-content: flex-start
                align-items: center
                cursor: pointer
                &:hover
                    .edit-university-label.with-avatar
                        opacity: 1
            .edit-university-block
                position: relative
                display: flex
                flex-direction: row
                justify-content: flex-start
                align-items: center
                overflow: hidden
                width: 15rem
                border-radius: 50%
            .edit-university-label
                position: absolute
                left: 0
                top: 0
                width: 15rem
                height: 15rem
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                border-radius: 50%
                background-color: #666b80
                z-index: 2
                cursor: pointer
                transition: 0.3s
                opacity: 0
            .edit-university-input
                position: absolute
                width: 0
                height: 0
                left: 0
                top: 0
                visibility: hidden
            .edit-university-text
                color: #fff
                font-family: $InterFace
                font-size: 1.6rem
            .setup-university-logo-img
                width: 15rem
                height: 15rem
                border-radius: 50%
                &.setup-university-logo-img-empty
                    display: flex
                    align-items: center
                    justify-content: center
                    color: #fff
                    font-size: 1.6rem
                    background-color: #bababa
            .qr-code-container
                display: grid
                grid-template-columns: 1fr 1fr 1fr
            .email-and-sms-invitation-container
                .email-and-sms-invitation-button
                    font-family: $InterFace
                    color: #000
                    font-size: 1.6rem
                    padding: 0
                    opacity: .66
                    span
                        text-decoration: underline
                .email-and-sms-invitation-fields
                    display: none
                    &--opened
                        display: flex
                        flex-direction: column
                .chevron
                    width: 12px
                    transition: transform 0.3s
                    margin-left: 3px
                    &.chevron-open
                        transform: rotate(180deg)
                    path
                        fill: #262e45
            .credential-item
                font-family: $InterFaceBold
                font-size: 1.6rem
                margin-top: 1rem
                margin-bottom: .3rem
                span
                    margin: 0
                    opacity: .66
                    font-family: $InterFace
                    font-weight: normal
        .form-item
            &.activate-ambassador
                .ant-form-item-control-input
                    min-height: 0
            .form-item-toggle
                display: flex
                align-items: center
                gap: 10px
            .resend-sms-counter
                position: absolute
                width: 10rem
                left: 0.3rem
                top: 0.5rem
                opacity: .66
            .edit-url-link
                color: #ff5100
                opacity: 1
                text-decoration: underline
                font-size: 1.6rem
                &:hover
                    opacity: 0.5
    .steps-action
        display: flex
        justify-content: center
        padding-bottom: 2rem

@-webkit-keyframes animate_error_message
    0%
        -webkit-transform: translateY(-5px)
        transform: translateY(-5px)
        opacity: 0
    100%
        -webkit-transform: translateY(0)
        transform: translateY(0)
        opacity: 1

@keyframes animate_preloader
    0%
        -webkit-transform: translateY(-5px)
        transform: translateY(-5px)
        opacity: 0
    100%
        -webkit-transform: translateY(0)
        transform: translateY(0)
        opacity: 1