// chat page
//-----------------------------------------------------------------------------------

.individual-chats
  .chat-head
    padding: 2rem 3.1rem 1.2rem

.content
  &.mod-chat
    padding: 0

.chat
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  height: 100vh
  width: 100%
  box-sizing: border-box
  background-color: #fff
  overflow: auto

.chat-nav-img
  object-fit: cover
  height: 100%
  width: 100%
  position: absolute

.chat-nav-info
  max-width: 65%

.chat-nav-img-block
  border-radius: 50%
  margin-right: 1.1rem
  width: 5.5rem
  height: 5.5rem
  overflow: hidden
  position: relative


.chat-nav-head
  display: flex
  justify-content: space-evenly
  font-family: $InterFace
  margin: 3rem 2.3rem 2.3rem
  border-bottom: solid 1px rgba(38, 46, 69, 0.1)
  .head-item
    cursor: pointer
    border-bottom: 2px solid transparent
    width: 50%
    text-align: center
    padding-bottom: 0.5rem
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    letter-spacing: normal
    &.head-item-active
      cursor: default
      font-family: $InterFaceBold
      color: #ff5100
      border-bottom: 2px solid #ff5100
      margin-bottom: -1px
      font-weight: bold

.chat-nav-msg-counter
  font-family: $InterFaceBold
  font-size: 1.5rem
  font-weight: bold
  font-style: normal
  font-stretch: normal
  line-height: 2.5rem
  letter-spacing: -0.2px
  text-align: center
  color: #ffffff
  width: 2.5rem
  height: 2.5rem
  background-color: #ff5100
  border-radius: 50%
  float: right
  @media (max-width: 1100px)
    width: 2rem
    height: 2rem
    line-height: 2rem
    margin-top: 1rem

  &.mod-messaging
    .chat-block
      justify-content: space-between
      align-items: flex-start
      position: relative

    .chat-nav-name
      span
        font-size: 1.5rem
        font-family: $InterFace
        font-weight: normal

    .chat-nav-text
      font-family: $InterFace
      font-size: 1.4rem
      font-weight: normal
      font-style: normal
      font-stretch: normal
      line-height: normal
      letter-spacing: -0.2px
      color: #8f8e94
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 80%

    .chat-head
      height: 9.3rem

    .chat-name-img
      border-radius: 50%
      margin-right: 2rem

    .chat-name
      &:hover
        background-color: $tapgrey
        border-color: $tapgrey
        opacity: 1
        color: #fff

    .chat-input
      width: calc(100% - 7rem)
      height: 5.5rem
      border-radius: 2.74rem
      border: solid 0.14rem #eaeaea

    .chat-sbm
      width: 5.5rem
      height: 5.5rem

    .chat-body
      height: calc(100vh - 14.8rem)

    .msg-item-img
      max-width: 150%
      max-height: 150%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    .msg-item
      max-width: 90rem

      &.mod-my
        margin-top: 2rem

        .msg-block-item
          margin-right: 1.2em
          margin-left: 0
          background-color: #e2ffc7
          border-color: #e2ffc7
          padding-top: 1.2rem
          padding-bottom: 1.2rem

          &:after
            display: none

          &:before
            clip-path: polygon(0 0, 0% 100%, 100% 50%)
            right: -1.2rem
            left: auto
            background-color: #e2ffc7

    .msg-name
      font-size: 1.4rem

    .msg-text
      a
        font-family: $InterFaceBold
        font-weight: bold
        color: $taplightorange
        text-decoration: underline

        &:hover
          text-decoration: none

    .msg-controls
      justify-content: flex-end

    .chat-name-block
      margin-right: 2rem

.chat-name-block--chat
  display: flex
  align-items: center

.chat-nav-first-item
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  box-sizing: border-box
  background-color: #f9f9f9
  border-bottom: 0.1rem solid rgba(#979797, 0.2)
  height: 8rem

  .el-btn-outline
    margin-bottom: 0
    border-radius: 0
    width: 100%
    height: 100%
    line-height: 9.1rem
    text-align: center
    box-sizing: border-box
    padding: 0
    background-color: $taplightorange
    border: 0.1rem solid $taplightorange

    &:hover
      color: #fff

.chat-nav
  width: 42.1rem
  box-sizing: border-box
  height: 100vh
  border-left: 0.1rem solid rgba(#979797, 0.2)
  overflow: auto

  @media (max-width: 1100px)
    width: 33.1rem

.no-dialog-found
  font-family: $InterFace
  font-size: 1.8rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  text-align: center
  margin-top: 12%
  line-height: 1.15

.chat-nav-list
  overflow: auto

.chat-block
  opacity: 1
  height: 100%
  width: calc(100% - 42.1rem)
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center

  @media (max-width: 1100px)
    width: calc(100% - 33.1rem)

  .modal-title
    margin-top: 0.5rem
    letter-spacing: -0.5px

  .icon-chat
    width: 8rem
    height: 7rem
    fill: $tapgrey
    margin-top: calc(50vh - 7rem)

.chat-loader-block
  width: 100% !important

.chat-nav-img
  object-fit: cover
  height: 100%
  width: 100%
.chat-nav-info
  max-width: 18rem
.chat-nav-img-block
  width: 5.5rem
  min-width: 5.5rem
  height: 5.5rem
  border-radius: 50%
  margin-right: 1.1rem
  position: relative
  overflow: hidden

.chat-nav-item
  position: relative
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  box-sizing: border-box
  width: 100%
  padding: 2.3rem 2.3rem 0.2rem 2.3rem
  cursor: pointer
  transition: 0.3s
  &.admin-involved
    &:after
      content: ""
      position: absolute
      height: 70%
      width: 0.3rem
      left: 1rem
      bottom: 0
      background-color: #ff5100
      transition: 0.3s

  &:before
    content: ""
    position: absolute
    height: 0.1rem
    width: calc(100% - 4.6rem)
    right: 2.5rem
    bottom: 0
    background-color: rgba(#979797, 0.2)
    transition: 0.3s

  &:hover
    background-color: #f9f9f9

    &:before
      opacity: 0

  &.is-active
    background-color: #f9f9f9

    &:before
      opacity: 0

.chat-nav-name
  font-family: $InterFaceBold
  font-size: 1.6rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.04rem
  color: $dark
  margin-bottom: 0.3rem
  text-transform: capitalize

  &.chat-nav-name-messaging, &.chat-nav-name-group-messaging
    display: flex
    align-items: center
    span
      line-height: 1
  &.chat-nav-name-messaging
      svg
          margin-left: 8px
          width: 15px
  &.chat-nav-name-group-messaging
      svg
          margin-left: 7px
          width: 18px
          height: 19px

  @media (max-width: 1100px)
    font-size: 1.6rem

.chat-nav-name-container
  display: flex
  flex-direction: row
  align-items: baseline
.chat-blocked-button
  margin-left: 0.4rem
  color: #dc2e2e
  cursor: pointer
.chat-blocked-button-chat
  margin-left: 0.7rem
  color: #dc2e2e
  min-width: 10rem
  font-size: 1.2rem
  cursor: pointer
  svg
    margin-right: 0.2rem

.chat-blocked-content
  max-height: 500px
  &.overflow
    overflow-y: scroll

.chat-reported-button
  color: #dc2e2e
  min-width: 10rem
  cursor: pointer


.chat-nav-sa
  font-family: $InterFace
  padding-bottom: 0.3rem
  .chat-nav-sa-and
    font-size: 1.5rem
    padding-right: 5px
  .chat-nav-sa-name
    opacity: 0.8
    letter-spacing: -0.04rem
    text-transform: capitalize
    font-size: 1.6rem
    font-style: normal
    font-stretch: normal
    line-height: normal

.chat-nav-text
  @media (max-width: 1100px)
    font-size: 1.2rem
.group-chats
  .chat-nav-item
    padding: 2.3rem
  .chat-nav-text
    font-size: 1.4rem
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    color: #4c4c4d

.chat-nav-date
  text-align: right
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: 300
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  opacity: 0.5
  margin-bottom: 0.1rem

  @media (max-width: 1100px)
    font-size: 1.2rem

.chat-nav-data
  display: flex
  flex-direction: column
  margin-left: auto
  margin-top: auto
  width: 10.1rem
  align-items: center

.topic-item-popover
  max-width: 330px
  .ant-popover-arrow
    display: none
  .topic-item-popover-body
    font-size: 1.6rem
    padding: 20px
    letter-spacing: normal
    .topic-feedback-buttons
      display: flex
      justify-content: space-evenly
    button
      margin-top: 16px
      &:hover
        color: $taporange
.chat-topics-block
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin: 0 10px
  max-height: 60px
  overflow-y: auto
  .topic-item
    display: flex
    align-items: center
    margin-right: 21px
  .topic-item-text
    margin-right: 5px
    text-underline: #222222
    text-decoration-line: underline
    text-decoration-thickness: from-font
    cursor: pointer
    &.topic-item-confirmed
      color: $taporange
    &.topic-item-removed
      opacity: 0.5
      text-decoration-line: none
    &.topic-item-new
      &:before
        content: ""
        position: absolute
        width: 5px
        height: 5px
        top: 1px
        left: -5px
        border-radius: 50%
        background-color: $taporange

.chat-topics
  display: flex
  justify-content: space-between
  width: 100%
  padding: 1.5rem 3.1rem
  .chat-head-left
    flex: 1 1 100px
    align-items: baseline !important
  .chat-topics-title
    font-weight: 600
    letter-spacing: normal
  .add-topic-block
    display: flex
    cursor: pointer
    img
      width: 14px
      margin-right: 6px

.modal-topics
  margin: 6px 16px 0
  .add-topics-block
    display: flex
    flex-direction: column
  .topics-modal-title
    font-size: 2.1rem
    font-weight: 600
    line-height: 1.14
    letter-spacing: normal
    margin-bottom: 8px
    text-align: center
  .topics-modal-text
    font-size: 1.6rem
    line-height: 1.5
  .topics-input-title
    font-size: 1.6rem
    font-weight: 600
    line-height: 1.25
    letter-spacing: normal
    margin: 16px 0 4px
  .topic-error
    font-family: $InterFace
    line-height: 1.25
    letter-spacing: normal
    text-align: right
    color: #fe3057
  .save-topics-btn
    width: 162px
    background-color: #0a2540
    border: 0
    color: #ffffff
    padding: 9px 0
    border-radius: 4px
    margin: 48px auto 0

.topic-delete
  width: 9px
  cursor: pointer
  fill: #222222
.delete-topic-modal
  font-size: 1.6rem
  font-family: $InterFace
  line-height: 1.5
  letter-spacing: normal
  padding: 30px 40px
  p
    margin-top: 10px
  svg
    position: absolute
    width: 16px
    right: 15px
    top: 15px
    fill: rgba(34, 34, 34, 0.7)
    cursor: pointer
.topic-delete-buttons
  margin-top: 32px
  display: flex
  justify-content: space-between
  button
    border-radius: 4px
    background-color: #262e45
    color: #ffffff
    width: 150px
    padding: 9px 12px
    line-height: 1.25
    letter-spacing: normal
    text-align: center

.topics-select
  border: solid 1px #dbdbdb
  border-radius: 3px
  .ant-select-selector
    padding: 7px 10px
    font-size: 1.6rem
    font-family: $InterFace
    input
      margin-left: 0 !important
    .ant-select-selection-item
      border-radius: 12px
      background-color: #262e45
      color: #ffffff
      opacity: 0.8
      padding: 0 8px
      font-size: 1.4rem
    .ant-select-selection-item-remove
      color: #ffffff !important
      &:hover
        color: $taporange !important

.topics-dropdown
  padding-top: 12.5px
  .ant-select-item
    padding: 0 60px 0 20px
    &:not(:last-of-type) .ant-select-item-option-content
      border-bottom: solid 1px rgba(34, 34, 34, 0.1)
  .ant-select-item-option-content
    padding: 7.5px 0 !important
    font-family: $InterFace
    font-size: 1.6rem
    line-height: 1.25
    letter-spacing: normal
    color: #262e45

.chat-head
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  border-bottom: solid 0.1rem rgba(#979797, 0.2)
  width: 100%
  padding: 2rem 3.2rem
  @media (max-width: 1000px)
    flex-direction: column
    align-items: flex-start

.chat-back-btn
  color: #b3b3b3
  width: 0.8rem
  height: 1.6rem
  margin-right: 2rem

.chat-name-img-block
  width: 5.5rem
  min-width: 5.5rem
  height: 5.5rem
  border-radius: 50%
  margin-right: 1.1rem
  position: relative
  overflow: hidden

.chat-name-img
  object-fit: cover
  height: 100%
  width: 100%
  position: absolute

.general-popup.chat-mute-popup
  background-color: #ffffff
  padding: 30px 50px
  text-align: left
  .modal-title
    text-align: left
    margin-bottom: 16px
  .modal-body
    font-size: 1.6rem
  .confirm-mute-buttons
    text-align: center
    margin-top: 32px
    button
      width: 110px
      min-width: 110px
      margin-top: 0
      padding: 1rem 0
.general-popup.chat-mute-success-popup
  .modal-body
    font-size: 2.1rem

.chat-head-left
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

  @media (max-width: 1000px)
    margin-bottom: 15px

.chat-name
  font-family: $InterFaceBold
  font-size: 1.8rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  text-transform: capitalize

  @media (max-width: 1200px)
    font-size: 1.6rem

.chat-members
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark

.chat-sa-number
  margin-left: 10px
  letter-spacing: normal
  font-size: 1.6rem
  font-family: 'Source sans pro'
  line-height: 1.25

.chat-head-right
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-start
  flex-wrap: wrap

.chat-btn
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  text-align: center
  color: $dark
  width: 11.6rem
  height: 2.2rem
  border-radius: 0.3rem
  border: solid 0.06rem #b3b3b3
  margin-right: 1rem
  transition: 0.3s

  &:hover
    background-color: $tapgrey
    border-color: $tapgrey
    opacity: 1
    color: #fff

.close-chat-btn
  font-family: $InterFace
  margin-top: 5px
  cursor: pointer

  &:hover
    opacity: 0.66

  &__icon
    margin-right: 6px
    vertical-align: sub

a.chat-btn
  line-height: 2.3rem
  text-decoration: none

.unflag-button
  color: #fe3057
  border: 1px solid #fe3057
  padding: .5rem 1rem
  border-radius: .3rem
  margin-left: 1rem

.chat-status
  font-family: $InterFace
  font-size: 1.5rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  opacity: 0.7

  &.chat-messaging-status
    display: flex
    align-items: center
    line-height: 1.4
    svg
      width: 14px
      margin-right: 8px

  &--participants
    font-size: 1.2rem

    @media (max-width: 1000px)
      display: none

.chat-footer
  width: 100%
  height: auto
  background-color: #f9f9f9
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  padding: 1.5rem 1.3rem 1.5rem 2.2rem
  box-sizing: border-box
  position: relative
  &.prospect-sa-chat
    margin-top: 2rem

.flagged-footer-block
  position: absolute
  display: flex
  width: 100%
  margin-left: -2.3rem
  background-color: #fff
  .chat-footer-flaged
    margin: auto
    padding-bottom: 20px

.chat-form
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  width: 100%

.chat-input
  width: calc(100% - 12.2rem)
  height: 3.5rem
  border-radius: 2.74rem
  border: solid 0.14rem #eaeaea
  background-color: #ffffff
  padding-left: 1rem
  font-family: $InterFace
  font-size: 1.8rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  color: $tapgrey
  box-sizing: border-box
  margin-right: 1.7rem

  &::placeholder
    color: rgba(0,0,0,.5)

.chat-file-icon
  display: block
  width: 4rem
  height: 4rem
  color: $taporange
  transition: all .2s
  &:hover
    opacity: .66

  .icon-photo
    width: 3.6rem
    height: 4rem

.chat-file-label
  position: relative
  margin-right: 1.8rem
  overflow: hidden
  cursor: pointer

.chat-file-input
  position: absolute
  left: 0
  top: 0
  opacity: 0
  &.content-upload
    display: none

.chat-sbm
  width: 4.5rem
  height: 4.5rem
  background-color: #b3b3b3
  border-radius: 50%
  color: #fff
  cursor: pointer
  transition: all .2s
  &:hover:not(:disabled)
    background-color: $taporange
    opacity: 1
  &.disabled:hover
    background-color: #b3b3b3 !important
    cursor: default !important
    .icon-send
       cursor: default !important
  &:disabled
    cursor: default

  .icon-send
    width: 3rem
    height: 2.6rem
    cursor: pointer

.chat-body
  height: 100%
  width: 100%
  box-sizing: border-box
  padding: 3.3rem 6rem 3.3rem 6rem
  overflow: auto

.chat-body-list
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start

.msg-item-img
  object-fit: cover
  margin-right: 10px
  border-radius: 50%
  height: 4rem
  width: 4rem

  &--participants
    margin: 0
    width: 2.5rem

.msg-avatar-text
  font-family: $InterFaceBold
  background-color: #ebebeb
  border-radius: 50%
  color: $tapgrey
  height: 3.6rem
  width: 3.6rem
  line-height: 3.6rem
  text-align: center
  text-transform: uppercase
  margin-right: 10px

  &--chat
    flex-shrink: 0

.msg-item
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-end
  max-width: 53rem
  margin-bottom: 2.8rem
  margin-top: 2rem
  position: relative

  &:last-child
    margin-bottom: 0

  &.mod-my
    flex-direction: row-reverse
    margin-left: auto

    .msgs-block
      margin-left: 0
      margin-right: 0.6rem

    .msg-block-item
      margin-right: 1.2em
      margin-left: 0
      background-color: #e2ffc7
      border-color: #e2ffc7
      padding-top: 1.2rem
      padding-bottom: 1.2rem

      &:after
        display: none

      &:before
        clip-path: polygon(0 0, 0% 100%, 100% 50%)
        right: -1.2rem
        left: auto
        background-color: #e2ffc7

.msg-name
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: #9b9b9b

  span
    font-family: $InterFace
    font-weight: normal

.msg-post-caption
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-top: 1.5rem
  margin-bottom: 0.5rem

.msg-text
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  word-wrap: break-word
  white-space: pre-wrap
  a
    text-decoration: underline

  .bad-word
    color: red !important
  &.hidden
    font-style: italic

.msgs-block
  margin-left: 0.6rem
  max-width: 40rem
  min-width: 30rem
  &.blocking-control
    min-width: 45rem
    max-width: 45rem


.msg-block-item
  border-radius: 0.9rem
  border: solid 0.13rem #eaeaea
  background-color: #ffffff
  margin-left: 1.2rem
  padding: 0.8rem 1.5rem
  position: relative
  margin-bottom: 1rem
  .search-word
    color: $taporange !important
  &:last-child
    margin-bottom: 0
  &.blocked
    border-color: #dc2e2e

.msg-controls
  width: 100%
  position: absolute
  right: 0
  top: calc(100% + 0.6rem)
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  box-sizing: border-box

  &__wrapper
    font-size: 1.2rem
    line-height: normal
    color: $dark
    display: flex
    align-items: center
    cursor: pointer

  &--to-left
    justify-content: space-between

  &__view-icon-hidden
    width: 30px

  &__view-icon-visible
    width: 22px
    margin: 0 4px 0 4px
    opacity: 0.5

  &__text
    opacity: 0.5

    &--visible
      color: red

.reply-and-delete-container
  display: flex
  flex-direction: row
  width: 100%

.msg-time, .reply-button, .delete-button, .unblock-button, .block-button
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: 300
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  opacity: 0.5
  display: flex
  align-items: center
  &.prospect-popup
    font-size: 1.5rem
  &:hover
    color: $taporange

.block-prospect-msg
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: 300
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  display: flex
  align-items: center
  width: 100%
  justify-content: flex-end
  color: #dc2e2e

.msg-time
  white-space: nowrap
.reply-button
  cursor: pointer
  margin-left: 1rem
  margin-right: 1rem

.block-button, .unblock-button
  cursor: pointer
  margin-left: 1rem
  margin-right: 1rem

.block-button
  color: #dc2e2e
  margin-right: auto
  opacity: 1
  &.prospect-popup
    text-decoration: underline
    &:hover
      opacity: 0.5

.delete-button
  cursor: pointer
  margin-right: 1rem

.reply-item
  background-color: #f9f9f9
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  width: 100%
  padding: 0 1.5rem
  box-sizing: border-box

.reply-item .msg-avatar-block
  position: relative
  width: 2rem
  min-width: 2rem
  height: 2rem
  margin-bottom: auto
  margin-top: 1.5rem
  border-radius: 50%
  overflow: hidden
  text-align: center
  &.custom-color
    background-color: #ebebeb
.reply-item .msg-block-reply-item
  margin-left: 0
  position: relative
  width: 100%
  border: none
  .msg-name
    color: #ff5100
.reply-close
  width: 18px
  height: 18px
  cursor: pointer
  fill: rgba(34, 34, 34, 0.6)
    
.msg-parent
  border-left: 2px solid #222222
  padding-left: 10px
  margin: 8.5px 0 8.5px 10px
  .msg-txt
    color: rgba(34, 34, 34, 0.7)

.msgs-block
  &.mod-media
    .msg-block-item
      padding-right: 0.6rem
      padding-bottom: 0.6rem
      padding-left: 0.6rem

    .msg-name
      margin-bottom: 0.6em

.msg-controls-left
  margin-right: auto
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

.msg-control-link
  display: block
  margin-right: 0.5rem
  opacity: 0.5
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark

  &:hover
    text-decoration: none
    color: #222
    opacity: 0.66

.msg-avatar-block
  position: relative
  min-width: 3.6rem
  width: 3.6rem
  height: 3.6rem
  border-radius: 50%
  overflow: hidden
  &.msg-prospect-avatar
    cursor: pointer

.new-messages-button
  position: fixed
  left: 40%
  bottom: 7rem
  z-index: 9
  transform: translateX(-50%)
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  color: #fff
  padding: 0.7rem
  border-radius: 0.5rem
  background-color: $tapgrey

.chat-video-container
  position: relative
  padding-top: 100%
  border-radius: 0
  display: block
  cursor: pointer
  overflow: hidden
  transition: 0.3s
  min-width: 37rem

  &:before
    content: ""
    position: absolute
    width: 0
    height: 0
    border-top: 2.3rem solid transparent
    border-left: 4.6rem solid #fff
    border-bottom: 2.3rem solid transparent
    bottom: 2rem
    right: 2rem
    z-index: 2

.chat-video
  position: absolute
  top: 0
  left: 0
  object-fit: cover
  height: 100%
  width: 100%
  flex-grow: 0
  border-radius: 0

.prospect-table-head
  display: block
  position: relative
  width: 100%

.title-container
  display: flex
  justify-content: space-between
  align-items: center

.prospect-table-title
  font-size: 2.4rem
  font-weight: 900
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-bottom: 2.4rem

  & > span
    font-family: $InterFaceBold
  &.beta-version
        position: relative
        width: fit-content
        .beta-mark
            font-size: 14px
            color: #ff5800
            position: absolute
            right: -33px
            top: 0


.prospect-table-head-container
  display: flex
  flex-direction: row
  background: #fff
  padding: 2rem
  margin-bottom: 2rem
  border-radius: 4px
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  @media (max-width: 1100px)
    flex-direction: column
    justify-content: flex-start

.prospect-table-head-filter
  background: #fff
  padding: 2rem
  border-top-right-radius: 4px
  border-top-left-radius: 4px
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  margin: 0

.prospect-table-wraper
  background: #fff
  padding: 2rem
  border-bottom-right-radius: 4px
  border-bottom-left-radius: 4px
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
  height: auto
  overflow-x: auto
  margin-top: 0

.export-buttons-container
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-end
  & > button
    margin-right: 8px
    width: 160px
    height: 32px
    padding: 0px 0px
  @media (max-width: 1100px)
    justify-content: flex-start
    width: auto
  @media (max-width: 459px)
    flex-direction: column

.prospect-table-head-title-container
  display: flex
  flex-direction: column

.prospect-table-row
  display: block
  text-align: left
  margin: 1rem 0

  & > button
    display: inline-block
    vertical-align: middle
    margin-right: 1rem

.simple-dark-btn
  border: 1px solid $tapgrey
  font-size: 1.4rem
  padding: 1rem 2rem
  border-radius: .3rem
  transition: all 0.2s linear

  &:hover
    color: #fff
    background: $tapgrey

.prospect-table-datepicker
  display: inline-block
  transform: scale(0)
  transform-origin: 0 50%
  transition: all 0.1s linear
  margin-left: 1rem

  @media (max-width: 1100px)
    margin-top: 2rem

.toggle-filter-btn
  font-size: 1.4rem
  color: #909090
  border-color: #909090

  @media (max-width: 1100px)
    display: block
    margin-left: 0

.prospect-table-br
  display: none

  @media (max-width: 1100px)
    display: block

.prospect-table-head.active
  .prospect-table-datepicker
    transform: scale(1)

  .toggle-filter-btn
    color: #ffffff
    border-color: $taporange
    background-color: $taporange

.prospect-table-container
  transition: all 0.2s linear

.prospect-table-container-slim
  width: calc(100% - 400px)

.react-datepicker-popper
  z-index: 3

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name
  font-size: 1.1rem
  width: 3rem
  line-height: 3rem
  color: #5a626c

.react-datepicker-wrapper
  margin-right: 2rem

.react-datepicker__month-container
  font-family: $InterFace

.react-datepicker__day--in-range
  background: #ffe3ba
  transition: all .2s

.react-datepicker__day--in-range:hover
  background: #ffe3ba
  color: $tapgrey

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selected
  color: #ffffff
  background-color: $taporange
  font-family: $InterFaceBold

.react-datepicker__day--selecting-range-start
  color: #ffffff
  background-color: $taporange

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)
  background-color: #ffe3ba

.react-datepicker__day--keyboard-selected
  font-family: $InterFaceBold
  background-color: $taporange
  color: #fff

.react-datepicker__current-month, .react-datepicker-time__header
  font-size: 1.4rem
  color: #5a626c
  font-weight: 400

.react-datepicker__header
  background: none

.react-datepicker__navigation--previous, .react-datepicker__navigation--next
  top: 13px

.react-datepicker__day-names
  border-top: 1px solid #aeaeae
  margin-top: 1rem

.react-datepicker__input-container input
  text-align: left
  padding-left: 0.2rem
  height: 3.9rem

.react-datepicker__close-icon
  top: 50%
  transform: translateY(-50%)
  margin-right: 0
  &::after
    background-color: $taporange

.widget-setting-toggle
  display: flex
  margin: 3rem 0

  .radio-btn
    margin-right: 1rem

    span
      margin-left: 0.5rem
      display: inline-block
      vertical-align: middle

.widgets-setting-info *
  font-family: $InterFace

.widget-setting-title
  font-size: 2.1rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.2px
  color: $dark
  margin-bottom: 2.4rem

.widget-setting-p
  font-size: 1.6rem
  color: $dark
  font-weight: 100
  margin: 1rem 0 2rem

.widgets-setting-users
  padding: 1rem
  background: #fff
  border-radius: 1px
  border: solid 0.6px #dadada
  max-width: 600px
  width: 600px

  .select-all
    margin-top: 1rem
    margin-bottom: 0.5rem
    margin-right: 1rem
    font-size: 1.4rem
    text-align: right
    &:hover
      cursor: pointer
      text-decoration: none

.chats-row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: flex-start
  flex-wrap: wrap
  margin: 0 -15px 50px

  @media (max-width: 767px)
    flex-wrap: wrap
    margin-bottom: 20px

  &.mod-page
    flex-wrap: wrap
    justify-content: flex-start

    .chats-item
      margin-bottom: 30px

.widget-users-carusel
  width: 90%
  margin: 20px auto 50px

  @media (max-width: 767px)
    flex-wrap: wrap
    margin-bottom: 20px

  &.mod-page
    flex-wrap: wrap
    justify-content: flex-start

    .chats-item
      margin-bottom: 30px

.chats-item
  max-width: 350px
  min-height: 490px
  border-radius: 7px
  border: solid 0.6px #dadada
  background-color: #ffffff
  box-sizing: border-box
  padding: 10px 15px 19px
  margin: 0 15px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center

  .flag-icon-container
    width: 32.8px
    height: 32.8px
    border: 1px solid #fff
    box-sizing: border-box
    position: absolute
    right: 4.4px
    bottom: 9.5px
    z-index: 2
    border-radius: 50%
    overflow: hidden
    text-align: center

    .flag-icon
      max-height: 120%
      max-width: 150%
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)

  @media (max-width: 991px)
    margin: 0

  @media (max-width: 767px)
    max-width: 100%
    margin: 0 auto

.chat-online
  font-family: $InterFace
  font-size: 10px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.4
  letter-spacing: -0.2px
  color: #a6a6a6
  margin-bottom: 1.4px
  margin-right: auto

.chat-img
  object-fit: cover
  height: 100%
  width: 100%

.chat-img-block
  position: relative
  width: 129.2px
  height: 129.2px
  border-radius: 50%
  overflow: hidden

.chat-img-container
  position: relative
  width: 129.2px
  height: 129.2px
  margin: 0 auto 4.5px

.chat-img-country
  width: 32.8px
  height: 32.8px
  border: solid 1px #ffffff
  box-sizing: border-box
  position: absolute
  right: 4.4px
  bottom: 9.5px
  z-index: 2
  border-radius: 50%

.chat-item-name
  font-family: $InterFace
  font-size: 24px
  font-weight: bold
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.5px
  text-align: center
  color: #1f1646

.chat-item-study
  font-family: $InterFace
  font-size: 12px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.33
  letter-spacing: -0.3px
  text-align: center
  color: $tapgrey
  margin-bottom: 4px

.chat-education-row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  margin-bottom: 12px

.chat-education-text
  font-family: $InterFace
  font-size: 12px
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: 1.17
  letter-spacing: -0.3px
  color: $tapgrey

.chat-education-lang
  font-family: $InterFace
  font-size: 6.7px
  font-weight: 900
  font-style: normal
  font-stretch: normal
  line-height: 1.79
  text-align: center
  color: #ffffff
  border-radius: 3px
  background-color: $tapgrey
  padding: 2px 3px
  margin-left: 3px

.chat-item-from
  font-family: $InterFace
  font-size: 12px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.33
  letter-spacing: -0.3px
  text-align: center
  color: #b3b3b3
  margin-bottom: 10px

  span
    font-weight: 500
    color: $tapgrey
    font-family: $InterFace

.chat-item-text
  font-family: $InterFace
  font-size: 14px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.5
  letter-spacing: -0.4px
  text-align: center
  color: $dark
  padding: 0 10px
  margin-bottom: 20px

.chat-societies-name
  font-family: $InterFace
  font-size: 7px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 2
  letter-spacing: -0.3px
  text-align: center
  color: #adadad
  margin-top: auto

.chat-societies-items
  font-family: $InterFace
  font-size: 12px
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: 1.33
  letter-spacing: -0.4px
  text-align: center
  color: #4cd964
  margin-bottom: 15px

.chat-link
  width: 135px
  height: 39px
  border-radius: 100px
  border: solid 1px #ff5100
  box-sizing: border-box
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  font-family: $InterFace
  font-size: 16px
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: 0.88
  letter-spacing: -0.4px
  color: #ff5100
  margin: 0 auto
  transition: 0.3s

  &:hover
    text-decoration: none
    color: #fff
    background-color: #ff5100

  .icon-chat
    width: 25px
    height: 25px
    padding-right: 9px

.posts-row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: flex-start
  margin: 0 -10px 85px

  @media (max-width: 767px)
    flex-wrap: wrap
    margin-bottom: 40px

.widget-users-carusel
  width: 100%

  .slick-track
    max-height: 500px

  .slick-prev
    transform: rotate(180deg)

.slick-slide
  min-height: 490px

.slick-arrow
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 2
  font-size: 0
  background: url(../../../img/svg/right-arrow.svg) center center no-repeat
  background-size: cover

  &:hover
    background: url(../../../img/svg/right-arrow.svg) center center no-repeat
    background-size: cover

.slick-prev
  @media (max-width: 767px)
    left: 0

.slick-next
  @media (max-width: 767px)
    right: 0

.send-message-form-container
  width: 100%

.chat-block-icon
  text-align: center

.upload-preview-modal
  height: 380px
  text-align: center

  img
    display: inline-block
    max-width: 100%
    max-height: 80%

.upload-preview-bottom
  position: absolute
  bottom: 10px
  left: 0
  width: 90%
  padding-left: 5%

.upload-preview-video
  width: 100% !important
  max-height: 80%
  height: auto !important

.chat-footer.loaded
  &:after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 99
    background: rgba(0, 0, 0, 0.2)

.load-dialog
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  z-index: 200

.table-load
  transition: all 0.2s linear
  filter: blur(3px)

.mod-messaging
  .chat-nav-msg-counter
    position: absolute
    right: 25px
    top: 60%

.prospect-info-modal
  padding: 2rem 5rem

.prospect-info-title
  font-size: 2rem
  color: #ff7106
  border-bottom: 1px solid #ff7106
  text-align: center
  margin: 0 auto 3rem
  display: table
  position: relative

  span
    position: absolute
    bottom: -13px
    right: -10px
    color: green
    font-size: 1rem

.prospect-info-row
  display: flex
  justify-content: space-between
  margin: 0.5rem 0
  padding-bottom: 0.5rem
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)

.prospect-info-field
  font-size: 1.4rem
  color: rgba(0, 0, 0, 0.7)

.prospect-info-val
  font-size: 1.6rem

.prospect-info-subtitle
  margin-top: 2rem
  margin-bottom: 1rem

.chat-footer-flaged
  text-align: center
  color: #fe3057

.details-flagged-actions
  margin-top: 7px
  margin-bottom: 7px
  display: flex
  justify-content: space-around
  align-items: center
  .details-text
    opacity: 0.4
    cursor: pointer
    font-size: 14px
    font-weight: normal
    color: #222222
  .flagged-details-block
    z-index: 9
    width: 450px
    bottom: 100%
    left: 20%
    transition: opacity 1s ease-out
    margin-left: -60px
    margin-bottom: 8px
    padding: 25px 10px
    background-color: #FFFFFF
    border-radius: 5px
    box-shadow: 0 3px 6px 0 rgba(38, 46, 69, 0.1)
    border: solid 0.5px rgba(38, 46, 69, 0.16)
    font-size: 16px
    .flagged-row
      margin-bottom: 20px
      text-align: left
      padding-left: 15px
    .flagged-column-title
      flex: 1
      color: #262e45
      opacity: 0.5
    .flagged-column-data
      display: flex
      flex: 4
      align-items: center
      color: #222222
      overflow-x: auto
      overflow-y: auto
      margin-right: 15px
      max-height: 300px
    .flagged-by
      align-self: center
    .date-flagged-data, .reasons-flagged-data
      padding-left: 2px


.flaged-icon
  font-family: Helvetica, sans-serif
  font-size: 1.5rem
  font-weight: bold
  font-style: normal
  font-stretch: normal
  line-height: 2.6rem
  letter-spacing: -0.2px
  text-align: center
  width: 2.3rem
  height: 2.3rem
  background-color: transparent
  &.keyword-icon
    height: 2rem
  @media (max-width: 1100px)
    width: 2rem
    height: 2rem
    line-height: 2rem
    font-size: 1.2rem
    margin-top: 1rem
.child-icon
  text-align: center
  width: 1.4rem
.chat-icons-group
  margin: auto auto 1rem
  display: flex
  align-items: center
  .flaged-icon
    margin-left: 0.2rem

  @media (max-width: 1100px)
    margin-top: 0

  .flaged-icon,
  .chat-nav-msg-counter
    position: static
    display: inline-block

    &.hidden-block
      display: none

.join-row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  flex-wrap: wrap
  width: 100%
  margin-top: 4rem
  margin-bottom: 3rem

  @media (max-width: 767px)
    margin-top: 1rem
    margin-bottom: 1rem

.join-text
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: 1.8
  letter-spacing: -0.03rem
  text-align: center
  color: $tapgrey
  margin: 0 1rem

.msg-reopened
  width: 100%
  font-family: $InterFace
  font-size: 1.6rem
  font-weight: 500
  font-style: normal
  font-stretch: normal
  line-height: 1.8
  letter-spacing: -0.03rem
  text-align: center
  color: #262e45
  margin: 0 1rem

.and-item
  font-family: $InterFace
  font-size: 1.2rem
  font-weight: normal
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -0.03rem
  color: #222222
  margin-right: 2rem

  @media (max-width: 767px)
    display: none

.invitation-wrapper
  padding: 0 20px

  @media (max-width: 1000px)
    padding: 0 20px 0 6px

.invitation
  display: flex
  align-items: center
  cursor: pointer
  margin-top: 4px

  &:hover > *:not(:first-child)
    opacity: 0.66

  &__avatar-wrapper
    overflow: hidden
    position: relative

  &__button
    display: flex
    border-radius: 50%
    margin-right: 6px
    z-index: 4

    &__icon
      width: 100%
      margin-left: 0.5px

  &__text
    font-family: $InterFace
    color: $dark

  &__avatar
    display: inline-flex
    align-items: center
    justify-content: center
    width: 2.5rem
    height: 2.5rem
    border-radius: 50%
    border: 2px solid #fff
    object-fit: cover
    margin-right: 0

    &:nth-child(1)
      z-index: 1

    &:nth-child(2)
      z-index: 2
      margin-left: -1rem

    &:nth-child(3)
      z-index: 3
      margin-left: -1rem

  @media (max-width: 1000px)

    &__avatar
      display: none

.show-more-dialogs-button
  padding-left: 23px
  margin-top: 20px
  margin-bottom: 30px
  font-size: 20px


.new-group-chat-button
  padding: 2.3rem 1.5rem 0.2rem 1.5rem
  text-align: center
  .create-group-chat-btn
    width: stretch
    text-align: center

.chat-group-search-block
  margin-bottom: 35px
  .search-filter-custom
    margin: 0 !important
    padding: 0 1.5rem 0.2rem 1.5rem !important

.group-chat-input
  width: 100%
  background-color: #ffffff
  font-size: 1.6rem
  font-family: $InterFace
  font-weight: 300
  font-style: normal
  font-stretch: normal
  letter-spacing: normal
  min-height: 10rem
  border: none
  &::placeholder
     color: #222222
     opacity: 0.5

.create-group-row .input-chat-wrapper
  display: flex
  flex-direction: column
  margin-top: 5px
  word-break: break-word
  padding: 0.6rem 1.6rem
  border-radius: 3px
  border: solid 1px #e3e3e3
  &.input-chat-wrapper-warning
    border: 1px solid red

  .input-length-indicator
    width: 100%
    text-align: right
    font-size: 1.4rem
    margin-top: 5px
    color: #262e45
    &.input-length-indicator-warning
      color: red

.deleted-user-system-message
  display: block
  width: 100%
  text-align: center
  margin: 3rem 0 0
  font-weight: 400
  font-style: normal
  font-stretch: normal
  line-height: normal
  letter-spacing: -.03rem
  color: #262e45

.private-chat-block
  display: flex
  .private-icon
    width: fit-content
  .private-chat-text
    font-size: 1.6rem
    line-height: 1.25
    letter-spacing: normal
    cursor: default
  .private-icon-info
    display: flex
    align-items: center
    svg
      cursor: pointer
      margin-left: 10px
      margin-top: 3px
      width: 16px
      height: 16px
      fill: #a7a7a7

.private-chat-help-text
  padding: 10px 16px
  line-height: 1.29

.chat-private-check-icon
  margin-right: 1rem !important
  cursor: pointer

.options-icon-drop
  cursor: pointer
  .ant-popover-arrow
    display: none
.options-drop-block
  padding: 10px 16px
  display: grid
  .options-drop-item
    padding: 16px 0
    margin: 0 25px
    font-family: $InterFace
    font-size: 1.6rem
    line-height: 1.25
    letter-spacing: normal
    color: #262e45
    cursor: pointer
    max-width: 20rem
    text-align: start
    cursor: not-allowed
    &:not(:last-child)
      border-bottom: 1px solid #ebebeb
    &:not(:disabled)
      cursor: pointer
      &:hover
        color: $taporange

.copy-link-popup
  padding: 30px 42px 42px
  h3
    font-size: 2.1rem
    font-weight: 600
    letter-spacing: normal
    line-height: 1.29
    margin-bottom: 16px
  .copy-link-popup-text
    font-size: 1.6rem
    line-height: 1.25
    letter-spacing: normal
    margin-bottom: 20px
  .copy-link-input-title
    font-size: 1.6rem
    font-weight: 600
    line-height: 1.25
    letter-spacing: normal
    padding-left: 1px
  .private-link-input-block
    display: flex
    align-items: center
    margin: 8px 0 16px
    .private-link-input
      background-color: #f7f7f7
      border-radius: 5px
      border: solid 1px #dadada
      padding: 8px 12px
      margin-right: 16px
      word-break: break-all
    .copy
      cursor: pointer
      color: $taporange
      font-size: 1.6rem
      margin-bottom: 3px

.popover-content-block
  padding: 1.6rem 2.2rem
  .blocker-name
    color: #dc2e2e
  .popover-text
    &:not(:last-child)
      margin-bottom: 0.8rem
    &.reason
      font-style: italic
    &.created-at
      text-align: right
  &.report
    &:not(:last-child)
      border-bottom: 1px solid rgba(38, 46, 69, 0.2)
    .reported-comment
      font-style: italic
      margin-top: 1rem

.reported-user-container
  display: flex
  flex-direction: row
  .preview-button
    margin-left: auto
    border: 1px solid rgba(38, 46, 69, 0.7)
    height: 50%
    width: 25%
    &:hover
      color: white
      background: rgba(38, 46, 69, 0.7)

.highlight-repoted-message
  background-color: rgba(38, 46, 69, 0.1)
  position: absolute
  top: -0.5rem
  width: 100%
  height: calc(100% + 3rem)
  border-radius: 0.5rem



