// nav
//-----------------------------------------------------------------------------------

.nav
    width: 6.5rem
    background-color: #ebebeb
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    padding: 2rem 0 2rem
    grid-area: nav
    @media (max-width: 767px)
        transition: 0.3s
        transform: translateX(-100%)
        z-index: 101
        width: 7rem
        padding-top: 6rem
        &.is-active
            transform: translateX(0)
.nav-top
    margin-bottom: 3rem
    @media (max-width: 767px)
        width: 100%
.nav-bottom
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    .user-img
      margin-bottom: 1rem
      width: 3rem
      height: 3rem
      cursor: pointer
.logo
    margin: 0 auto 1.8rem auto
    &:hover
        text-decoration: none
    @media (max-width: 767px)
        display: none

img.logo-img
    width: 70%
    max-width: 70%
    text-align: center

.bottom-helper-drop
    position: absolute
    width: 190px
    bottom: 100%
    left: 100%
    z-index: 9
    background-color: #ffffff
    border-radius: 3px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    li
        position: relative
        font-family: $InterFace
        display: flex
        align-items: center
        height: auto
        color: #222222
        &.active
            background-color: rgba(#222222, 0.05)
            color: #222222
        &:last-child:before
            position: absolute
            top: 0
            left: 50%
            transform: translateX(-50%)
            content: ''
            width: calc(100% - 50px)
            height: 1px
            background-color: rgba(#222222, 0.1)

        &:hover
            background-color: rgba(#222222, 0.05)
            color: #222222
        a
            width: 100%
            padding: 10px 20px
        &:first-child a
            padding-top: 16px
        &:last-child a
            padding-bottom: 16px
            padding-top: 13.5px
        &.drop-help-link
            padding-bottom: 13.5px

.nav-link
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    width: 100%
    transition: .3s
    &:hover
        text-decoration: none
        opacity: 1
        cursor: pointer
        text-decoration: none
        opacity: 1
        color: $taporange
        svg
            fill: $taporange
        &::after
          opacity: 1
          visibility: visible
    &::after
      z-index: 1
      position: absolute
      font-family: $InterFaceBold
      font-size: 1.4rem
      left: 6rem
      top: .5rem
      content: ''
      cursor: auto
      border: .1rem solid #e7e7e7
      background-color: #fff
      border-radius: 3px
      padding: .6rem .9rem
      opacity: 0
      visibility: hidden
      transition: all .45s
      box-shadow: 0 3px 12px rgba(0,0,0,.12)
      white-space: nowrap
    @media (max-width: 767px)
        margin-bottom: 1rem
    &.is-active
        .nav-link-icon
            background-color: #fff
            svg
                fill: $taporange
            img
                fill: $taporange
    &:last-of-type
        margin-bottom: 0
    &.is-disabled
        pointer-events: none
        opacity: 0.3
    &.beta-version
        position: relative
        .beta-mark
            font-size: 9px
            color: #ff5800
            position: absolute
            right: 1px
            top: 0

.nav-icon-notooltip
  &::after
    display: none
    visibility: hidden

.nav-icon-home
  &::after
    content: 'Home page'

.nav-icon-camera
    &::after
        content: 'Live events'
    svg
        width: 60% !important
        height: 60% !important
        padding-right: 2px

.nav-icon-camera-stream
    &::after
        content: 'Streams'
    svg
        width: 60% !important
        height: 60% !important
        padding-right: 2px

.nav-icon-chat
  &::after
    content: 'Chat'

.nav-icon-faq
  &::after
    content: 'FAQ'

.nav-icon-content
  &::after
    content: 'Content'

.nav-icon-salist
  &::after
    content: 'Ambassador list'

.nav-icon-settings
  &::after
    content: 'Settings'

.nav-link-idp
  &::after
    content: unset

.nav-icon-book-a-call
    &::after
        content: 'Book a call'
    .nav-link-icon
        &:active
            transform: scale(0.9)
            box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24)

.nav-icon-knowledge-base
  &::after
    content: 'Knowledge base'

.nav-icon-reporting
  &::after
    content: 'Reporting'

.nav-icon-analytics
  &::after
    content: 'Analytics'

.nav-icon-promotion
  &::after
    content: 'Promotion'

.nav-icon-campaign-management
  &::after
    content: 'Campaign Management'

.nav-icon-community
    &::after
        content: 'Community'

.nav-icon-campaign-management
  &::after
    content: 'Campaign Management'


.nav-link-icon
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    transition: 0.3s
    width: 100%
    height: 4.1rem
    margin-bottom: 0.5rem
    &:hover
        text-decoration: none
        opacity: 1
    svg
        fill: $dark
        width: 66.66%
        height: 66.66%
        transition: ease .2s
        &.marketplace, &.idp
            width: 52%
            height: 52%
    img
        fill: $dark
        width: 58.00%
        height: 58.00%
        transition: ease .2s
    &.mod-faq
        color: $dark
        font-size: 2.2rem

.nav-link-text
    opacity: 0.5
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.25
    letter-spacing: normal
    text-align: center
    color: #262e45
    padding-right: 2px

.submenu
    background-color: #fff
    border-right: 0.1rem solid #e6e6e6
    padding: 2.5rem 1.8rem 3rem 1.8rem
    transition: 0.3s
    grid-area: submenu
    @media (max-width: 767px)
        left: -100%
        &.is-active
            left: 7rem
.submenu-name
    text-transform: capitalize
    font-family: $InterFaceBold
    font-size: 2.1rem
    font-weight: 500
    font-style: normal
    font-stretch: normal
    line-height: normal
    letter-spacing: -0.2px
    color: $dark
    margin-bottom: 3.4rem
.menu-sep
    border: none
    border-top: 1px solid #dbdbdb
    width: 90%
    margin: .5rem 0 2.5rem 0
.submenu-link
    font-family: $InterFace
    font-size: 1.6rem
    font-weight: normal
    font-style: normal
    font-stretch: normal
    white-space: nowrap
    color: $dark
    display: block
    transition: 0.3s
    margin-bottom: 2rem
    &.is-disabled
      opacity: .3
      cursor: text !important
      &:hover
        pointer-events: none !important
    &:last-child
        margin-bottom: 0
    &:hover
        text-decoration: none
    &.active
        color: $taporange
        font-family: $InterFaceBold
    &.beta-version
        position: relative
        .beta-mark
            font-size: 9px
            color: #ff5800
            position: absolute
            right: -15px
            top: -7px

.submenu-header
  font-family: $InterFaceBold
  &:nth-child(n+2)
    margin-top: 3rem
.submenu-list
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start


.confirm-ban-buttons
    margin-top: 1rem
    text-align: center

.tooltip
    bottom: 90px
    position: fixed

.tooltip-text
    width: auto
    background-color: black
    color: #fff
    text-align: center
    padding: .6rem 1rem
    border-radius: 6px
    position: absolute
    z-index: 1

    &:after
      content: " "
      position: absolute
      top: 100%
      left: 25%
      margin-left: -10px
      border-width: 10px
      border-style: solid
      border-color: black transparent transparent transparent


// nav setup wizard

.nav-link-wizard
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    width: 100%
    transition: .3s
    margin: 1rem 0 5rem
    &:not(:last-child)::after
        position: absolute
        bottom: 0
        content: ''
        border-left: solid 1px rgba(34, 34, 34, 0.2)
        height: 32px
        top: 39px
    &:last-of-type
        margin-bottom: 0
    &.is-active
        .nav-link-wizard-icon
            background-color: $taporange
            color: #ffffff
            border: none
    &:hover
        text-decoration: none
        opacity: 1
    &.is-disabled
        pointer-events: none
        opacity: 0.3

    @media (max-width: 767px)
        margin-bottom: 1rem

.nav-privacy-policy, .nav-organization-details
    margin-top: 158px

.nav-link-wizard-icon
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    transition: 0.3s
    border-radius: 50%
    border: 1px solid rgba(34, 34, 34, 0.2)
    color: $taporange
    width: 30px
    height: 30px
    text-align: center

    &::after
        position: absolute
        font-family: $InterFaceBold
        font-size: 1.4rem
        left: 6rem
        top: .5rem
        content: ''
        cursor: auto
        border: .1rem solid #e7e7e7
        background-color: #fff
        color: $taporange
        border-radius: 3px
        padding: .6rem .9rem
        opacity: 0
        visibility: hidden
        transition: all .45s
        box-shadow: 0 3px 12px rgba(0,0,0,.12)
        white-space: nowrap

.nav-privacy-policy .nav-link-wizard-icon::after
    content: "Privacy policy"
.nav-training-centre .nav-link-wizard-icon::after
    content: "Training center"
.nav-branding-customisation .nav-link-wizard-icon::after
    content: "Branding customisation"
.nav-organization-details .nav-link-wizard-icon::after
    visibility: unset
.nav-ambassador-self-invite .nav-link-wizard-icon::after
    visibility: unset
.nav-participant-first-message .nav-link-wizard-icon::after
    visibility: unset
.nav-tap-page .nav-link-wizard-icon::after
    content: "Tap feed"
.nav-optional-data .nav-link-wizard-icon::after
    content: "Additional data"

.nav-organization-details, .nav-ambassador-self-invite, .nav-participant-first-message
    cursor: unset
