// font default
//-----------------------------------------------------------------------------------
body, input, button, select, textarea
  font-family: $InterFace, $default-font

.tap-orange
  color: $taporange
// Common classes
//-----------------------------------------------------------------------------------
body
  height: 100%
  text-rendering: optimizeLegibility
  -moz-osx-font-smoothing: grayscale
  -moz-font-feature-settings: "liga" on
  line-height: 1.33

.clear
  clear: both

%clear-fix:after
  content: ""
  clear: both
  display: table
  width: 100%

// positioning
//-----------------------------------------------------------------------------------
.fl
  float: left

.fr
  float: right

// text align -- registration
//-----------------------------------------------------------------------------------
.text
  &-left
    text-align: left

  &-center
    text-align: center

  &-right
    text-align: right

pre, code
  font-family: monospace

// link
//-----------------------------------------------------------------------------------
a
  color: inherit
  outline: none
  text-decoration: none
  transition: all .2s

  &:hover
    opacity: 0.66

button:hover:not(:disabled)
  opacity: 0.66

button.clean
  background: none
  border: none
  padding: 0
  margin: 0
  cursor: pointer
  &:hover
    opacity: 1

img
  position: relative
  display: inline-block
  border: none
  vertical-align: top
  max-width: 100%
  height: auto
  font: 300 12px / 2 $default-font
  text-align: center

  &:after
    content: "Loading image: " attr(alt)
    display: block
    color: rgb(100, 100, 100)
    position: absolute
    z-index: 2
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #fff

// form
//-----------------------------------------------------------------------------------
input
  vertical-align: middle
  margin: 0
  padding: 0

  &::-ms-clear,
  &::-ms-reveal
    display: none
    width: 0
    height: 0

button,
a.button,
input[type='reset'],
input[type='submit'],
input[type='button'],
input[type='image']
  display: inline-block
  width: auto
  margin: 0
  padding: 0
  overflow: visible
  background: none
  outline: 0
  border: 0
  cursor: pointer
  vertical-align: middle
  text-align: center
  text-decoration: none
  transition: all .2s

// WebKit/Blink Browsers
//-----------------------------------------------------------------------------------
\::selection
  background: $color-selection

// vertical align middle
//-----------------------------------------------------------------------------------
%vafix
  font-size: 0

  &:before
    display: inline-block
    margin-right: -0.25em
    height: 100%
    content: ""
    vertical-align: middle

.position-relative
  position: relative
.position-absolute
  position: absolute
.d-flex
  display: flex
.flex-row
  flex-direction: row !important
.flex-column
  flex-direction: column !important
.justify-content-center
  justify-content: center
.w-30
  width: 30%
.w-40
  width: 40%
.w-50
  width: 50%
.w-70
  width: 70%
.w-80
  width: 80%
.w-90
  width: 90%
.w-100
  width: 100%
.mr-auto
  margin-right: auto
.ml-auto
  margin-left: auto
.mt-auto
  margin-top: auto
.mb-auto
  margin-bottom: auto

.scroll-8
  &::-webkit-scrollbar
    width: 8px
  &::-webkit-scrollbar-thumb
    border-radius: 8px
    background-color: rgba(#222222, 0.2)
  &::-webkit-scrollbar-track
    border-radius: 8px
    background-color: unset

.interface-bold
  font-family: $InterFaceBold
