.tagger
  width: calc(80% + 2rem + 2px)
  border: .1rem solid #e7e7e7
  &:focus
    border-color: #becdd5

  .tags
    background: #fff
    padding: 0 5px
    overflow: hidden
    display: flex
    align-items: center

  .tag
    color: #fff
    font-family: $InterFaceBold
    background: $taporange
    float: left
    padding: 5px 10px
    border-radius: 6px
    margin: 8px 5px
    font-size: 1.8rem

    button
      background: transparent
      border: 0
      cursor: pointer

  .tag-input
    padding: 5px 10px
    box-sizing: border-box
    color: $tapgrey
    border: 0
    float: left
    margin: 10px 0
    font-size: 1.8rem
    outline: 0
    &::placeholder
        color: rgba($tapgrey, 0.5)


code
  font-size: 1.4rem
  background: #FCF8D0
  display: inline-block
  font-family: $monospace
  padding: 4px 6px
  border-radius: 4px
  margin: 0 5px

.promotion-input
    position: relative
    display: block
    padding: 1.2rem
    border: .1rem solid #e7e7e7
    color: $tapgrey
    font-size: 1.4rem
    line-height: 1em
    user-select: text
    border-radius: .2rem
    transition: border-color .15s linear
    box-sizing: border-box
    font-family: $InterFace
    background-color: #fff
    width: calc(80% + 2rem + 2px)
    &:focus
        border-color: #becdd5
    &::placeholder
        color: rgba($tapgrey, 0.5)
    &.mod-pass
        padding-right: 6.5rem

    &--settings
      width: 100%

    &--error
      border-color: $tapred

    &--error-message
      display: block
      font-family: $InterFace
      color: $tapred
      margin: 2px auto 0 10px
      font-size: 1.4rem

.autoplay-row
  display: flex
  flex-direction: row
  .settings-name
    margin-right: 3rem
.promotion-table
  td
    height: 4.5rem
    &:first-of-type
      display: block
      margin-right: 10px
      min-width: 40%
    &:last-of-type
      max-width: 45rem
    span
      opacity: .66
  small
    display: block
    margin-top: -.3rem
    opacity: .66
  code
    margin: 0 5px 0 0
