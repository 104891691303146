// sass import fonts
//-----------------------------------------------------------------------------------
=font-face($family, $path, $svg, $weight: normal, $style: normal)
  @font-face
    font-family: $family
    src: url('#{$path}.eot')
    src: url('#{$path}.eot?#iefix') format('embedded-opentype'), url('#{$path}.woff') format('woff'), url('#{$path}.ttf') format('truetype'), url('#{$path}.svg##{$svg}') format('svg')
    font-weight: $weight
    font-style: $style

// gradients
//-----------------------------------------------------------------------------
=gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%)
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent)
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent)
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent)
  background-repeat: repeat-x
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1)


=gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%)
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent)
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent)
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent)
  background-repeat: repeat-x
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0)

// ratio
//-----------------------------------------------------------------------------------
=aspect-ratio($width, $height)
  position: relative

  &:before
    display: block
    content: ''
    width: 100%
    padding-top: ($height / $width) * 100%

  > .ratio-inner
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

//@mixin font-size($sizeValue: 16)
  //font-size: ($sizeValue) + px
  //font-size: ($sizeValue / 16) + rem

//@include font-size(28)